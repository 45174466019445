const statusFilterOptions = [
  {
    key: 'leaved',
    value: 'leaved',
    label: 'PENDENTI',
    type: 'boolean',
    isStatusFilter: true,
    fieldValue: true,
    groupName: 'statusFilter',
    isExclusive: true
  },
  {
    key: 'closed',
    value: 'closed',
    label: 'CONCLUSE',
    type: 'boolean',
    isStatusFilter: true,
    fieldValue: true,
    groupName: 'statusFilter',
    isExclusive: true
  }
]

const expiredKeyValueFilterOptions = [
  {
    key: 'hrm_expired',
    value: 'hrm_expired',
    label: 'SCADUTA',
    isKeyValueFilter: true,
    fieldCondition: { hrm_expired: true },
    groupName: 'expiredKeyValueFilterOptions',
    isExclusive: true
  },
  {
    key: 'hrm_not_expired',
    value: 'hrm_not_expired',
    label: 'NON SCADUTA',
    isKeyValueFilter: true,
    fieldCondition: {
      $or: [{ hrm_expired: false }, { hrm_expired: { $exists: 0 } }]
    },
    groupName: 'expiredKeyValueFilterOptions',
    isExclusive: true
  }
]

const confirmedFilterOptions = [
  {
    key: 'confirmed',
    value: 'confirmed',
    label: 'CONFERMATI',
    isEventFilter: true,
    fieldValue: 'IS_CLAIM_CONFIRMED',
    groupName: 'confirmedFilter',
    isExclusive: true
  },
  {
    key: 'notConfirmed',
    value: 'notConfirmed',
    label: 'NON CONFERMATI',
    isEventFilter: true,
    fieldValue: 'IS_CLAIM_NOT_CONFIRMED',
    groupName: 'confirmedFilter',
    isExclusive: true
  },
  {
    key: 'notAnswered',
    value: 'notAnswered',
    label: 'ASTENUTI',
    isEventFilter: true,
    groupName: 'confirmedFilter',
    isExclusive: true
  }
]

const otherKeyValueFilterOptions = [
  {
    key: 'isSupportLocked',
    value: 'isSupportLocked',
    label: 'LOCKED',
    isKeyValueFilter: true,
    fieldCondition: { isSupportLocked: true },
    groupName: 'otherKeyValueFilterOptions'
  }
]

export default {
  statusFilterOptions,
  expiredKeyValueFilterOptions,
  confirmedFilterOptions,
  otherKeyValueFilterOptions
}
