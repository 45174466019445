/**
 * Gets the repositories of the Communications from Github
 */
import axios from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  loadCommunicationsSuccessAct,
  loadCommunicationsErrorAct,
  loadCorrelatedCommunicationsSuccessAct,
  loadCorrelatedCommunicationsErrorAct,
  downloadCommunicationsSuccessAct,
  downloadCommunicationsErrorAct,
  selectedCommunicationAct,
  downloadAllDocCallSuccessAct,
  downloadAllDocCallErrorAct
} from './_actions'
import {
  getCommunicationsDataByComponent,
  createListFile
} from './utils-communications'
import convert from './../../utils/convert-partition-name'
import { loadCommunicationDetailsAct } from './../communication-details/_actions'

export const getStateCommunications = (state) => state.communications

function getCommunicationsDataHttp(_requestURL, _body) {
  return axios.post(_requestURL, _body)
}

function sendGetOptions(_requestURL, _body) {
  return axios.get(_requestURL, _body)
}

function downloadFile(blob, filename) {
  var a = document.createElement('a')
  document.body.appendChild(a)
  a.style.display = 'none'
  var url = window.URL.createObjectURL(blob)
  a.href = url
  a.download = filename
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export function* getCommunicationsDataFromServer(action) {
  try {
    // let requestURL_old = `http://localhost:3004/communication/leave`;
    const stateCommunications = yield select(getStateCommunications)
    // Select paginations info (from store) in body
    let groupName =
      stateCommunications.selectedComponent &&
      stateCommunications.selectedComponent.value

    if (groupName === null) {
      const requestURL_group = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/groups`
      const response_group = yield call(
        getCommunicationsDataHttp,
        requestURL_group,
        null
      )
      if (
        response_group &&
        response_group.data &&
        response_group.data.data &&
        response_group.data.data.length
      ) {
        groupName = response_group.data.data[0].name
      }
    }

    const partition = convert.convertPartitionName(groupName)
    const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/leave`
    // Filter key-value pairs filters
    const _body = getCommunicationsRequestBody(stateCommunications)

    const response = yield call(getCommunicationsDataHttp, requestURL, _body)
    if (response.data.opResult === true) {
      const _payload = {
        serverData: response.data,
        // forceSelectedComponent: (!!action.payload) && 'NOW_AGE'
        takeAllSentAndFailed:
          action && action.payload && action.payload.takeAllSentAndFailed
      }
      yield put(loadCommunicationsSuccessAct(_payload))

      if (action.payload && action.payload.selectFirstCommunication === true) {
        const _currentSelectedComponent = stateCommunications.selectedComponent
        const _componentEl = getCommunicationsDataByComponent(
          response.data.data,
          _currentSelectedComponent
        )
        if (_componentEl && _componentEl.data && _componentEl.data.length) {
          const _selectedCommunication = _componentEl.data[0]
          yield put(selectedCommunicationAct(_selectedCommunication))
          yield put(loadCommunicationDetailsAct())
        }
      }
    } else {
      yield put(loadCommunicationsErrorAct(response.data.error))
    }
  } catch (err) {
    loadCommunicationsErrorAct(err)
  }
}

export function getCommunicationsRequestBody(
  stateCommunications,
  isDownloadAction
) {
  const keyValuePairFilter =
    stateCommunications.advancedFilters &&
    stateCommunications.advancedFilters.filter((f) => {
      return f.isKeyValueFilter === true
    })
  // Status filters
  const statusFilter =
    stateCommunications.advancedFilters &&
    stateCommunications.advancedFilters.filter((f) => f.isStatusFilter === true)
  // Event filters
  const eventsFilter =
    stateCommunications.advancedFilters &&
    stateCommunications.advancedFilters.filter((f) => f.isEventFilter === true)

  const nowIdFilter = stateCommunications.filterId
    ? stateCommunications.filterId
    : null

  let _body = {
    filtered: stateCommunications.filtered,
    nowId: nowIdFilter,
    advancedFilters: {
      keyValuePairFilter,
      statusFilter,
      eventsFilter
    }
  }
  // Search string or pagination
  if (stateCommunications.searchStr) {
    _body.search = stateCommunications.searchStr
  } else {
    _body = Object.assign({}, _body, {
      pageSize: isDownloadAction ? 999 : stateCommunications.pageSize,
      pageNum: isDownloadAction ? 1 : stateCommunications.page + 1,
      sorted: stateCommunications.sorted
    })
  }
  return _body
}

export function* getCorrelatedCommunicationsFromServer(action) {
  const stateCommunications = yield select(getStateCommunications)
  const groupName =
    stateCommunications.selectedComponent &&
    stateCommunications.selectedComponent.value
  const partition = convert.convertPartitionName(groupName)
  const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/leave`

  try {
    const _body = {
      advancedFilters: {
        keyValuePairFilter: [
          {
            isKeyValueFilter: true,
            fieldCondition: { crash_id: action.payload }
          }
        ]
      }
    }

    const response = yield call(getCommunicationsDataHttp, requestURL, _body)
    if (response.data.opResult === true) {
      yield put(loadCorrelatedCommunicationsSuccessAct(response.data))
    } else {
      yield put(loadCorrelatedCommunicationsErrorAct(response.data.error))
    }
  } catch (err) {
    yield put(loadCorrelatedCommunicationsErrorAct(err))
  }
}

export function* downloadCommunicationsDataFromServer() {
  // Select paginations info (from store) in body
  const stateCommunications = yield select(getStateCommunications)

  const groupName =
    stateCommunications.selectedComponent &&
    stateCommunications.selectedComponent.value
  const partition = convert.convertPartitionName(groupName)
  const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/leave`

  const _body = getCommunicationsRequestBody(stateCommunications, true)

  try {
    const response = yield call(getCommunicationsDataHttp, requestURL, _body)
    if (response.data.opResult === true) {
      const backendData = response.data
      const _componentEl = getCommunicationsDataByComponent(
        backendData.data,
        stateCommunications.selectedComponent
      )

      createListFile(
        _componentEl && _componentEl.data,
        stateCommunications.selectedComponent
      )
      yield put(downloadCommunicationsSuccessAct())
    } else {
      yield put(downloadCommunicationsErrorAct(response.data.error))
    }
  } catch (err) {
    yield put(downloadCommunicationsErrorAct(err))
  }
}

export function* handleDownloadAllDocCall(action) {
  try {
    const nowId = action.payload
    const requestURL = `${window.REACT_APP_API_ENDPOINT}/api/request/download-all/${nowId}?download=true`
    const res = yield call(sendGetOptions, requestURL, {
      responseType: 'arraybuffer'
    })

    if (res && res.status >= 200 && res.status < 300) {
      var blob = new Blob([res.data], { type: 'application/zip' })
      downloadFile(blob, `${nowId}.zip`)
      yield put(downloadAllDocCallSuccessAct())
    } else {
      yield put(downloadAllDocCallErrorAct(res.data.error))
    }
  } catch (error) {
    yield put(downloadAllDocCallErrorAct(error))
    infoError(
      '',
      `L'operazione non è stata portata a termine. Si prega di riprovare.`
    )
  }
}

// saga watcher
export default function* getCommunicationsData() {
  yield takeLatest('LOAD_COMMUNICATIONS', getCommunicationsDataFromServer)
  yield takeLatest(
    'LOAD_CORRELATED_COMMUNICATIONS',
    getCorrelatedCommunicationsFromServer
  )
  yield takeLatest(
    'DOWNLOAD_COMMUNICATIONS',
    downloadCommunicationsDataFromServer
  )
  yield takeLatest('DOWNLOAD_DOCUMENTS', handleDownloadAllDocCall)
}
