import * as XLSX from 'xlsx'
import moment from 'moment'

export const columns = [
  {
    Header: 'Giorno',
    accessor: (d) => moment(d.timestamp).format('DD/MM/YYYY')
  },
  {
    Header: 'Totale comunicazioni',
    accessor: (d) => d.totalComs
  },
  {
    Header: 'Comunicazioni ricevute',
    accessor: (d) => d.numComsReceived
  },
  {
    Header: 'Comunicazioni con link aperto almeno una volta',
    accessor: (d) => d.numLinkOpened
  },
  {
    Header: 'Comunicazioni con almeno un documento caricato e salvato',
    accessor: (d) => d.numAtLeastOneDoc
  },
  {
    Header: 'N° totale documenti caricati e salvati',
    accessor: (d) => d.numDocSaved
  }
]

export const monthNames = [
  'Gennaio',
  'Febbraio',
  'Marzo',
  'Aprile',
  'Maggio',
  'Giugno',
  'Luglio',
  'Agosto',
  'Settembre',
  'Ottobre',
  'Novembre',
  'Dicembre'
]

export const createListFile = (stats, name) => {
  const data = []

  data.push(columns.map((ch) => ch.Header))
  stats.forEach((cd) => {
    const currentRow = columns.map((ch) => ch.accessor && ch.accessor(cd))
    data.push(currentRow)
  })

  const workSheet = XLSX.utils.aoa_to_sheet(data)
  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, workSheet, 'Storico Estrazioni')

  XLSX.writeFile(workBook, `${name}.xlsx`)
}

export const isValidProperty = (k, obj) => {
  return k in obj && typeof obj[k] === 'number'
}
