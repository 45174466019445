import React, { useState, useEffect, useRef } from 'react'
import ReactMapGL, { Marker, Popup } from 'react-map-gl'
import CommPin from './comm-pin'

import filter from '../../utils/filter-widgets'

export const setRelativeStyleMap = (mapItemRef) => {
  if (!mapItemRef || !mapItemRef.current || !mapItemRef.current.style) {
    return
  }
  mapItemRef.current.style.position = 'relative'
  mapItemRef.current.style.top = 'unset'
  mapItemRef.current.style.height = 'calc(100vh - 180px)'
}

export const setFixedStyleMap = (mapItemRef) => {
  if (!mapItemRef || !mapItemRef.current || !mapItemRef.current.style) {
    return
  }
  mapItemRef.current.style.position = 'fixed'
  mapItemRef.current.style.top = '80px'
  mapItemRef.current.style.height = 'calc(100vh - 115px)'
}

export const computeScrollTopNum = (_scrollArr) => {
  const scrollArr = _scrollArr || document.getElementsByClassName('ps__rail-y')
  const scrollEl = scrollArr && scrollArr.length && scrollArr[0]
  if (!scrollEl) {
    return
  }
  const _scrollTop = scrollEl && scrollEl.style && scrollEl.style.top
  if (!_scrollTop) {
    return
  }
  const _scrollTopNumStr = _scrollTop.replace('px', '')
  const _scrollTopNum = parseInt(_scrollTopNumStr)
  return _scrollTopNum
}

const CommunicationsMap = React.forwardRef((props, ref) => {
  useEffect(() => {
    const map = ref && ref.current && ref.current.getMap && ref.current.getMap()
    if (map) {
      props.executeFitBound()
    }
  }, [ref && ref.current])

  const [popupInfo, setPopupInfo] = useState(null)
  let monitorStickyInterval
  const mapItemRef = useRef()

  useEffect(() => {
    monitorMapPosition()

    return () => {
      return monitorStickyInterval && clearInterval(monitorStickyInterval)
    }
  }, [])

  const monitorMapPosition = () => {
    if (monitorStickyInterval) {
      return
    }
    monitorStickyInterval = setInterval(() => {
      const _scrollTopNum = computeScrollTopNum()
      if (!_scrollTopNum || isNaN(_scrollTopNum)) {
        return
      }

      if (_scrollTopNum < 94) {
        setRelativeStyleMap(mapItemRef)
      } else {
        setFixedStyleMap(mapItemRef)
      }
    }, 150)
  }

  const goToDetails = (comm) => {
    props.goToDetails(comm)
  }

  const onMouseEnterEvent = (comm) => {
    props.hoveredCommInMap(comm && comm._id)
  }

  const _renderCommMarkers = (comm, index) => {
    const _lat = parseFloat(comm.inc_latitude)
    const _long = parseFloat(comm.inc_longitude)
    // get hovered communication id
    const communicationStore = props.communications
    const hoveredMapCommId =
      communicationStore && communicationStore.currentHoveredCommIdInMap
    const hoveredTableCommId =
      communicationStore && communicationStore.currentHoveredCommIdInTable
    const isHovered =
      hoveredMapCommId === comm._id || hoveredTableCommId === comm._id

    return (
      <Marker key={`marker-${index}`} longitude={_long} latitude={_lat}>
        <CommPin
          isHovered={isHovered}
          onClick={() => goToDetails(comm)}
          onMouseEnter={() => onMouseEnterEvent(comm)}
          onMouseLeave={() => onMouseEnterEvent(null)}
        />
      </Marker>
    )
  }

  const _renderMarkers = (_list) => {
    const validGeoComms = props.getValidGeoComms(_list)
    return (
      validGeoComms &&
      validGeoComms.length &&
      validGeoComms.map(_renderCommMarkers)
    )
  }

  const _renderPopup = () => {
    const _lat = parseFloat(popupInfo && popupInfo.inc_latitude)
    const _long = parseFloat(popupInfo && popupInfo.inc_longitude)

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor='top'
          longitude={_long}
          latitude={_lat}
          closeOnClick={false}
          onClose={() => setPopupInfo(null)}
        >
          {/* <CityInfo info={popupInfo} /> */}
          <h1>Details here</h1>
        </Popup>
      )
    )
  }

  const communicationStore = props.communications
  const mapData = communicationStore && communicationStore.mapData
  const communicationsList =
    (communicationStore && communicationStore.list) || []
  const newViewPort = Object.assign({}, mapData.viewport, props.newSizeInfo)

  let groupName = null
  if (
    communicationStore.selectedComponent &&
    communicationStore.selectedComponent.value
  ) {
    groupName = communicationStore.selectedComponent.value
  }
  const blackListMap = ['NOW_AGE']
  const renderWidget = filter.shouldRenderThisWidget(groupName, blackListMap)

  const mapTabViewMode =
    communicationStore && communicationStore.tabViewMode === 'map'

  return (
    props.mapTabViewMode &&
    renderWidget &&
    mapTabViewMode && (
      <React.Fragment>
        <div className='map-container'>
          <div className='map-item' ref={mapItemRef}>
            <ReactMapGL
              ref={ref}
              mapStyle={{
                version: 8,
                sources: {
                  backgroundLayer: {
                    type: 'raster',
                    tiles: ['https://c.tile.openstreetmap.org/{z}/{x}/{y}.png'],
                    crossOrigin: null,
                    tileSize: 256,
                    attribution:
                      'Map tiles by <a target="_top" rel="noopener" href="http://stamen.com">Stamen Design</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a target="_top" rel="noopener" href="http://openstreetmap.org">OpenStreetMap</a>, under <a target="_top" rel="noopener" href="http://creativecommons.org/licenses/by-sa/3.0">CC BY SA</a>'
                  }
                },
                layers: [
                  {
                    id: 'backgroundLayer',
                    type: 'raster',
                    source: 'backgroundLayer',
                    minzoom: 0,
                    maxzoom: 24
                  }
                ]
              }}
              {...newViewPort}
              onViewportChange={(viewport) =>
                props.updateMapViewport({ viewport })
              }
            >
              {props.showMapMarkers && _renderMarkers(communicationsList)}

              {_renderPopup()}

              <div className='zoom-container'>
                <button
                  title='Zoom avanti'
                  aria-label='Zoom avanti'
                  type='button'
                  className='gm-control-active'
                  onClick={props.mapZoomIn}
                >
                  <i className='fas fa-plus' />
                </button>
                <div className='divider' />
                <button
                  title='Zoom avanti'
                  aria-label='Zoom avanti'
                  type='button'
                  className='gm-control-active'
                  onClick={props.mapZoomOut}
                >
                  <i className='fas fa-minus' />
                </button>
              </div>
            </ReactMapGL>
          </div>
        </div>
      </React.Fragment>
    )
  )
})

export default CommunicationsMap
