import axios from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { loadStatsSuccessAct, loadStatsErrorAct } from './_actions'
import moment from 'moment'

export const getStatsFromState = (state) => state.stats

function getGroupsDataHttp(_requestURL, _body) {
  return axios.post(_requestURL, _body)
}

function getStatsDataHttp(_requestURL, _body) {
  return axios.post(_requestURL, _body)
}

export function* getStatsDataFromServer(action) {
  const {
    payload: { params }
  } = action

  try {
    const stats = yield select(getStatsFromState)

    // const today = moment()

    let groupName = stats.selectedComponent && stats.selectedComponent.value

    if (groupName === null) {
      const groupRequest = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/groups`
      const groupResponse = yield call(getGroupsDataHttp, groupRequest, null)
      if (
        groupResponse &&
        groupResponse.data &&
        groupResponse.data.data &&
        groupResponse.data.data.length
      ) {
        groupName = groupResponse.data.data[0].name
      }
    }

    const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/stats`

    const _body = {
      year: params.year
    }

    const {
      data: { opResult, data, error }
    } = yield call(getStatsDataHttp, requestURL, _body)

    if (opResult && data) {
      yield put(loadStatsSuccessAct(data))
    } else {
      console.error(error)
      yield put(loadStatsErrorAct('Impossibile caricare le stats.'))
    }
  } catch (err) {
    loadStatsErrorAct(err)
  }
}

// saga watcher
export default function* getStatsData() {
  yield takeLatest('LOAD_STATS', getStatsDataFromServer)
}
