import React from 'react';
import { localGetTimeHourWithUtcOffset } from '../../../utils/custom_conversions';

function FinitaliaDetailsListCmp(props) {
  const renderDetailsInfo = (data) => (
    <React.Fragment>
      <dl>
        <dt>Codice cliente: </dt>
        <dd>{data.owner_ns || '--'}</dd>
        <dt>Numero di telefono: </dt>
        <dd>{data.phone_number || '--'}</dd>
        <dt>Data creazione pratica: </dt>
        <dd>
          {data.creation_date
            ? localGetTimeHourWithUtcOffset(data.creation_date)
            : '--'}
        </dd>
      </dl>
    </React.Fragment>
  );

  const DetailsInfo =
    props.communicationDetails && props.communicationDetails.data
      ? renderDetailsInfo(props.communicationDetails.data)
      : null;

  return <div className='incident-info-container'>{DetailsInfo}</div>;
}

export default FinitaliaDetailsListCmp;
