const confirmedFilterOptions = []

const userInteractionStatusFilterOptions = [
  {
    key: 'page_viewed',
    value: 'page_viewed',
    label: 'VISITATE',
    type: 'boolean',
    isStatusFilter: true,
    fieldValue: true,
    groupName: 'userInteractionStatusFilterOptions',
    isExclusive: false
  },
  {
    key: 'closed',
    value: 'closed',
    label: 'CONCLUSE',
    type: 'boolean',
    isStatusFilter: true,
    fieldValue: true,
    groupName: 'userInteractionStatusFilterOptions',
    isExclusive: false
  }
]

const sentStatusFilterOptions = [
  {
    key: 'sent',
    value: 'sent',
    label: 'INVIATE',
    type: 'boolean',
    isStatusFilter: true,
    fieldValue: true,
    groupName: 'sentStatusFilterOptions',
    isExclusive: true
  },
  {
    key: 'sent_failed',
    value: 'sent_failed',
    label: 'INVII FALLITI',
    type: 'boolean',
    isStatusFilter: true,
    fieldValue: true,
    groupName: 'sentStatusFilterOptions',
    isExclusive: true
  }
]

export default {
  confirmedFilterOptions,
  userInteractionStatusFilterOptions,
  sentStatusFilterOptions
}
