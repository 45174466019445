const convertPartitionName = (name) => {
  switch (name) {
    case 'NOW_AGE':
      return 'now/'
    default:
      return ''
  }
}

export default {
  convertPartitionName
}
