import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import convert from './../../utils/convert-partition-name'

const ZipDownload = (props) => {
  const { communications } = props
  const [bearerToken, setBearerToken] = useState(null)

  useEffect(() => {
    if (localStorage.getItem('applicationState') !== null) {
      const appState = JSON.parse(localStorage.getItem('applicationState')) // re-hydrate the store
      const authInfoToken =
        appState.auth && appState.auth.authInfo && appState.auth.authInfo.token
      if (authInfoToken) {
        setBearerToken(authInfoToken)
      }
    }
  }, [])

  const token =
    communications &&
    communications.selectedCommunication &&
    communications.selectedCommunication.token
  const groupName =
    communications.selectedComponent && communications.selectedComponent.value
  const partition = convert.convertPartitionName(groupName)

  return (
    <div className='zip-button-container'>
      <Button color='info'>
        <a
          href={`${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/${token}/downloadAll?token=${bearerToken}&download=true`}
          target='_blank'
          rel='noreferrer'
        >
          <i className='fa fa-download' /> &nbsp;
          <span className='download-all-label'>
            Scarica tutti i documenti
          </span>
        </a>
      </Button>
    </div>
  )
}

export default ZipDownload
