import moment from 'moment'
import {
  faThumbsUp,
  faHandPointer,
  faFileDownload,
  faFileUpload,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

export const localGetTimeHourWithUtcOffset = (date) => {
  const utcoffset = moment().utcOffset()

  const momentObj = moment(date).add(utcoffset, 'minutes').utc()

  return momentObj.isValid() ? momentObj.format('DD/MM/YYYY HH:mm') : '--'
}

export const fromTemplateToName = (type) => {
  switch (type) {
    case 'AGE_PARAM_DOC_UPLOAD_UNO':
      return 'Richiesta documentazione sinistro'
    case 'AGE_PARAMETRIC_DOC_UPLOADER':
      return 'Richiesta documentazione'
    case 'AGE_INFO_DOC':
      return 'Comunicazione informativa'
    default:
      return type
  }
}

export const isDocRequest = (type) => {
  switch (type) {
    case 'AGE_PARAM_DOC_UPLOAD_UNO':
    case 'AGE_PARAMETRIC_DOC_UPLOADER':
      return true
    default:
      return false
  }
}

export const sendingStatusesObjects = [
  {
    name: 'sent_tried',
    codes: [
      'TWL_SENT',
      'HERMES_OUTCOME_SUCCESSED',
      'VTV-SENT',
      'HRM-03',
      'HRM-02',
      'TWL_DELIVERED',
      'CAP-01',
      'SMS-02',
      'SMS-03',
      'HRM-01',
      'CAP-03',
      'CAP-02'
    ],
    label: 'Inviata'
  },
  {
    name: 'sent',
    codes: [
      'TWL_DELIVERED',
      'HERMES_MESSAGE_RECEIVED',
      'CAP-01',
      'CAP-03',
      'CAP-02',
      'HRM-01',
      'HRM-02',
      'HRM-03',
      'SMS-03',
      'VTV-RECEIVED'
    ],
    label: 'Ricevuta'
  },
  {
    name: 'sent_failed',
    codes: [
      'TWL_FAILED',
      'HERMES_OUTCOME_FAILED',
      'SMS-05',
      'VTV-OUTCOME-FAILED',
      'VTV-NOT-RECEIVED'
    ],
    label: 'Invio fallito'
  },
  { name: 'not_sent', codes: [], label: 'Non inviato' }
]

export const viewedStatusItem = {
  name: 'page_viewed',
  codes: ['HRM-01', 'CAP-01', 'CAP-03', 'CAP-02', 'HRM-02'],
  label: 'Visitata'
}

export const userInteractionsStatusesObjects = [
  {
    name: 'closed',
    codes: ['HRM-02'],
    label: 'Conclusa',
    iconComponent: faThumbsUp
  },
  {
    name: 'document_downloaded',
    codes: ['IS_DOCUMENT_DOWNLOADED'],
    label: 'File scaricato',
    iconComponent: faFileDownload
  },
  {
    name: 'upload_started',
    label: 'Documenti parzialmente caricati',
    iconComponent: faFileUpload
  }
]

export const communicationInteractionsRelated = [
  {
    name: 'upload_started',
    label: 'Documenti parzialmente caricati',
    iconComponent: faFileUpload
  }
]

export const linearCommunicationInteractionsRelated = [
  {
    name: 'upload_started',
    label: 'Almeno un file caricato',
    iconComponent: faFileUpload
  }
]

export const linearInteractionsStatusesObjects = [
  {
    name: 'sent',
    codes: [
      'TWL_DELIVERED',
      'HERMES_MESSAGE_RECEIVED',
      'CAP-01',
      'CAP-03',
      'CAP-02',
      'HRM-01',
      'HRM-02',
      'HRM-03',
      'SMS-03',
      'VTV-RECEIVED'
    ],
    label: 'Comunicazione Ricevuta',
    iconComponent: faCheck
  },
  {
    name: 'clicato',
    codes: ['HRM-01', 'CAP-01', 'CAP-03', 'CAP-02', 'HRM-02'],
    label: 'Link Cliccato',
    iconComponent: faThumbsUp
  }
]

export const isEventPresent = (communication, statusCodesArray) => {
  let codeFound = false
  let lastTimestamp = ''
  if (statusCodesArray && statusCodesArray.length) {
    statusCodesArray.forEach((codeItem) => {
      const codeItemFound =
        communication &&
        communication.events &&
        communication.events.find((ev) => ev.value === codeItem)
      if (codeItemFound) {
        codeFound = true
        lastTimestamp = codeItemFound.timestamp
      }
    })
  }

  return { codeFound, lastTimestamp }
}

export const getMostRecentEvent = (_events) => {
  if (!_events || !_events.length || !_events.length) {
    return _events
  }
  let mostrecentEv = _events[0]
  if (_events.length > 0) {
    for (let i = 0; i < _events.length; i++) {
      // compare dates
      const currentMostRecentDate = moment(mostrecentEv.lastTimestamp)
      const currentDate = moment(_events[i].lastTimestamp)
      if (currentDate.diff(currentMostRecentDate) > 0) {
        mostrecentEv = _events[i]
      }
    }
  }
  return mostrecentEv
}

export const hasSendingFailedStatus = (communication) => {
  const statusesOb = getCommunicationStatuses(
    communication,
    sendingStatusesObjects
  )
  const listStatuses = statusesOb ? statusesOb.map((so) => so.name) : []
  const failedStatusesFound = ['sent_failed', 'not_sent'].filter((value) =>
    listStatuses.includes(value)
  )
  return failedStatusesFound.length > 0
}

export const hasSendingSuccessStatus = (communication) => {
  const statusesOb = getCommunicationStatuses(
    communication,
    sendingStatusesObjects
  )
  const listStatuses = statusesOb ? statusesOb.map((so) => so.name) : []
  const successStatusesFound = ['sent'].filter((value) =>
    listStatuses.includes(value)
  )
  return successStatusesFound.length > 0
}

export const removeFailedDuplicatesData = (communications) => {
  /* Remove failed send trials for which there is a success send */
  const filteredData = communications.filter((communication) => {
    if (hasSendingFailedStatus(communication)) {
      const foundSuccessWithSameNumber = communications.find(
        (comm) =>
          comm.customer_phone === communication.customer_phone &&
          hasSendingSuccessStatus(comm)
      )
      if (foundSuccessWithSameNumber) {
        return false
      }
    }
    return true
  })
  return filteredData
}

export const getCommunicationStatuses = (
  communication,
  statusCodesArray,
  takeTheLastRecent = false
) => {
  /*
        conclusa HRM-02
        visualizzata HRM-01/HRM-03 (l'evento successivo a CAP-02 - superamento captcha)
        SMS-02 - invio tentato
        SMS-03 - E' arrivata dal provider la risposta che l'invio è andato a buon fine (sent)
        SMS-05 - invio fallito (nel now)
        SMS-04 - documento pdf scaricato, ma solo per ID1/id2/id3 (nel NOW non si presenta mai)

        personalizzare la lista dei filtri per le comunicazioni del NOW con gli stati di cui 
        sopra
    */
  if (!communication || !communication.events || !communication.events.length) {
    const notFoundEv =
      statusCodesArray &&
      statusCodesArray.find((sta) => sta.name === 'not_sent')
    return notFoundEv ? [notFoundEv] : []
  }
  let statusesFound = statusCodesArray
    .map((statusObject) => {
      const evPresentObj = isEventPresent(communication, statusObject.codes)
      if (evPresentObj.codeFound) {
        return { ...statusObject, lastTimestamp: evPresentObj.lastTimestamp }
      }
    })
    .filter((outObj) => !!outObj)
  if (takeTheLastRecent && statusesFound && statusesFound.length > 1) {
    // keep only the most recent one
    const mostRecentEv = getMostRecentEvent(statusesFound)
    statusesFound = [mostRecentEv]
  }
  return statusesFound
}

export const isInteractionPresentInCommunications = (communication, propName) => {
  return !!(
    communication &&
    communication.interactions &&
    communication.interactions.length &&
    communication.interactions.find((interaction) => !!interaction[propName])
  )
}
export const isUploadDocStarted = (communication, selectedGroup) => {
  const docUploadStarted = isInteractionPresentInCommunications(
    communication,
    'file_type'
  )

  const interactionArray =
    selectedGroup === 'LINEAR'
      ? linearCommunicationInteractionsRelated
      : communicationInteractionsRelated

  return (
    docUploadStarted &&
    interactionArray.find((ci) => ci.name === 'upload_started')
  )
}

export const countInteractionsFiles = (communication) => {
  if (communication && communication.interactions) {
    return communication.interactions.filter((el) => el.file_type !== undefined)
      .length
  } else {
    return 0
  }
}
