export const initialState = {
  pdf: null,
  loadingError: false,
  loading: false,
  contentType: null,
  showSpinner: false,
  showCanvas: true
}

export const previewComponentDetailsReducer = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case 'LOAD_PREVIEW':
      return {
        ...state,
        loading: true,
        loadingError: false
      }
    case 'LOAD_PREVIEW_SUCCESS':
      return {
        ...state,
        pdf: action.payload,
        loading: false,
        loadingError: false
      }
    case 'LOAD_PREVIEW_ERROR':
      return {
        ...state,
        loading: false,
        loadingError: true,
        userInfoError: action.payload
      }
    case 'SHOW_LOADING_SPINNER':
      return {
        ...state,
        showSpinner: action.payload
      }
    case 'SHOW_CANVAS':
      return {
        ...state,
        showCanvas: action.payload
      }
    default:
      return state
  }
}
