import React from 'react'
import FactoryClaimInteractionModalCmp from './FactoryClaimInteractionModalCmp'

const ClaimInteractionModalCmp = (props) => {
  const factoryClaimInteractionModalComponents = new FactoryClaimInteractionModalCmp()

  const claimInteractionModal =
    factoryClaimInteractionModalComponents.createModalInstance(
      props.communications.selectedComponent,
      props
    )

  return <div>{claimInteractionModal.modalCmp}</div>
}

export default ClaimInteractionModalCmp
