import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  linearInteractionsStatusesObjects,
  localGetTimeHourWithUtcOffset,
  getCommunicationStatuses,
  isUploadDocStarted
} from './../../../utils/custom_conversions'

export default [
  {
    Header: 'Id Sinistro',
    accessor: 'externalId'
  },
  {
    Header: 'Targa',
    accessor: 'your_plate'
  },
  {
    Header: 'Numero telefono',
    accessor: 'to'
  },
  {
    Header: 'Invio sms',
    id: 'creation_date',
    className: 'creation-date-col',
    headerClassName: 'creation-date-col',
    accessor: (d) => {
      return localGetTimeHourWithUtcOffset(d.creation_date)
    }
  },
  {
    Header: 'Stato',
    id: 'status',
    Cell: ({ original }) => {
      const statusesOb = getCommunicationStatuses(
        original,
        linearInteractionsStatusesObjects
      )
      const isUploadDocStartedStatus = isUploadDocStarted(original, 'LINEAR')
      if (isUploadDocStartedStatus) {
        statusesOb.push(isUploadDocStartedStatus)
      }
      const listStatusesIcon = statusesOb
        ? statusesOb.map((so) => (
            <li
              key={so.name}
              className='d-inline-block'
              data-toggle='tooltip'
              title={so.label}
            >
              {' '}
              <FontAwesomeIcon icon={so.iconComponent} />
            </li>
          ))
        : ''
      return (
        <ul className='statuses-list interaction-list'>{listStatusesIcon} </ul>
      )
    },
    headerClassName: 'communication-statuses-col',
    className: 'communication-statuses communication-interactions'
  }
]
