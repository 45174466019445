import React from 'react'
import {
  sendingStatusesObjects,
  userInteractionsStatusesObjects,
  viewedStatusItem,
  getCommunicationStatuses,
  isUploadDocStarted,
  localGetTimeHourWithUtcOffset,
  countInteractionsFiles
} from './../../../utils/custom_conversions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/free-solid-svg-icons'

const handleClickDocDlCell = (event) => {
  if (event.currentTarget.className === 'span-doc-dl-icon') {
    //   event.stopPropagation();
    console.log('You just clicked on me dude, not cool')
  }
}

export default [
  // {
  //     Header: "Destinatario",
  //     accessor: "customer_name"
  // },
  {
    Header: 'Telefono',
    accessor: 'customer_phone'
  },
  // {
  //     Header: "Codice Fiscale",
  //     id: "customer_code",
  //     accessor: d => {
  //         return conversions.fromTemplateToName(d.customer_code);
  //     },
  //     headerClassName: 'template-customer_code-col',
  //     className: 'template-customer_code'
  // },
  // {
  //     Header: "Template",
  //     id: "type",
  //     accessor: d => {
  //         return conversions.fromTemplateToName(d.type);
  //     },
  //     headerClassName: 'template-type-col',
  //     className: 'template-type'
  // },
  {
    Header: 'Stato Invio',
    id: 'status',
    Cell: ({ original }) => {
      const statusesOb = getCommunicationStatuses(
        original,
        sendingStatusesObjects
      )
      const listStatuses = statusesOb
        ? statusesOb.map((so) => (
            <li className={so.name} key={so.name}>
              {so.label}
            </li>
          ))
        : ''
      return <ul className='statuses-list'>{listStatuses}</ul>
    },
    headerClassName: 'communication-statuses-col',
    className: 'communication-statuses'
  },
  {
    Header: 'Stato Interazioni',
    id: 'status',
    Cell: ({ original }) => {
      const statusesOb = getCommunicationStatuses(
        original,
        userInteractionsStatusesObjects
      )
      const isUploadDocStartedStatus = isUploadDocStarted(original)
      if (isUploadDocStartedStatus) {
        statusesOb.push(isUploadDocStartedStatus)
      }
      const listStatusesIcon = statusesOb
        ? statusesOb.map((so) => (
            <li key={so.name} data-tooltip={so.label}>
              {' '}
              <FontAwesomeIcon icon={so.iconComponent} />
            </li>
          ))
        : ''
      return (
        <ul className='statuses-list interaction-list'>{listStatusesIcon} </ul>
      )
    },
    headerClassName: 'communication-statuses-col',
    className: 'communication-statuses communication-interactions'
  },
  {
    Header: 'Data Invio',
    id: 'creation_date',
    accessor: (d) => {
      return localGetTimeHourWithUtcOffset(d.creation_date)
    },
    headerClassName: 'creation-date-col',
    className: 'creation-date'
  },
  {
    Header: 'Data Visualizzazione',
    id: 'viewd_date',
    accessor: (d) => {
      const statusesOb = getCommunicationStatuses(d, [viewedStatusItem])
      return statusesOb && statusesOb.length
        ? localGetTimeHourWithUtcOffset(statusesOb[0].lastTimestamp)
        : '--'
    }
  },
  {
    Header: 'Documenti',
    id: 'document_dl',
    Cell: ({ original }) => {
      const areThereDocs = countInteractionsFiles(original) > 0
      return (
        <span
          className='span-doc-dl-icon'
          style={{ width: '100%', height: '100%', color: '#5094DE' }}
          onClick={(e) => {
            handleClickDocDlCell(e)
          }}
        >
          {areThereDocs && <FontAwesomeIcon icon={faDownload} size='lg' />}
        </span>
      )
    },
    headerClassName: 'document-dl-col',
    className: 'document-dl',
    show: false
  }
  // {
  //     Header: "Canale",
  //     accessor: "comm_channel",
  //     headerClassName: 'comm-channel-col',
  //     className: 'comm-channel'
  // }
]
