import React from 'react'

const Icon = ({ name }) => {
  switch (name) {
    case 'page':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='17'
          fill='none'
          viewBox='0 0 16 17'
        >
          <g fill='#fff' clipPath='url(#clip0_703_2378)'>
            <path d='M5.333 8.114a.667.667 0 000 1.333h5.334a.667.667 0 100-1.333H5.333zM4.667 11.447c0-.368.298-.666.666-.666h5.334a.667.667 0 010 1.333H5.333a.667.667 0 01-.666-.667zM5.333 5.447a.667.667 0 000 1.334h1.334a.667.667 0 000-1.334H5.333z' />
            <path
              fillRule='evenodd'
              d='M2.586 1.366A2 2 0 014 .781h5.333c.177 0 .347.07.472.195l4 4a.666.666 0 01.195.471v8a2 2 0 01-2 2H4a2 2 0 01-2-2V2.781a2 2 0 01.586-1.415zM4 2.114a.667.667 0 00-.667.667v10.666a.667.667 0 00.667.667h8a.667.667 0 00.667-.667V6.114H9.333a.667.667 0 01-.666-.667V2.114H4zm6 .943l1.724 1.724H10V3.057z'
              clipRule='evenodd'
            />
          </g>
          <defs>
            <clipPath id='clip0_703_2378'>
              <path
                fill='#fff'
                d='M0 0H16V16H0z'
                transform='translate(0 .114)'
              />
            </clipPath>
          </defs>
        </svg>
      )
    case 'send':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          fill='none'
          viewBox='0 0 24 24'
        >
          <path
            fill='#3B3B3B'
            fillRule='evenodd'
            d='M22.707 1.293a.998.998 0 01.23 1.058l-6.993 19.98a1 1 0 01-1.858.075l-3.844-8.648-8.648-3.844a1 1 0 01.076-1.858l19.979-6.993a1 1 0 011.058.23zM18.194 4.39L4.711 9.111l6.067 2.696 7.416-7.416zm-6.001 8.83l7.415-7.415-4.719 13.483-2.696-6.068z'
            clipRule='evenodd'
          />
        </svg>
      )
    case 'hand-pointer':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          fill='none'
          viewBox='0 0 24 24'
        >
          <path
            fill='#3B3B3B'
            d='M8.348 4.076a1.467 1.467 0 112.935 0v5.437a1.764 1.764 0 012.825.697 1.761 1.761 0 013.041 1.068 1.761 1.761 0 012.939 1.31v4.107a4.695 4.695 0 01-4.697 4.696h-3.128a3.73 3.73 0 01-.54-.036 7.35 7.35 0 01-5.136-2.899l-2.641-3.521a1.465 1.465 0 01.293-2.055 1.465 1.465 0 012.055.294l2.054 2.74V4.076zm4.109 9.685a.589.589 0 00-.587-.587.589.589 0 00-.587.587v3.522c0 .322.264.587.587.587a.589.589 0 00.587-.587V13.76zm1.76-.587a.589.589 0 00-.587.587v3.522c0 .322.265.587.587.587a.589.589 0 00.587-.587V13.76a.589.589 0 00-.587-.587zm2.935.587a.589.589 0 00-.587-.587.589.589 0 00-.587.587v3.522c0 .322.264.587.587.587a.589.589 0 00.587-.587V13.76z'
          />
        </svg>
      )
    case 'file-upload':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='24'
          fill='none'
          viewBox='0 0 24 24'
        >
          <path
            fill='#3B3B3B'
            d='M7.043 2.609a2.35 2.35 0 00-2.347 2.348v14.087a2.35 2.35 0 002.347 2.347h9.392a2.35 2.35 0 002.348-2.347V8.478h-4.696c-.65 0-1.174-.524-1.174-1.174V2.61h-5.87zm7.044 0v4.695h4.695L14.087 2.61zM12.62 17.576c0 .488-.393.88-.881.88a.878.878 0 01-.88-.88v-3.745L9.72 14.968a.88.88 0 01-1.244-1.244l2.642-2.641a.877.877 0 011.244 0l2.641 2.641a.88.88 0 01-1.244 1.244l-1.137-1.137v3.745h-.003z'
          />
        </svg>
      )
    case 'download':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          fill='none'
          viewBox='0 0 16 16'
        >
          <path
            fill='#fff'
            d='M8.667 2a.667.667 0 00-1.334 0v6.39L5.138 6.196a.667.667 0 10-.943.943l3.334 3.333c.26.26.682.26.942 0l3.334-3.333a.667.667 0 10-.943-.943L8.667 8.391V2z'
          />
          <path
            fill='#fff'
            d='M2 9.333c.368 0 .667.299.667.667v2.667a.667.667 0 00.666.666h9.334a.667.667 0 00.666-.666V10a.667.667 0 011.334 0v2.667a2 2 0 01-2 2H3.333a2 2 0 01-2-2V10c0-.368.299-.667.667-.667z'
          />
        </svg>
      )
    case 'back':
      return (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='24'
          height='25'
          fill='none'
          viewBox='0 0 24 25'
        >
          <path
            fill='#3B3B3B'
            d='M10.414 12.5l5.293-5.293a1 1 0 00-1.414-1.414l-6 6a1 1 0 000 1.414l6 6a1 1 0 001.414-1.414L10.414 12.5z'
          />
        </svg>
      )
    default:
      return null
  }
}

export default Icon
