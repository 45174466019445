import React from 'react'
import { InputGroup, InputGroupText, InputGroupAddon, Input } from 'reactstrap'

const SearchBar = (props) => {
  const handleSubmit = (event) => {
    // Reload communication pagination data from server
    props.loadCommunications({ ...props })

    // If search string is empty, then lose focus
    if (!props.communications.searchStr) {
      document.activeElement.blur()
    }

    event.preventDefault()
  }

  const handleChange = (event) => {
    // Update store pagination input data
    props.loadCommunicationsSearchStrUpdated(event.target.value)
  }

  const textPresentClass = props.communications.searchStr
    ? 'text-present'
    : ''

  return (
    <React.Fragment>
      <form className='search-form' onSubmit={handleSubmit}>
        <InputGroup className='no-border'>
          <Input
            value={props.communications.searchStr}
            onChange={handleChange}
            placeholder='Cerca...'
            className={textPresentClass}
          />
          <InputGroupAddon addonType='append' onClick={handleSubmit}>
            <InputGroupText className={textPresentClass}>
              <i className='nc-icon nc-zoom-split' />
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
      </form>
    </React.Fragment>
  )
}

export default SearchBar
