import React, { useEffect, useRef } from 'react'
import moment from 'moment'
import PerfectScrollbar from 'perfect-scrollbar'
import Toast from '../../Notifications/Toast'
import linearDocumentTypes from '../constants/linearDocumentsType'
import eventConversion from '../constants/eventConversion'

const DefaultTimelineCmp = (props) => {
  const timelinePanel = useRef(null)
  let pst

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      pst = new PerfectScrollbar(timelinePanel.current)
    }

    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        pst.destroy()
      }
    }
  }, [])

  const scrollTimeline = () => {
    const cardBody = document.getElementsByClassName('card-body')[0]
    if (cardBody) {
      cardBody.classList.add('scrollingClass')
      cardBody.scrollTop = 0
      cardBody.classList.remove('scrollingClass')
    }
  }

  const renderToastNotification = () => {
    return (
      <React.Fragment>
        <Toast {...props} scrollTimeline={scrollTimeline} />
      </React.Fragment>
    )
  }

  const renderListEvents = (events) => {
    const { communicationDetails } = props
    communicationDetails.documents = communicationDetails.documents
      ? communicationDetails.documents.concat(linearDocumentTypes)
      : linearDocumentTypes

    const listEvents =
      events &&
      events
        .map((event, index) => {
          let paragraph = <p>{event.description}</p>
          if (event.code === 'DOC_UPLOADED' || event.code === 'DOC_REMOVED') {
            const singleLabel =
              props.communicationDetails &&
              props.communicationDetails.documents &&
              props.communicationDetails.documents.filter((val) => {
                return event.metadata.doc_type === val.code
              })[0]
            if (singleLabel)
              paragraph = <p>{event.description + singleLabel.label}</p>
            else {
              paragraph = <p>{event.description + 'SCONOSCIUTO'}</p>
            }
          }
          if (event.show === true) {
            const value = event.value
            const conv = eventConversion[value]
            let palette = 'default'
            let icon = 'nc-alert-circle-i'
            if (conv && conv.palette) {
              palette = conv.palette
            }
            if (conv && conv.icon) {
              icon = conv.icon
            }
            return (
              <li
                key={index}
                className={index % 2 ? 'timeline-inverted' : null}
              >
                <div className={'timeline-badge ' + palette || 'default'}>
                  <i className={'nc-icon ' + icon || 'default'} />
                </div>
                <div className='timeline-panel'>
                  {event.metadata && event.metadata.isSupport && (
                    <div className='operator-icon-container'>
                      <i className='fa fa-chalkboard-teacher' />
                    </div>
                  )}
                  <div className='timeline-heading'>
                    <span className={'badge badge-' + palette + ' badge-pill'}>
                      {event.title}
                    </span>
                  </div>
                  <div className='timeline-body'>{paragraph}</div>
                  <h6>
                    <i className='fa fa-clock-o' />
                    {event.timestamp
                      ? moment(new Date(event.timestamp)).format(
                          'DD/MM/YYYY HH:mm:ss'
                        )
                      : '--'}
                  </h6>
                </div>
              </li>
            )
          } else {
            return null
          }
        })
        .filter((e) => e)
    return <React.Fragment>{listEvents}</React.Fragment>
  }

  let timeEvents =
    props.communicationDetails.data &&
    props.communicationDetails.data.events
  const ToastNotification = renderToastNotification(
    props.communicationDetails.data
  )
  if (timeEvents && timeEvents.length > 0) {
    pst && pst.update()
  }

  if (props.type === 'LINEAR') {
    timeEvents = timeEvents.filter(
      (event) => event.code !== 'HRM-03' && event.code !== 'SMS-03'
    )
  }

  return (
    <React.Fragment>
      <div className='row notifications-section'>
        <div className='col-md-12'>{ToastNotification}</div>
      </div>
      <div className='row timeline-section'>
        <div className='col-md-12'>
          <div className='shadow-top' />
          <div className='card-timeline card-plain card'>
            <div className='card-body' ref={timelinePanel}>
              <ul className='timeline'>
                {renderListEvents(timeEvents)}
                <li key='start' className='timeline-inverted'>
                  <div className='timeline-badge start success' />
                  <div className='timeline-panel start'>
                    <div className='timeline-heading'>
                      <span className='badge badge-success badge-pill'>
                        INIZIO
                      </span>
                    </div>
                    <div className='timeline-body'>
                      <p>Creazione comunicazione</p>
                    </div>
                    <h6>
                      <i className='fa fa-clock-o' />
                      {timeEvents
                        ? moment(
                            new Date(
                              props.communicationDetails.data.creation_date
                            )
                          ).format('DD/MM/YYYY HH:mm:ss')
                        : '--'}
                    </h6>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className='shadow-bottom' />
        </div>
      </div>
    </React.Fragment>
  )
}

export default DefaultTimelineCmp
