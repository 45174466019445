import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Nav,
  NavItem,
  Row
} from 'reactstrap'
import { loadStatsAct, updateSelectedComponentAct } from './_actions'

import Button from '../../components/ui/button/Button'
import Icon from '../../components/ui/icon/Icon'
import CommunicationsChart from '../../components/communications-chart/CommunicationChart'
import ReactSelect from 'react-select'
import moment from 'moment'
import CommunicationsStatsHistory from '../../components/communications-stats-history/CommunicationsStatsHistory'
import { monthNames } from './utils'

const CommunicationsDashboardPage = ({
  goToCommunications,
  loadStats,
  componentKey,
  stats,
  ...props
}) => {
  const today = moment()

  const yearFilterOptions = [
    {
      label: 'Anno corrente',
      value: today.year()
    },
    {
      label: 'Anno passato',
      value: today.year() - 1
    }
  ]

  const [yearFilter, setYearFilter] = useState(yearFilterOptions[0])
  const [monthFilter, setMonthFilter] = useState(null)
  const [hasCurrentMonthFilter, setHasCurrentMonthFilter] = useState(false)

  const statsItems =
    stats && stats.list && stats.list.data
      ? [
        ...stats.list.data.sort((a, b) =>
          moment(a.timestamp).diff(moment(b.timestamp))
        )
      ]
      : []

  const lastUpdated = useMemo(() => {
    if (statsItems.length > 0 && yearFilter.value === today.year()) {
      return moment(statsItems[statsItems.length - 1].timestamp).format(
        'DD/MM/YYYY'
      )
    }

    return null
  }, [yearFilter, statsItems])

  useEffect(() => {
    loadStats({
      year: yearFilter.value
    })
  }, [componentKey, yearFilter])

  const monthFilterOptions = useMemo(() => {
    let monthsToShow = today.month() + 1

    if (yearFilter && yearFilter.value !== today.year()) {
      monthsToShow = 12
    }

    return monthNames.slice(0, monthsToShow).map((month, idx) => ({
      label: month,
      value: idx
    }))
  }, [yearFilter])

  const handleYearFilter = (selectedOption) => {
    setYearFilter(selectedOption)
    setMonthFilter(null)
    setHasCurrentMonthFilter(false)
  }

  const handleMonthFilter = (selectedOption) => {
    setMonthFilter(selectedOption)
    setHasCurrentMonthFilter(false)
  }

  const handleCurrentMonthFilter = () => {
    setMonthFilter(null)
    setYearFilter(yearFilterOptions[0])
    setHasCurrentMonthFilter(true)
  }

  const isActiveClassName = useCallback((type) => {
    if (type === 'year') {
      return !hasCurrentMonthFilter && !monthFilter ? 'active' : ''
    }

    if (type === 'month') {
      return !hasCurrentMonthFilter && monthFilter ? 'active' : ''
    }

    if (type === 'currentMonth') {
      return hasCurrentMonthFilter ? 'active' : ''
    }
  })

  const filteredData = useMemo(() => {
    let items

    if (yearFilter) {
      items = statsItems.filter(
        (i) => moment(i.timestamp).year() === yearFilter.value
      )
    }

    if (monthFilter) {
      items = statsItems.filter(
        (i) => moment(i.timestamp).month() === monthFilter.value
      )
    }

    if (hasCurrentMonthFilter) {
      items = statsItems.filter(
        (i) =>
          moment(i.timestamp).year() === today.year() &&
          moment(i.timestamp).month() === today.month()
      )
    }

    return items
  }, [statsItems, yearFilter, monthFilter, hasCurrentMonthFilter])

  const dateLabel = useMemo(() => {
    if (yearFilter && !monthFilter && !hasCurrentMonthFilter) {
      const firstDay = moment()
        .year(yearFilter.value)
        .startOf('year')
        .format('DD/MM/YYYY')

      const lastDay =
        yearFilter.value === today.year()
          ? today.format('DD/MM/YYYY')
          : moment().year(yearFilter.value).endOf('year').format('DD/MM/YYYY')

      return `dal ${firstDay} al ${lastDay}`
    }

    if (yearFilter && monthFilter) {
      const firstDay = moment()
        .year(yearFilter.value)
        .month(monthFilter.value)
        .startOf('month')
        .format('DD/MM/YYYY')

      const lastDay =
        yearFilter.value === today.year() && monthFilter.value === today.month()
          ? today.format('DD/MM/YYYY')
          : moment()
            .year(yearFilter.value)
            .month(monthFilter.value)
            .endOf('month')
            .format('DD/MM/YYYY')

      return `dal ${firstDay} al ${lastDay}`
    }

    if (hasCurrentMonthFilter) {
      const firstDay =  moment().startOf('month').format('DD/MM/YYYY')
      const lastDay =  moment().format('DD/MM/YYYY')

      return `dal ${firstDay} al ${lastDay}`
    }
  }, [yearFilter, monthFilter, hasCurrentMonthFilter])

  return (
    <React.Fragment>
      <div className='content communications-page communications-dashboard-page'>
        <Row>
          <div className='dashboard-page__header'>
            <h2>Dashboard</h2>
            {statsItems.length ? (
              <React.Fragment>
                <div className='filters'>
                  <Nav>
                    <NavItem>
                      <ReactSelect
                        className={
                          'react-select-custom ' + isActiveClassName('year')
                        }
                        onChange={handleYearFilter}
                        value={yearFilter}
                        placeholder='Scegli anno'
                        options={yearFilterOptions}
                      />
                    </NavItem>
                    <NavItem>
                      <button
                        className={
                          'filter-btn ' + isActiveClassName('currentMonth')
                        }
                        onClick={handleCurrentMonthFilter}
                      >
                        <span>Mese corrente</span>
                      </button>
                    </NavItem>
                    <NavItem>
                      <ReactSelect
                        className={
                          'react-select-custom ' + isActiveClassName('month')
                        }
                        onChange={handleMonthFilter}
                        value={monthFilter}
                        placeholder='Scegli mese'
                        options={monthFilterOptions}
                      />
                    </NavItem>
                  </Nav>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </Row>
        <Row>
          <Col>
            {statsItems.length ? (
              <CommunicationsChart
                data={filteredData}
                dateLabel={dateLabel}
                lastUpdated={lastUpdated}
              />
            ) : (
              <Card>
                <CardHeader className='header-with-search'>
                  <CardTitle tag='h4'>Comunicazioni create:</CardTitle>
                </CardHeader>
                <CardBody>
                  <p>Nessuna comunicazione disponibile</p>
                </CardBody>
              </Card>
            )}
          </Col>
        </Row>
        <Button
          variant='dark'
          data-testid='goToCommunicationsButton'
          icon={<Icon name='page' />}
          onClick={goToCommunications}
        >
          Dettaglio comunicazioni
        </Button>
        <Row style={{ marginTop: '48px' }}>
          <Col>
            <CommunicationsStatsHistory data={statsItems} />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state, ownProps) => ({
  stats: state.stats
})

export const mapDispatchToProps = (dispatch, ownProps) => ({
  loadStats: (params) => dispatch(loadStatsAct(params)),
  updateSelectedComponent: (componentOption) =>
    dispatch(updateSelectedComponentAct(componentOption))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationsDashboardPage)
