import { localGetTimeHourWithUtcOffset } from './../../../utils/custom_conversions'

export default [
  {
    Header: 'ID Evento',
    accessor: 'crash_id'
  },
  {
    Header: 'token',
    accessor: 'token'
  },
  {
    Header: 'Data Accadimento',
    id: 'creation_date',
    accessor: (d) => {
      return localGetTimeHourWithUtcOffset(d.creation_date)
    }
  },
  {
    Header: 'Nome Assicurato',
    id: 'assic_nome',
    accessor: (d) => {
      const _assic_nome = d.assic_nome ? d.assic_nome : ''
      const _assic_cognome = d.assic_cognome ? d.assic_cognome : ''
      if (!_assic_nome && !_assic_cognome) {
        return '--'
      }
      return d.assic_nome + ' ' + d.assic_cognome
    }
  },
  {
    Header: 'Targa',
    accessor: 'veic_targa'
  }
]
