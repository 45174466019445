const eventConversion = {
  IS_KASCO: {
    palette: 'warning',
    icon: 'nc-bullet-list-67'
  },
  IS_RCA: {
    palette: 'warning',
    icon: 'nc-bullet-list-67'
  },
  IS_RCA_MANY: {
    palette: 'warning',
    icon: 'nc-bullet-list-67'
  },
  'CAP-01': {
    palette: 'info',
    icon: 'nc-check-2'
  },
  'CAP-02': {
    palette: 'success',
    icon: 'nc-check-2'
  },
  'HRM-01': {
    palette: 'primary',
    icon: 'nc-tap-01'
  },
  'HRM-03': {
    palette: 'info',
    icon: 'nc-check-2'
  },
  IS_CLAIM_CONFIRMED: {
    palette: 'success',
    icon: 'nc-bullet-list-67'
  },
  POSITION_CONFIRMED: {
    palette: 'success',
    icon: 'nc-map-big'
  },
  BAREMES_MODIFIED_CONFIRMED: {
    palette: 'success',
    icon: 'nc-bullet-list-67'
  },
  IS_CLAIM_NOT_CONFIRMED: {
    palette: 'danger',
    icon: 'nc-simple-remove'
  },
  POSITION_MODIFIED: {
    palette: 'warning',
    icon: 'nc-map-big'
  },
  BAREMES_MODIFIED: {
    palette: 'warning',
    icon: 'nc-bullet-list-67'
  },
  LAST_STEP_VISITED: {
    palette: 'info',
    icon: 'nc-bullet-list-67'
  },
  IS_DOCUMENT_DOWNLOADED: {
    palette: 'warning',
    icon: 'nc-check-2'
  },
  LAST_PAGE_REACHED: {
    palette: 'success',
    icon: 'nc-check-2'
  },
  APPLE_STORE_DOWNLOAD_APP_CLICK: {
    palette: 'extra-info',
    icon: 'nc-mobile'
  },
  GOOGLE_PLAY_DOWNLOAD_APP_CLICK: {
    palette: 'extra-info',
    icon: 'nc-mobile'
  },
  BATCH_BAREMES_CONFIRMED: {
    palette: 'warning',
    icon: 'nc-bullet-list-67'
  },
  ALERT_ENABLED: {
    palette: 'success',
    icon: 'nc-check-2'
  },
  ALERT_DISABLED: {
    palette: 'danger',
    icon: 'nc-simple-remove'
  },
  'SMS-03': {
    palette: 'info',
    icon: 'nc-check-2'
  },
  'SMS-02': {
    palette: 'success',
    icon: 'nc-send'
  },
  'SMS-04': {
    palette: 'warning',
    icon: 'nc-cloud-download-93'
  },
  'SMS-05': {
    palette: 'danger',
    icon: 'nc-simple-remove'
  },
  'HRM-02': {
    palette: 'success',
    icon: 'nc-satisfied'
  },
  'PHOTO-DAMAGE-POINT-TOP-LEFT': {
    palette: 'default',
    icon: 'nc-image'
  },
  'PHOTO-DAMAGE-POINT-TOP-CENTER': {
    palette: 'default',
    icon: 'nc-image'
  },
  'PHOTO-DAMAGE-POINT-TOP-RIGHT': {
    palette: 'default',
    icon: 'nc-image'
  },
  'PHOTO-DAMAGE-POINT-CENTER-LEFT': {
    palette: 'default',
    icon: 'nc-image'
  },
  'PHOTO-DAMAGE-POINT-CENTER-CENTER': {
    palette: 'default',
    icon: 'nc-image'
  },
  'PHOTO-DAMAGE-POINT-CENTER-RIGHT': {
    palette: 'default',
    icon: 'nc-image'
  },
  'PHOTO-DAMAGE-POINT-BOTTOM-LEFT': {
    palette: 'default',
    icon: 'nc-image'
  },
  'PHOTO-DAMAGE-POINT-BOTTOM-CENTER': {
    palette: 'default',
    icon: 'nc-image'
  },
  'PHOTO-DAMAGE-POINT-BOTTOM-RIGHT': {
    palette: 'default',
    icon: 'nc-image'
  },
  'PHOTO-INCIDENT': {
    palette: 'warning',
    icon: 'nc-image'
  },
  'PHOTO-CAI': {
    palette: 'success',
    icon: 'nc-image'
  },
  Q1_ANSWER_SELECTED: {
    palette: 'primary',
    icon: 'nc-alert-circle-i'
  },
  Q2_ANSWER_SELECTED: {
    palette: 'primary',
    icon: 'nc-alert-circle-i'
  },
  Q3_ANSWER_SELECTED: {
    palette: 'primary',
    icon: 'nc-alert-circle-i'
  },
  DOC_UPLOADED: {
    palette: 'success',
    icon: 'nc-alert-circle-i'
  },
  DOC_REMOVED: {
    palette: 'success',
    icon: 'nc-alert-circle-i'
  }
}

export default eventConversion
