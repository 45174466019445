import React from 'react'

const Button = ({ variant, icon, children, onClick, className, ...props }) => {
  let btnClasses = ''

  switch (variant) {
    case 'dark':
      btnClasses += ` btn btn-round btn-dark`
      break
    case 'inline':
      btnClasses += ` btn-inline`
      break
    default:
      btnClasses += ' btn btn-round'
      break
  }

  return (
    <button
      {...(onClick && { onClick })}
      className={`${btnClasses} ${className}`}
      {...props}
    >
      {icon && <span style={{ marginRight: '8px' }}>{icon}</span>}
      {children}
    </button>
  )
}

export default Button
