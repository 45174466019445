import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  loadCommunicationDetailsAct,
  loadBaremesInfoAct,
  showClaimDataAct,
  loadDocumentsListAct,
  takeClaimControlAct,
  openClaimInteractionModalAct,
  closeClaimInteractionModalAct,
  triggerTooltipAct,
  clearTooltipAct,
  lockCommunicationAct,
  unlockCommunicationAct,
  clientContactedAct,
  clientNotContactedAct,
  updateNoteValueAct,
  submitUpdatedNoteValueAct,
  cleanSelectedCommunicationDataAct
} from './_actions'
import {
  selectedCommunicationAct,
  loadCorrelatedCommunicationsAct,
  loadCommunicationsFilterIdAct,
  updateSelectedComponentAct,
  loadCommunicationsAct,
  cleanSelectedCommunicationAct
} from './../communications/_actions'
import { openDefaultModalAct, closeDefaultModalAct } from '../Layout/_actions'
import { Row, Col, Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import FactoryDetailsComponents from './FactoryDetailsComponents.jsx'
import ClaimInteractionModalContainer from './ClaimInteractionModalContainer'
import Loader from './../../components/Loader/Loader'
import Switch from 'react-switch'
import OperatorNotes from './../../components/OperatoreNotes/OperatoreNotes'
import filter from '../../utils/filter-widgets'

import './communications-details-page.scss'
import Button from '../../components/ui/button/Button.jsx'
import Icon from '../../components/ui/icon/Icon.jsx'

const CommunicationDetailsPage = (props) => {

  const {
    communications,
    communicationDetails,
    loadCommunicationDetails,
    loadBaremesInfo,
    loadDocumentsList,
    showClaimData,
    takeClaimControl,
    triggerTooltip,
    clearTooltip,
    openClaimInteractionModal,
    closeClaimInteractionModal,
    openDefaultModal,
    closeDefaultModal,
    lockCommunication,
    unlockCommunication,
    clientContacted,
    clientNotContacted,
    updateNoteValue,
    submitUpdatedNoteAndCloseModal,
    selectedCommunication,
    loadCorrelatedCommunications,
    loadCommunicationsFilterId,
    updateSelectedComponent,
    loadCommunications,
    cleanSelectedCommunication,
    cleanSelectedCommunicationData,
    hideTimelineSection,
    hideDetailsHeaderSection,
    goBack
  } = props

  const factoryDetailsComponents = new FactoryDetailsComponents()

  const [timeInterval, setTimeInterval] = useState(null)

  useEffect(() => {
    window.addEventListener('beforeunload', onUnload)
    const isPolling = false
    const isPolling2 = false

    if (communications.singleCommunicationFromServer && communications.nowId) {
      loadFirstNowIdCommunication()
    } else {
      loadCommunicationDetails(isPolling)
      loadBaremesInfo(isPolling2)

      setTimeout(() => {
        loadCommunicationDetails(true)
        loadBaremesInfo(true)
      }, 500)
    }

    loadDocumentsList()

    if (!timeInterval) {
      let _timeInterval = setInterval(() => {
        loadCommunicationDetails(true)
        loadBaremesInfo(true)
      }, 6000)
      setTimeInterval(_timeInterval)
    }

    return () => {
      clearInterval(timeInterval)
      clearTooltip()
      closeDefaultModal && closeDefaultModal()
      window.removeEventListener('beforeunload', onUnload)
    }
  }, [])

  const onUnload = (event) => {
    if (communicationDetails.data && communicationDetails.data.isSupportLocked === true) {
      unlockCommunication()
    }
  }

  const loadFirstNowIdCommunication = () => {
    const nowId = communications.nowId
    if (nowId) {
      cleanSelectedCommunication()
      cleanSelectedCommunicationData()

      loadCommunicationsFilterId(nowId)
      updateSelectedComponent({ value: 'NOW_AGE' })
      loadCommunications({
        ...communications,
        selectFirstCommunication: true
      })
    }
  }

  const refreshPageInfo = () => {
    loadCommunicationDetails(false)
    loadBaremesInfo(false)
  }

  const handleToggleClientContacted = (checked) => {
    if (checked) {
      clientContacted()
    } else {
      clientNotContacted()
    }
  }

  const filterCallToActionBtn = (detailsListComponent) => {
    const blackListToggleMap = ['NOW_AGE', 'LINEAR']
    let groupName = null
    if (
      communications.selectedComponent &&
      communications.selectedComponent.value
    ) {
      groupName = communications.selectedComponent.value
    }
    const callToAction = (
      <button
        className='btn btn-info details-call-to-action'
        onClick={() => openClaimInteractionModal()}
      >
        <span className='btn-label'>
          <i className='fa fa-chalkboard-teacher' />
        </span>
        {detailsListComponent &&
          detailsListComponent.detailsPage &&
          detailsListComponent.detailsPage.callToAction}
      </button>
    )
    return filter.shouldRenderThisWidget(groupName, blackListToggleMap)
      ? callToAction
      : null
  }

  const toggleTimelineModalOpened = (open) => {
    const detailsListComponent =
      communications.selectedComponent &&
      factoryDetailsComponents.createDetailsList(
        communications.selectedComponent,
        { ...props }
      )
    if (open) {
      openDefaultModal({
        modalClassName: 'timeline-modal',
        title: 'Dettaglio interazione',
        customBodyPresent: true,
        customBodyCmp: (
          <div> {detailsListComponent && detailsListComponent.timelineCmp}</div>
        ),
        cancelBtnPresent: false,
        closeAndCancelHandlerFunctionName: closeTimelineModelHandler,
        closeAndSaveHandlerFunctionName: closeTimelineModelHandler,
        okBtnLabel: 'X'
      })
    } else {
      closeDefaultModal && closeDefaultModal()
    }
  }

  const closeTimelineModelHandler = () => {
    toggleTimelineModalOpened(false)
  }

  const render = () => {
    const template =
      communications.selectedCommunication &&
      communications.selectedCommunication.type
    const detailsListComponent =
      communications.selectedComponent &&
      factoryDetailsComponents.createDetailsList(
        communications.selectedComponent,
        { ...props }
      )
    const hiddenTranslatedAnimated_EndClass = communicationDetails.isLoading
      ? ''
      : 'hidden-translated-content-animated-end'
    const currentCommunication = communicationDetails.data
    const clientAlreadyContacted = !!(
      currentCommunication && currentCommunication.clientContactedByOperator
    )
    let clientContactedSectionPresent = !!(
      detailsListComponent &&
      detailsListComponent.detailsPage &&
      detailsListComponent.detailsPage.clientContactedOptionPresent
    )
    clientContactedSectionPresent =
      clientContactedSectionPresent &&
      !(
        props.detailsParams && props.detailsParams.hideClientContacted
      )
    const claimInteractionPresent = !(
      props.detailsParams && props.detailsParams.hideTakeControl
    )

    const clientContactedLabel =
      detailsListComponent &&
      detailsListComponent.detailsPage &&
      detailsListComponent.detailsPage.clientContactedLabel

    const showWidget = filter.shouldRenderThisWidget(template, ['AGE_INFO_DOC'])
      ? 'show'
      : 'not-show'

    const showTimelineSection = !hideTimelineSection
    const showDetailsHeaderSection = !hideDetailsHeaderSection

    const isLinear =
      communications.selectedComponent &&
      communications.selectedComponent.value === 'LINEAR'

    const handleBackBtn = () => goBack()

    return (
      <React.Fragment>
        {detailsListComponent && (
          <ClaimInteractionModalContainer
            interactionDefaultMode={detailsListComponent.interactionDefaultMode}
          />
        )}
        <div className='content communication-details'>
          {showDetailsHeaderSection && (
            <Row>
              <Col md='12'>
                <div
                  className={
                    'page-title-with-corner-container visible-translated-content-animated-start ' +
                    hiddenTranslatedAnimated_EndClass
                  }
                >
                  {clientContactedSectionPresent && (
                    <div className='page-title-container client-contacted-info'>
                      <h5>
                        <Button variant='inline' onClick={handleBackBtn}>
                          <Icon name='back' />
                        </Button>
                        {clientContactedLabel}
                      </h5>
                      <Switch
                        onChange={handleToggleClientContacted}
                        checked={clientAlreadyContacted}
                        onColor='#6bd098'
                        offColor='#989591'
                        height={32}
                        width={70}
                        handleDiameter={28}
                        activeBoxShadow='0 6px 10px -4px rgba(0, 0, 0, 0.15)'
                      />
                      <div className='actions-btns'>
                        <button
                          className='btn-round btn btn-info btn-link usage-action edit-action'
                          onClick={() =>
                            openDefaultModal({
                              modalClassName: 'notes-modal',
                              title: 'Note contatto',
                              customBodyPresent: true,
                              customBodyCmp: <OperatorNotes {...props} />,
                              mainText: 'Inserire note qui',
                              cancelBtnPresent: true,
                              cancelBtnLabel: 'Chiudi',
                              closeAndSaveHandlerFunctionName:
                                submitUpdatedNoteAndCloseModal,
                              okBtnLabel: 'Salva note e chiudi'
                            })
                          }
                        >
                          <i className='far fa-edit' />
                          {currentCommunication &&
                            currentCommunication.operatorNotes && (
                              <h6 className='additional-info'>note presenti</h6>
                            )}
                        </button>
                      </div>
                    </div>
                  )}
                  <div className='actions-btns'>
                    <button
                      className='btn-round btn btn-info btn-link usage-action'
                      onClick={refreshPageInfo}
                    >
                      <i className='fa fa-sync' />
                    </button>
                    {claimInteractionPresent &&
                      filterCallToActionBtn(detailsListComponent)}
                  </div>
                </div>
              </Col>
            </Row>
          )}
          <Row className='page-h-centered'>
            {communicationDetails.isLoading && <Loader />}
          </Row>
          {currentCommunication && (
            <Row
              className={
                'visible-translated-content-animated-start ' +
                hiddenTranslatedAnimated_EndClass
              }
            >
              {showTimelineSection && (
                <Col md='6' className={showWidget}>
                  {detailsListComponent && detailsListComponent.timelineCmp}
                </Col>
              )}
              {showWidget === 'show' && (
                <Col md='6' className='details-left-side'>
                  <Card className='details-list-card'>
                    <CardHeader className='header-center header-info-recap'>
                      <CardTitle tag='h4'>
                        {detailsListComponent &&
                          detailsListComponent.detailsPage &&
                          detailsListComponent.detailsPage.tabListDataTitle}
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      {detailsListComponent &&
                        detailsListComponent.detailsListCmp}
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          )}
        </div>
      </React.Fragment>
    )
  }

  return render()
}

const mapStateToProps = (state, ownProps) => ({
  communications: state.communications,
  communicationDetails: state.communicationDetails
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  loadCommunicationDetails: (isPolling) =>
    dispatch(loadCommunicationDetailsAct(isPolling)),
  loadBaremesInfo: (isPolling) => dispatch(loadBaremesInfoAct(isPolling)),
  loadDocumentsList: () => dispatch(loadDocumentsListAct()),
  showClaimData: () => dispatch(showClaimDataAct()),
  takeClaimControl: () => dispatch(takeClaimControlAct()),
  triggerTooltip: () => dispatch(triggerTooltipAct()),
  clearTooltip: () => dispatch(clearTooltipAct()),
  openClaimInteractionModal: () => dispatch(openClaimInteractionModalAct()),
  closeClaimInteractionModal: () => dispatch(closeClaimInteractionModalAct()),
  openDefaultModal: (modalParams) => dispatch(openDefaultModalAct(modalParams)),
  closeDefaultModal: () => dispatch(closeDefaultModalAct()),
  lockCommunication: () => dispatch(lockCommunicationAct()),
  unlockCommunication: () => dispatch(unlockCommunicationAct()),
  clientContacted: () => dispatch(clientContactedAct()),
  clientNotContacted: () => dispatch(clientNotContactedAct()),
  updateNoteValue: (newNoteValue) => dispatch(updateNoteValueAct(newNoteValue)),
  submitUpdatedNoteAndCloseModal: () => {
    dispatch(closeDefaultModalAct())
    return dispatch(submitUpdatedNoteValueAct())
  },
  selectedCommunication: (communication) =>
    dispatch(selectedCommunicationAct(communication)),
  loadCorrelatedCommunications: (correlated_crash_id) =>
    dispatch(loadCorrelatedCommunicationsAct(correlated_crash_id)),
  loadCommunicationsFilterId: (_id) =>
    dispatch(loadCommunicationsFilterIdAct(_id)),
  updateSelectedComponent: (componentOption) =>
    dispatch(updateSelectedComponentAct(componentOption)),
  loadCommunications: (paramInfo) => dispatch(loadCommunicationsAct(paramInfo)),
  cleanSelectedCommunication: () => dispatch(cleanSelectedCommunicationAct()),
  cleanSelectedCommunicationData: () =>
    dispatch(cleanSelectedCommunicationDataAct())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommunicationDetailsPage)