import React from 'react'
import RealtimeDetailsListCmp from '../../components/DetailsList/REALTIME/RealtimeDetailsListCmp'
import DefaultTimelineCmp from '../../components/Timeline/DEFAULT/DefaultTimelineCmp'
import FinitaliaDetailsListCmp from '../../components/DetailsList/FINITALIA/FinitaliaDetailsListCmp'
import LinkSmsDetailsListCmp from '../../components/DetailsList/LINK_SMS/LinkSmsDetailsListCmp'
import NowAgeDetailsListCmp from '../../components/DetailsList/NOW_AGE/NowAgeDetailsListCmp'
import LinearDetailsListCmp from '../../components/DetailsList/LINEAR/LinearDetailsListCmp'

const baseDetailsPage = {
  title: 'Dettaglio Comunicazione',
  clientContactedOptionPresent: true,
  clientContactedLabel: 'Cliente contattato:',
  tabListDataTitle: 'Dati',
  tabClaimInteractionTitle: 'Interazione',
  callToAction: 'Interazione'
}

export default function FactoryDetailsComponents() {
  this.createDetailsList = function (type, props) {
    if (type.value === 'REALTIME' || type.value === 'REALTIME_DEMO') {
      return {
        detailsListCmp: <RealtimeDetailsListCmp {...props} />,
        timelineCmp: <DefaultTimelineCmp {...props} />,
        detailsPage: Object.assign({}, baseDetailsPage, {
          tabListDataTitle: 'Dati Sinistro',
          tabClaimInteractionTitle: 'Interazione CAI'
        }),
        interactionDefaultMode: 'PHONE'
      }
    } else if (type.value === 'FINITALIA') {
      return {
        detailsListCmp: <FinitaliaDetailsListCmp {...props} />,
        timelineCmp: <DefaultTimelineCmp {...props} />,
        detailsPage: Object.assign({}, baseDetailsPage, {}),
        interactionDefaultMode: 'TABLET'
      }
    } else if (type.value === 'LINK SMS') {
      return {
        detailsListCmp: <LinkSmsDetailsListCmp {...props} />,
        timelineCmp: <DefaultTimelineCmp {...props} />,
        detailsPage: Object.assign({}, baseDetailsPage, {}),
        interactionDefaultMode: 'TABLET'
      }
    } else if (type.value === 'NOW_AGE') {
      return {
        detailsListCmp: <NowAgeDetailsListCmp {...props} />,
        timelineCmp: <DefaultTimelineCmp {...props} />,
        detailsPage: Object.assign({}, baseDetailsPage, {
          tabListDataTitle: 'Documenti'
        }),
        interactionDefaultMode: 'TABLET'
      }
    } else if (type.value === 'LINEAR') {
      return {
        detailsListCmp: <LinearDetailsListCmp {...props} />,
        timelineCmp: <DefaultTimelineCmp type={type.value} {...props} />,
        detailsPage: Object.assign({}, baseDetailsPage, {
          tabListDataTitle: 'Documenti'
        }),
        interactionDefaultMode: 'TABLET'
      }
    }
  }
}
