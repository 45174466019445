// COMMUNICATIONS INFO

export const loadCommunicationsPaginationsUpdatedAct = (pagesInfo) => ({
  type: 'LOAD_COMMUNICATIONS_PAGINATIONS_UPDATED',
  payload: pagesInfo
})

export const loadCommunicationsSearchStrUpdatedAct = (searchStr) => ({
  type: 'LOAD_COMMUNICATIONS_SEARCH_STRING_UPDATED',
  payload: searchStr
})

export const loadCommunicationsFilterIdAct = (_id) => ({
  type: 'LOAD_COMMUNICATIONS_FILTERID',
  payload: _id
})

export const addCommunicationsFilterItemAct = (filterItem) => ({
  type: 'ADD_COMMUNICATIONS_FILTER_ITEM',
  payload: filterItem
})

export const setCommunicationsFilterItemAct = (filterItems) => ({
  type: 'SET_COMMUNICATIONS_FILTER_ITEM',
  payload: filterItems
})

export const removeCommunicationsFilterItemAct = (fieldName) => ({
  type: 'REMOVE_COMMUNICATIONS_FILTER_ITEM',
  payload: fieldName
})

export const downloadCommunicationsAct = () => ({
  type: 'DOWNLOAD_COMMUNICATIONS'
})

export const downloadCommunicationsSuccessAct = () => ({
  type: 'DOWNLOAD_COMMUNICATIONS_SUCCESS'
})

export const downloadCommunicationsErrorAct = (errorInfo) => ({
  type: 'DOWNLOAD_COMMUNICATIONS_ERROR',
  payload: errorInfo
})

export const downloadAllDocCallAct = (nowId) => ({
  type: 'DOWNLOAD_DOCUMENTS',
  payload: nowId
})

export const downloadAllDocCallSuccessAct = () => ({
  type: 'DOWNLOAD_DOCUMENTS_SUCCESS'
})

export const downloadAllDocCallErrorAct = (errorInfo) => ({
  type: 'DOWNLOAD_DOCUMENTS_ERROR',
  payload: errorInfo
})

export const loadCommunicationsAct = (params) => ({
  type: 'LOAD_COMMUNICATIONS',
  payload: params
})

export const loadCommunicationsSuccessAct = (communicationsInfo) => ({
  type: 'LOAD_COMMUNICATIONS_SUCCESS',
  payload: communicationsInfo
})

export const loadCommunicationsErrorAct = (errorInfo) => ({
  type: 'LOAD_COMMUNICATIONS_ERROR',
  payload: errorInfo
})

export const updateSelectedComponentAct = (componentCode) => ({
  type: 'UPDATE_SELECTED_COMPONENT',
  payload: componentCode
})

export const selectedCommunicationAct = (communication) => ({
  type: 'SELECTED_COMMUNICATION',
  payload: communication
})

export const cleanSelectedCommunicationAct = (communication) => ({
  type: 'CLEAN_SELECTED_COMMUNICATION',
  payload: communication
})

export const loadCorrelatedCommunicationsAct = (pagesInfo) => ({
  type: 'LOAD_CORRELATED_COMMUNICATIONS',
  payload: pagesInfo
})

export const loadCorrelatedCommunicationsSuccessAct = (communicationsInfo) => ({
  type: 'LOAD_CORRELATED_COMMUNICATIONS_SUCCESS',
  payload: communicationsInfo
})

export const loadCorrelatedCommunicationsErrorAct = (errorInfo) => ({
  type: 'LOAD_CORRELATED_COMMUNICATIONS_ERROR',
  payload: errorInfo
})

export const updateMapViewportAct = (viewport) => ({
  type: 'UPDATE_MAP_VIEWPORT',
  payload: viewport
})

export const mapZoomInAct = () => ({
  type: 'MAP_ZOOM_IN'
})

export const mapZoomOutAct = () => ({
  type: 'MAP_ZOOM_OUT'
})

export const setTabViewModeAct = (viewMode) => ({
  type: 'SET_TAB_VIEW_MODE',
  payload: viewMode
})

export const hoveredCommInTableAct = (commId) => ({
  type: 'HOVERED_COMM_IN_TABLE',
  payload: commId
})

export const hoveredCommInMapAct = (commId) => ({
  type: 'HOVERED_COMM_IN_MAP',
  payload: commId
})
