/**
 * Gets the repositories of the Communications from Github
 */
import axios from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  loadPreviewDetailsSuccessAct,
  loadPreviewDetailsErrorAct
} from './_actions'
import convert from './../../utils/convert-partition-name'
export const getStateCommunications = (state) => state.communications
const NETWORK_ERROR = "Errore nell'esecuzione della chiamata"

function getPreviewDataHttp(_requestURL) {
  return axios.get(_requestURL, {
    responseType: 'arraybuffer'
  })
}

export function* loadPreviewDetailsAct(action) {
  const stateCommunications = yield select(getStateCommunications)
  const token =
    stateCommunications.selectedCommunication &&
    stateCommunications.selectedCommunication.token
  const groupName =
    stateCommunications.selectedComponent &&
    stateCommunications.selectedComponent.value
  const partition = convert.convertPartitionName(groupName)
  const ids = action.payload
  const aggregateData = []
  for (let i = 0; i < ids.length; i++) {
    const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/${token}/${ids[i]}` //RICORDAAAAA ?partition=now
    try {
      const response = yield call(getPreviewDataHttp, requestURL)
      if (!response) {
        throw new Error(NETWORK_ERROR)
      }
      if (response.status === 200) {
        aggregateData.push({
          data: response.data,
          id: ids[i],
          contentType: response.headers['content-type']
        })
        if (i === ids.length - 1) {
          yield put(loadPreviewDetailsSuccessAct(aggregateData))
        }
      } else {
        yield put(loadPreviewDetailsErrorAct(response.error))
      }
    } catch (err) {
      yield put(loadPreviewDetailsErrorAct(err))
      return
    }
  }
}

export default function* getFilePreview() {
  yield takeLatest('LOAD_PREVIEW', loadPreviewDetailsAct)
}
