export const loadStatsAct = (params) => ({
  type: 'LOAD_STATS',
  payload: {
    params
  }
})

export const loadStatsSuccessAct = (data) => ({
  type: 'LOAD_STATS_SUCCESS',
  payload: data
})

export const loadStatsErrorAct = (error) => ({
  type: 'LOAD_STATS_ERROR',
  payload: error
})

export const updateSelectedComponentAct = (data) => ({
  type: 'UPDATE_SELECTED_COMPONENT',
  payload: data
})
