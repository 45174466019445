// COMMUNICATION DETAIL INFO

export const loadCommunicationDetailsAct = (isPolling) => ({
  type: 'LOAD_COMMUNICATION_DETAILS',
  payload: isPolling
})

export const loadCommunicationDetailsSuccessAct = (communicationsInfo) => ({
  type: 'LOAD_COMMUNICATION_DETAILS_SUCCESS',
  payload: communicationsInfo
})

export const loadCommunicationDetailsErrorAct = (errorInfo) => ({
  type: 'LOAD_COMMUNICATION_DETAILS_ERROR',
  payload: errorInfo
})

export const loadBaremesInfoAct = (isPolling2) => ({
  type: 'LOAD_BAREMES_INFO',
  payload: isPolling2
})

export const loadBaremesInfoSuccessAct = (baremesInfo) => ({
  type: 'LOAD_BAREMES_INFO_SUCCESS',
  payload: baremesInfo
})

export const loadBaremesInfoErrorAct = (errorInfo) => ({
  type: 'LOAD_BAREMES_INFO_ERROR',
  payload: errorInfo
})

export const showClaimDataAct = () => ({
  type: 'SHOW_CLAIM_DATA'
})

export const takeClaimControlAct = () => ({
  type: 'TAKE_CLAIM_CONTROL'
})

export const cleanSelectedCommunicationDataAct = () => ({
  type: 'CLEAN_SELECTED_COMMUNICATION_DATA'
})

export const openClaimInteractionModalAct = () => ({
  type: 'OPEN_CLAIM_INTERACTION_MODAL'
})

export const closeClaimInteractionModalAct = () => ({
  type: 'CLOSE_CLAIM_INTERACTION_MODAL'
})

export const triggerTooltipAct = () => ({
  type: 'TRIGGER_TOOLTIP'
})

export const clearTooltipAct = () => ({
  type: 'CLEAR_TOOLTIP'
})

export const lockCommunicationAct = () => ({
  type: 'LOCK_CLAIM'
})

export const unlockCommunicationAct = () => ({
  type: 'UNLOCK_CLAIM'
})

export const lockUnlockCommunicationSuccessAct = (communicationsInfo) => ({
  type: 'LOCK_UNLOCK_COMMUNICATION_DETAILS_SUCCESS'
})

export const lockUnlockCommunicationDetailsErrorAct = (errorInfo) => ({
  type: 'LOCK_UNLOCK_COMMUNICATION_DETAILS_ERROR'
})

export const clientContactedAct = () => ({
  type: 'CLIENT_CONTACTED'
})

export const clientNotContactedAct = () => ({
  type: 'CLIENT_NOT_CONTACTED'
})

export const toggleClientContactedErrorAct = (errorInfo) => ({
  type: 'TOGGLE_CLIENT_CONTACTED_ERROR'
})

export const updateNoteValueAct = (newNoteValue) => ({
  type: 'UPDATE_NOTE_VALUE',
  payload: newNoteValue
})

export const submitUpdatedNoteValueAct = () => ({
  type: 'SUBMIT_UPDATED_NOTE_VALUE'
})

export const loadDocumentsListAct = () => ({
  type: 'LOAD_DOCUMENTS_LIST'
})

export const loadDocumentsListSuccessAct = (documents) => ({
  type: 'LOAD_DOCUMENTS_LIST_SUCCESS',
  payload: documents
})

export const loadDocumentsListErrorAct = (errorInfo) => ({
  type: 'LOAD_DOCUMENTS_LIST_ERROR',
  payload: errorInfo
})
