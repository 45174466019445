import {
  sendingStatusesObjects,
  userInteractionsStatusesObjects,
  localGetTimeHourWithUtcOffset,
  getCommunicationStatuses
} from '../../../utils/custom_conversions'

export default [
  {
    Header: 'ID Evento',
    accessor: (d) => d.crash_id
  },
  {
    Header: 'token',
    accessor: (d) => d.token
  },
  {
    Header: 'Data Accadimento',
    id: 'creation_date',
    accessor: (d) => {
      return localGetTimeHourWithUtcOffset(d.creation_date)
    }
  },
  {
    Header: 'Stato Invio',
    accessor: (original) => {
      const statusesOb = getCommunicationStatuses(
        original,
        sendingStatusesObjects
      )
      const labelsList = (
        statusesOb ? statusesOb.map((so) => so.label) : []
      ).join(', ')
      return labelsList
    }
  },
  {
    Header: 'Stato Interazioni',
    id: 'status',
    accessor: (original) => {
      const statusesOb = getCommunicationStatuses(
        original,
        userInteractionsStatusesObjects,
        true
      )
      const listStatuses = statusesOb ? statusesOb.map((so) => so.label) : ''
      return listStatuses
    }
  },
  {
    Header: 'Nome Assicurato',
    id: 'assic_nome',
    accessor: (d) => {
      const _assic_nome = d.assic_nome ? d.assic_nome : ''
      const _assic_cognome = d.assic_cognome ? d.assic_cognome : ''
      if (!_assic_nome && !_assic_cognome) {
        return '--'
      }
      return d.assic_nome + ' ' + d.assic_cognome
    }
  }
]
