export const statsInitialState = {
  list: [],
  selectedComponent: null,
  isLoading: false,
  error: null
}

export const getStatsDataByComponent = (_statsData, _selectedComponent) => {
  // If no selected component is present, then return first element
  if (!_selectedComponent || !_selectedComponent.value) {
    return _statsData[0]
  } else {
    // Select the component
    return _statsData.find((d) => {
      return d.component === _selectedComponent.value
    })
  }
}

export const statsReducer = (state = statsInitialState, action) => {
  let _selectedComponent
  let _avComponents = []
  let _componentEl

  switch (action.type) {
    case 'LOAD_STATS':
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case 'LOAD_STATS_SUCCESS':
      if (action.payload) {
        const _currentSelectedComponent = state.selectedComponent

        _avComponents = action.payload.map((item) => ({
          value: item.component,
          label: item.component
        }))

        _componentEl = getStatsDataByComponent(
          action.payload,
          _currentSelectedComponent
        )

        _selectedComponent = {
          value: _componentEl.component,
          label: _componentEl.component
        }
      }

      return {
        ...state,
        list: _componentEl,
        availableComponents: _avComponents,
        selectedComponent: _selectedComponent,
        isLoading: false,
        error: null
      }

    case 'LOAD_STATS_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case 'UPDATE_SELECTED_COMPONENT':
      _selectedComponent = action.payload
      return {
        ...state,
        selectedComponent: _selectedComponent
      }
    default:
      return state
  }
}
