import React from 'react'
import Select from 'react-select'
import { getGroupedAdvFilters } from './utilFilter'

export const computeItemToSave = (items, selectedOption) => {
  let itemsToSave = items || []

  /*
    If an action has been selected (action='select-option') and the action is exclusive, then remove all existing items of that group (excluded itself)
     */
  if (selectedOption && selectedOption.action === 'select-option') {
    if (selectedOption.option && selectedOption.option.isExclusive) {
      const optionGroupName = selectedOption.option.groupName
      itemsToSave = itemsToSave.filter((item) => {
        return (
          item.groupName !== optionGroupName ||
          (item.groupName === optionGroupName &&
            item.value === selectedOption.option.value)
        )
      })
    }
  }
  return itemsToSave
}

const FilterBar = (props) => {
  const setFilterClick = (items, selectedOption) => {
    const itemsToSave = computeItemToSave(items, selectedOption)
    props.setCommunicationsFilterItem(itemsToSave)
    props.loadCommunications({ ...props })
  }

  const formatGroupAdvFilterLabel = (data) => {
    return (
      <div className='select-group-container'>
        <span>{data.label}</span>
      </div>
    )
  }

  const _advFiltersValue = props.communications.advancedFilters
  const selectedComponent = props.communications.selectedComponent

  return (
    <React.Fragment>
      <Select
        name='advFilters'
        placeholder='Applica filtri...'
        options={getGroupedAdvFilters(
          selectedComponent && selectedComponent.value
        )}
        formatGroupLabel={formatGroupAdvFilterLabel}
        className='basic-multi-select'
        classNamePrefix='select'
        value={_advFiltersValue}
        onChange={setFilterClick}
        hideSelectedOptions={false}
        isMulti
      />

      {/* <div className={"btn-filter " + leavedFilterClass} onClick={() => toggleFilterClick('leaved', false, true)}>
                  <div className="pin"></div>
                  <div>PENDENTI</div>
              </div>
              <div className={"btn-filter " + closedFilterClass} onClick={() => toggleFilterClick('closed', false, true)}>
                  <div className="pin"></div>
                  <div>CONCLUSE</div>
              </div>
              <div className={"btn-filter " + isSupportLockedFilterClass} onClick={() => toggleFilterClick('isSupportLocked', true)}>
                  <div className="pin"></div>
                  <div>LOCKED</div>
              </div> */}
    </React.Fragment>
  )
}

export default FilterBar
