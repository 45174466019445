const linearDocumentTypes = [
  {
    label: 'Modulo Cai',
    type: 'DOC',
    code: 'MODULO_CAI'
  },
  {
    label: "Patente dell'assicurato",
    type: 'DOC',
    code: 'INSURED_LICENSE'
  },
  {
    label: 'Patente del conducente',
    type: 'DOC',
    code: 'DRIVER_LICENSE'
  },
  {
    label: 'Dichiarazioni dei testimoni',
    type: 'DOC',
    code: 'DICHIARAZIONE_TESTIMONI'
  },
  {
    label: "Documento d'identità dei testimoni",
    type: 'DOC',
    code: 'ID_TESTIMONI'
  },
  {
    label: "Documento d'identità dei testimoni",
    type: 'DOC',
    code: 'ID_TESTIMONI'
  },
  {
    label: 'Altri documenti',
    type: 'DOC',
    code: 'OTHER'
  }
]

export default linearDocumentTypes
