/**
 * Gets the repositories of the Communications from Github
 */
import axios from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import convert from './../../utils/convert-partition-name'

import {
  loadCommunicationDetailsSuccessAct,
  loadCommunicationDetailsErrorAct,
  lockUnlockCommunicationDetailsErrorAct,
  lockUnlockCommunicationSuccessAct
} from './_actions'
export const getStateCommunications = (state) => state.communications

function getCommunicationsDataHttp(_requestURL) {
  return axios.get(_requestURL)
}

export function* lockCommunication(action) {
  const stateCommunications = yield select(getStateCommunications)
  const token =
    stateCommunications.selectedCommunication &&
    stateCommunications.selectedCommunication.token
  const lockUnlockKey = action.type === 'LOCK_CLAIM' ? 'lock' : 'unlock'
  const groupName =
    stateCommunications.selectedComponent &&
    stateCommunications.selectedComponent.value
  const partition = convert.convertPartitionName(groupName)
  const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/${lockUnlockKey}/${token}`
  try {
    const response = yield call(getCommunicationsDataHttp, requestURL)
    if (response.data.opResult === true) {
      yield put(loadCommunicationDetailsSuccessAct(response.data.data))
      yield put(lockUnlockCommunicationSuccessAct())
    } else {
      yield put(loadCommunicationDetailsErrorAct(response.data.error))
      yield put(lockUnlockCommunicationDetailsErrorAct())
    }
  } catch (err) {
    yield put(loadCommunicationDetailsErrorAct(err))
    yield put(lockUnlockCommunicationDetailsErrorAct())
    return
  }
}

export default function* lockUnlockCommunication() {
  yield takeLatest('LOCK_CLAIM', lockCommunication)
  yield takeLatest('UNLOCK_CLAIM', lockCommunication)
}
