import React, { PureComponent } from 'react'

const pinStyle = {
  cursor: 'pointer',
  height: 22
}

export default class CommPin extends PureComponent {
  render() {
    const { isHovered, onClick, onMouseEnter, onMouseLeave } = this.props

    const hoveredClass = isHovered ? 'hovered-comm' : ''

    const hoverStyle = {}
    const _w = 35
    const _h = 40
    if (isHovered) {
      hoverStyle.fill = 'yellow'
      hoverStyle.width = _w
      hoverStyle.height = _h
      hoverStyle.backgroundSize = `${_w - 1}px ${_h - 1}px`
      hoverStyle.zIndex = 99
    }
    const currentStyle = Object.assign({}, pinStyle, hoverStyle)

    return (
      <div
        className={'map-pin ' + hoveredClass}
        style={{
          ...currentStyle,
          transform: `translate(${
            -currentStyle.height / 2
          }px,${-currentStyle.height}px)`
        }}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    )
  }
}
