import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import {
  loadCommunicationsAct,
  loadCommunicationsPaginationsUpdatedAct,
  selectedCommunicationAct,
  cleanSelectedCommunicationAct,
  loadCommunicationsSearchStrUpdatedAct,
  loadCommunicationsFilterIdAct,
  addCommunicationsFilterItemAct,
  removeCommunicationsFilterItemAct,
  loadCorrelatedCommunicationsAct,
  updateMapViewportAct,
  mapZoomInAct,
  mapZoomOutAct,
  setTabViewModeAct,
  hoveredCommInTableAct,
  hoveredCommInMapAct,
  setCommunicationsFilterItemAct,
  downloadCommunicationsAct,
  downloadAllDocCallAct,
  updateSelectedComponentAct
} from './_actions'
import { cleanSelectedCommunicationDataAct } from './../communication-details/_actions'
// reactstrap components
import { Card, CardHeader, CardBody, CardTitle, Row, Col } from 'reactstrap'

import SearchBar from '../../components/SearchBar/SearchBar'
import ToggleMap from '../../components/SearchBar/ToggleMap'
import FilterBar from '../../components/FilterBar/FilterBar'
import ContentButtonLoader from '../../components/Loader/ContentButtonLoader'
import CommunicationsMap from '../../components/CommunicationsMap/CommunicationsMap'
import CommunicationsTable from '../../components/CommunicationsTable/CommunicationsTable'
import Legend from '../../components/Legend/Legend'

import { SizeMe } from 'react-sizeme'
import filter from '../../utils/filter-widgets'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCloudDownloadAlt,
  faDownload
} from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import Icon from '../../components/ui/icon/Icon'
import Button from '../../components/ui/button/Button'

const CommunicationsPage = (props) => {
  const [showMapMarkers, setShowMapMarkers] = useState(false)
  const childMapRef = useRef(null)

  useEffect(() => {
    // Clean current selected communication details
    props.cleanSelectedCommunication()
    props.cleanSelectedCommunicationData()

    props.hoveredCommInTable(null)
    props.hoveredCommInMap(null)

    checkNowIdPresence()
  }, [])

  const checkNowIdPresence = () => {
    const nowId = props.nowId
    if (nowId) {
      props.loadCommunicationsFilterId(nowId)
      props.updateSelectedComponent({ value: 'NOW_AGE' })
      props.loadCommunications({ ...props })
    }
  }

  const goToDetails = (_selectedCommunication) => {
    props.selectedCommunication(_selectedCommunication)
    props.communications_goToDetails &&
      props.communications_goToDetails(_selectedCommunication)
  }

  const getValidGeoComms = (_list) => {
    const validGeoComms =
      _list &&
      _list.length &&
      _list.filter((comm) => {
        return (
          comm.inc_latitude &&
          comm.inc_longitude &&
          !isNaN(parseFloat(comm.inc_latitude)) &&
          !isNaN(parseFloat(comm.inc_longitude))
        )
      })
    return validGeoComms
  }

  const executeFitBound = () => {
    setShowMapMarkers(false)
    const communicationStore = props.communications
    const _list = (communicationStore && communicationStore.list) || []
    const map =
      childMapRef &&
      childMapRef.current &&
      childMapRef.current.getMap &&
      childMapRef.current.getMap()

    if (!map || !_list || !_list.length) {
      setShowMapMarkers(true)
      return
    }

    const validGeoComms = getValidGeoComms(_list)

    const allLng = validGeoComms
      .map((vgc) => +vgc.inc_longitude)
      .filter((val) => val >= -90 || val <= 90)
    const allLat = validGeoComms
      .map((vgc) => +vgc.inc_latitude)
      .filter((val) => val >= -90 || val <= 90)

    const minLng = Math.min(...allLng)
    const maxLng = Math.max(...allLng)
    const minLat = Math.min(...allLat)
    const maxLat = Math.max(...allLat)

    /* apply values only if all lat/lon are -90 <= x <= 90 */
    if (
      minLng >= -90 &&
      minLng <= 90 &&
      maxLng >= -90 &&
      maxLng <= 90 &&
      minLat >= -90 &&
      minLat <= 90 &&
      maxLat >= -90 &&
      maxLat <= 90
    ) {
      const coordBounds = [
        [minLng, minLat],
        [maxLng, maxLat]
      ]

      let transitionDuration = 400
      if (map.getZoom() > 7.2) {
        transitionDuration = 800
      }
      if (map.getZoom() > 9.2) {
        transitionDuration = 900
      }
      if (map.getZoom() > 12.2) {
        transitionDuration = 1100
      }

      map &&
        map.fitBounds(coordBounds, {
          padding: 50,
          duration: transitionDuration
        })

      map.once('moveend', (event) => {
        setShowMapMarkers(true)
        props.updateMapViewport({
          viewport: {
            longitude: map.getCenter().lng,
            latitude: map.getCenter().lat,
            zoom: map.getZoom()
          }
        })
      })
    }
  }

  const downloadAllDocsHandler = () => {
    const { nowId, expiredDate, showAlertFunc } = props
    if (nowId) {
      if (expiredDate && expiredDate.isAfter(moment())) {
        showAlertFunc(nowId, expiredDate)
      } else {
        props.downloadAllDocCall(nowId)
      }
    }
  }

  const downloadListHandler = () => {
    props.downloadCommunications()
  }

  const communicationStore = props.communications
  const mapTabViewMode =
    communicationStore && communicationStore.tabViewMode === 'map'
  const isDownloading = communicationStore && communicationStore.isDownloading
  const isDownloadingDocs =
    communicationStore && communicationStore.isDownloadingDocs
  const mapActiveView = mapTabViewMode ? 'map-active' : ''
  const groupName =
    communicationStore.selectedComponent &&
    communicationStore.selectedComponent.value
  const showDownloadExcelWidget = filter.isWidgetInWhiteList(groupName, [
    'NOW_AGE',
    'LINEAR'
  ])
  const showLegend = filter.isWidgetInWhiteList(groupName, ['LINEAR'])
  const downloadExcelLabel = `Esporta lista ${
    props.communications.selectedComponent &&
    props.communications.selectedComponent.value === 'LINEAR'
      ? 'comunicazioni'
      : 'destinatari'
  }`
  const isDocRequestTemplate =
    props.templateType === 'AGE_PARAMETRIC_DOC_UPLOADER'

  const isLinear =
    props.communications.selectedComponent &&
    props.communications.selectedComponent.value === 'LINEAR'

  const handleBackBtn = () => props.goBack()

  return (
    <React.Fragment>
      <div className='content communications-page'>
        <Row>
          <Col md='12'>
            <Card>
              <CardHeader className='header-with-search'>
                <CardTitle tag='h4'>
                  {isLinear && (
                    <Button variant='inline' onClick={handleBackBtn}>
                      <Icon name='back' />
                    </Button>
                  )}
                  Dettaglio Comunicazioni
                </CardTitle>
                <div className='header-items-container-two-buttons'>
                  {isDocRequestTemplate &&
                  !props.hideDownloadAllButton ? (
                    <div
                      className='download-all-list'
                      onClick={downloadAllDocsHandler}
                    >
                      <div className='div-container-icon'>
                        <FontAwesomeIcon icon={faDownload} />
                      </div>
                      <ContentButtonLoader
                        isLoading={isDownloadingDocs}
                        defaultText='Scarica tutti i documenti'
                        loadingText='In corso...'
                      />
                    </div>
                  ) : (
                    <div className='placeholder' />
                  )}

                  {showDownloadExcelWidget && (
                    <div
                      className='download-all-list'
                      onClick={downloadListHandler}
                    >
                      <div className='div-container-icon'>
                        <FontAwesomeIcon icon={faCloudDownloadAlt} />
                      </div>
                      <ContentButtonLoader
                        isLoading={isDownloading}
                        defaultText={downloadExcelLabel}
                        loadingText='In corso...'
                      />
                    </div>
                  )}

                  <FilterBar {...props} />
                  <div className='search-container'>
                    <SearchBar {...props} />
                  </div>
                  <ToggleMap {...props} />
                </div>
              </CardHeader>
              <CardBody>
                <div className={'comm-list-container ' + mapActiveView}>
                  <CommunicationsTable
                    {...props}
                    tableTabViewMode='active'
                    goToDetails={goToDetails}
                    executeFitBound={executeFitBound}
                  />
                  <SizeMe monitorHeight>
                    {({ size }) => {
                      const newSizeInfo = {}
                      if (size && (size.width || size.width === 0)) {
                        newSizeInfo.width = size.width
                      }
                      if (size && (size.height || size.height === 0)) {
                        newSizeInfo.height = size.height
                      }

                      return (
                        <CommunicationsMap
                          {...props}
                          mapTabViewMode='active'
                          goToDetails={goToDetails}
                          getValidGeoComms={getValidGeoComms}
                          showMapMarkers={showMapMarkers}
                          ref={childMapRef}
                          newSizeInfo={newSizeInfo}
                          executeFitBound={executeFitBound}
                        />
                      )
                    }}
                  </SizeMe>
                </div>
              </CardBody>
            </Card>
            {showLegend && <Legend groupName={groupName} />}
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state, ownProps) => ({
  communications: state.communications
})

export const mapDispatchToProps = (dispatch, ownProps) => ({
  loadCommunicationsPaginationsUpdated: (pageInfo) =>
    dispatch(loadCommunicationsPaginationsUpdatedAct(pageInfo)),
  loadCommunications: (paramInfo) => dispatch(loadCommunicationsAct(paramInfo)),
  selectedCommunication: (communication) =>
    dispatch(selectedCommunicationAct(communication)),
  cleanSelectedCommunication: () => dispatch(cleanSelectedCommunicationAct()),
  cleanSelectedCommunicationData: () =>
    dispatch(cleanSelectedCommunicationDataAct()),
  loadCommunicationsSearchStrUpdated: (newSearchStr) =>
    dispatch(loadCommunicationsSearchStrUpdatedAct(newSearchStr)),
  loadCommunicationsFilterId: (_id) =>
    dispatch(loadCommunicationsFilterIdAct(_id)),
  updateSelectedComponent: (componentOption) =>
    dispatch(updateSelectedComponentAct(componentOption)),
  addCommunicationsFilterItem: (filterItem) =>
    dispatch(addCommunicationsFilterItemAct(filterItem)),
  removeCommunicationsFilterItem: (fieldName) =>
    dispatch(removeCommunicationsFilterItemAct(fieldName)),
  setCommunicationsFilterItem: (filterItem) =>
    dispatch(setCommunicationsFilterItemAct(filterItem)),
  loadCorrelatedCommunications: (correlated_crash_id) =>
    dispatch(loadCorrelatedCommunicationsAct(correlated_crash_id)),
  updateMapViewport: (viewport) => dispatch(updateMapViewportAct(viewport)),
  mapZoomIn: (viewport) => dispatch(mapZoomInAct(viewport)),
  mapZoomOut: (viewport) => dispatch(mapZoomOutAct(viewport)),
  setTabViewMode: (tabViewMode) => dispatch(setTabViewModeAct(tabViewMode)),
  hoveredCommInTable: (commId) => dispatch(hoveredCommInTableAct(commId)),
  hoveredCommInMap: (commId) => dispatch(hoveredCommInMapAct(commId)),
  downloadCommunications: () => dispatch(downloadCommunicationsAct()),
  downloadAllDocCall: (nowId) => dispatch(downloadAllDocCallAct(nowId))
})

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationsPage)
