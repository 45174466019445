const contactedKeyValueFilterOptions = [
  {
    key: 'clientContactedByOperator',
    value: 'clientContactedByOperator',
    label: 'CONTATTATO',
    isKeyValueFilter: true,
    fieldCondition: { clientContactedByOperator: true },
    groupName: 'contactedKeyValueFilterOptions',
    isExclusive: true
  },
  {
    key: 'clientNotContactedByOperator',
    value: 'clientNotContactedByOperator',
    label: 'NON CONTATTATO',
    isKeyValueFilter: true,
    fieldCondition: {
      $or: [
        { clientContactedByOperator: false },
        { clientContactedByOperator: { $exists: 0 } }
      ]
    },
    groupName: 'contactedKeyValueFilterOptions',
    isExclusive: true
  }
]

export default {
  contactedKeyValueFilterOptions
}
