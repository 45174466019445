export const initialState = {
  loginInput: null,
  authInfo: null,
  authInfoError: null,
  isLoginLoading: false,
  loginError: false
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SUBMIT_LOGIN':
      return {
        ...state,
        loginInput: action.payload,
        isLoginLoading: true,
        loginError: false
      }
    case 'F5_SUBMIT_LOGIN_SUCCESS':
      return {
        ...state,
        authInfo: { token: action.payload }
      }
    case 'F5_REFRESH_TOKEN_SUCCESS':
      return {
        ...state,
        authInfo: { ...state.authInfo, token: action.payload }
      }
    case 'SUBMIT_LOGIN_SUCCESS':
      return {
        ...state,
        authInfo: action.payload,
        isLoginLoading: false,
        loginError: false
      }
    case 'SUBMIT_LOGIN_ERROR':
      return {
        ...state,
        isLoginLoading: false,
        loginError: true,
        authInfoError: action.payload
      }
    case 'LOGOUT':
      return {
        ...state,
        authInfo: null,
        isLoginLoading: false,
        loginError: false
      }
    default:
      return state
  }
}
