import { localGetTimeHourWithUtcOffset } from '../../../utils/custom_conversions'

export default [
  {
    Header: 'ID Evento',
    accessor: 'claim_number'
  },
  {
    Header: 'Data Accadimento',
    id: 'creation_date',
    accessor: (d) => {
      return localGetTimeHourWithUtcOffset(d.creation_date)
    }
  }
]
