import React, { useState, useEffect } from 'react'

const OperatoreNotes = (props) => {
  const [opNotes, setOpNotes] = useState('')

  useEffect(() => {
    const currentCommunication =
      props.communicationDetails && props.communicationDetails.data
    setOpNotes(currentCommunication && currentCommunication.operatorNotes)
  }, [props.communicationDetails])

  const handleNoteChange = (e) => {
    const noteValue = e.target.value
    setOpNotes(noteValue)
    props.updateNoteValue(noteValue)
  }

  return (
    <textarea
      className='notes-text-area'
      placeholder='Inserisci le note qui'
      value={opNotes}
      onChange={handleNoteChange}
    />
  )
}

export default OperatoreNotes
