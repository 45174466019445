import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table'
import RowLoading from './../RowLoading/RowLoading'
import CorrelatedCommunication from './../CorrelatedCommunication/CorrelatedCommunication'
import { countInteractionsFiles } from './../../utils/custom_conversions'
import REALTIME_columns from './columns/REALTIME'
import FINITALIA_columns from './columns/FINITALIA'
import DEFAULT_columns from './columns/DEFAULT'
import LINK_SMS_columns from './columns/LINK_SMS'
import NOW_AGE_columns from './columns/NOW_AGE'
import LINEAR_columns from './columns/LINEAR'

const CommunicationsTable = (props) => {
  const [expanded, setExpanded] = useState({})
  const [firstTimeCorrelationLoading, setFirstTimeCorrelationLoading] = useState(true)
  const [minTimeCorrelationLoading, setMinTimeCorrelationLoading] = useState(false)

  useEffect(() => {
    setFirstTimeCorrelationLoading(true)
  }, [])

  const getColumnsHeader = () => {
    switch (props.communications.selectedComponent && props.communications.selectedComponent.value) {
      case 'REALTIME':
      case 'REALTIME_DEMO':
        return REALTIME_columns
      case 'FINITALIA':
        return FINITALIA_columns
      case 'LINK SMS':
        return LINK_SMS_columns
      case 'NOW_AGE':
        if (props.templateType === 'AGE_PARAMETRIC_DOC_UPLOADER') {
          return NOW_AGE_columns.map((h) => {
            const res = h.id === 'document_dl' ? { ...h, show: true } : h
            return res
          })
        }
        return NOW_AGE_columns
      case 'LINEAR':
        return LINEAR_columns
      default:
        return DEFAULT_columns
    }
  }

  const handleRowExpanded = (index) => {
    setExpanded({
      ...expanded,
      [index[0]]: !expanded[index[0]]
    })
  }

  const startMinimumCorrLoading = () => {
    if (minTimeCorrelationLoading) {
      return
    }
    setMinTimeCorrelationLoading(true)
    setTimeout(() => {
      setMinTimeCorrelationLoading(false)
    }, 1000)
  }

  const onMouseEnterEvent = (rowInfo) => {
    const commId = rowInfo && rowInfo.row && rowInfo.row._original && rowInfo.row._original._id
    props.hoveredCommInTable(commId)
  }

  const shouldDisplaySubComponent = () => {
    const { selectedComponent } = props.communications
    return selectedComponent && (selectedComponent.value === 'REALTIME' || selectedComponent.value === 'REALTIME_DEMO')
  }

  const dynamicAttributes = shouldDisplaySubComponent()
    ? {
      SubComponent: (e) => {
        if (props.communications.isCorrelatedLoading || minTimeCorrelationLoading) {
          if (firstTimeCorrelationLoading) {
            startMinimumCorrLoading()
          }
          return <RowLoading />
        } else {
          return (
            <CorrelatedCommunication
              {...props}
              currentCorrelatedComm={props.communications.correlatedCommunication}
            />
          )
        }
      }
    }
    : {}

  return (
    props.tableTabViewMode && (
      <ReactTable
        minRows={0}
        previousText='Precedenti'
        nextText='Successivi'
        pageText='Pagina'
        ofText='di'
        rowsText='elementi'
        onExpandedChange={(newExpanded, index, event) => handleRowExpanded(index, event)}
        columns={getColumnsHeader()}
        manual // Forces table not to paginate or sort automatically, so we can handle it server-side
        data={props.communications.list}
        resolveData={(data) => {
          props.executeFitBound()
          return data
        }}
        pages={props.communications.pages} // Display the total number of pages
        loading={props.communications.isLoading} // Display the loading overlay when we need it
        sortable
        defaultPageSize={props.communications.pageSize || 10}
        {...dynamicAttributes}
        defaultSorted={props.communications.sorted || [{ id: 'creation_date', desc: false }]}
        page={props.communications.page || 0}
        className='-striped -highlight'
        onFetchData={(state, instance) => {
          const _pageInfo = {
            pageSize: state.pageSize,
            page: state.page,
            sorted: state.sorted,
            filtered: state.filtered
          }

          // Update store pagination input data
          props.loadCommunicationsPaginationsUpdated(_pageInfo)
          // Reload communication pagination data from server
          props.loadCommunications({ ...props })
        }}
        getTdProps={(state, rowInfo, columnInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: (e, handleOriginal) => {
                const correlated_crash_id = rowInfo && rowInfo.row && rowInfo.row._original && rowInfo.row._original.correlated_crash_id
                const hasCorrelated = !!correlated_crash_id
                if (hasCorrelated) {
                  // Load correlated data from server
                  props.loadCorrelatedCommunications(correlated_crash_id)
                }

                // Is correlated item expander?
                if (columnInfo && columnInfo.className && columnInfo.className.indexOf('correlated-crash') >= 0) {
                  if (handleOriginal) {
                    // execute expansion
                    return handleOriginal()
                  }
                } else if (
                  columnInfo && columnInfo.className && columnInfo.className.indexOf('document-dl') >= 0 &&
                  rowInfo && rowInfo.original &&
                  countInteractionsFiles(rowInfo.original) > 0 &&
                  rowInfo.original.token
                ) {
                  props.downloadAllSingleCommFunc(rowInfo.original.token)
                } else {
                  const _selectedCommunication = props.communications && props.communications.list[rowInfo.index]
                  props.goToDetails(_selectedCommunication)
                }
              }
            }
          } else {
            return {}
          }
        }}
        getTrProps={(state, rowInfo, column) => {
          const hoveredCommId = props.communications.currentHoveredCommIdInMap
          const lastViewedCommId = props.communications.lastViewedCommunicationId
          const commId = rowInfo && rowInfo.row && rowInfo.row._original && rowInfo.row._original._id
          const isHovered = hoveredCommId === commId
          const isLastSeen = lastViewedCommId === commId

          const retObj = {
            onMouseEnter: () => {
              onMouseEnterEvent(rowInfo)
            },
            onMouseLeave: () => {
              onMouseEnterEvent(null)
            }
          }

          if (isHovered) {
            retObj.style = {
              background: 'rgba(255, 178, 18, 1)'
            }
          }

          if (isLastSeen) {
            retObj.className = 'is-last-seen'
          }

          return retObj
        }}

        onPageSizeChange={() => {
          const scrollContainer = document.querySelector('#main-panel');
          if (scrollContainer) {
            scrollContainer.scrollTop = 0;
          }
        }}
      />
    )
  );
};

export default CommunicationsTable
