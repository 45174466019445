/**
 * Gets the repositories of the Communications from Github
 */
import axios from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toggleClientContactedErrorAct } from './_actions'
import { openDefaultModalAct } from '../Layout/_actions'
import convert from './../../utils/convert-partition-name'

export const getStateCommunications = (state) => state.communications

function getCommunicationsDataHttp(_requestURL) {
  return axios.get(_requestURL)
}

export function* toggleClientContactedCall(action) {
  const stateCommunications = yield select(getStateCommunications)
  const token =
    stateCommunications.selectedCommunication &&
    stateCommunications.selectedCommunication.token
  const clientContactedInfo = action.type === 'CLIENT_CONTACTED'
  const groupName =
    stateCommunications.selectedComponent &&
    stateCommunications.selectedComponent.value
  const partition = convert.convertPartitionName(groupName)
  const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/clientcontacted/${clientContactedInfo}/${token}`
  try {
    const response = yield call(getCommunicationsDataHttp, requestURL)
    if (response.data.opResult !== true) {
      yield put(toggleClientContactedErrorAct())
      yield put(openDefaultModalAct())
    }
  } catch (err) {
    yield put(toggleClientContactedErrorAct())
    yield put(openDefaultModalAct())
    return
  }
}

export default function* toggleClientContacted() {
  yield takeLatest('CLIENT_CONTACTED', toggleClientContactedCall)
  yield takeLatest('CLIENT_NOT_CONTACTED', toggleClientContactedCall)
}
