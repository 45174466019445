import {
  linearInteractionsStatusesObjects,
  localGetTimeHourWithUtcOffset,
  getCommunicationStatuses,
  isUploadDocStarted
} from '../../../utils/custom_conversions'

export default [
  {
    Header: 'ID Sinistro',
    accessor: (d) => d.externalId
  },
  {
    Header: 'Targa',
    accessor: (d) => d.your_plate
  },
  {
    Header: 'Numero Telefono',
    accessor: (d) => d.to
  },
  {
    Header: 'Invio SMS',
    id: 'creation_date',
    accessor: (d) => {
      return localGetTimeHourWithUtcOffset(d.creation_date)
    }
  },
  {
    Header: 'Stato',
    id: 'status',
    accessor: (original) => {
      const statusesOb = getCommunicationStatuses(
        original,
        linearInteractionsStatusesObjects,
        false
      )
      const isUploadDocStartedStatus = isUploadDocStarted(original, 'LINEAR')
      if (isUploadDocStartedStatus) {
        statusesOb.push(isUploadDocStartedStatus)
      }
      const listStatuses = (
        statusesOb ? statusesOb.map((so) => so.label) : ''
      ).join(', ')
      return listStatuses
    }
  }
]
