/**
 * Gets the repositories of the Communications from Github
 */
import axios from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  loadCommunicationDetailsSuccessAct,
  loadCommunicationDetailsErrorAct,
  loadBaremesInfoSuccessAct,
  loadBaremesInfoErrorAct,
  loadDocumentsListSuccessAct,
  loadDocumentsListErrorAct
} from './_actions'
import { openDefaultModalAct } from '../Layout/_actions'
import { loadCorrelatedCommunicationsAct } from './../communications/_actions'

import convert from './../../utils/convert-partition-name'
const NETWORK_ERROR = "Errore nell'esecuzione della chiamata"
export const getStateCommunications = (state) => state.communications
export const getCommunicationDetails = (state) => state.communicationDetails

function getCommunicationsDataHttp(_requestURL) {
  return axios.get(_requestURL)
}

function getDocumentsDataHttp(_requestURL) {
  return axios.get(_requestURL)
}

function patchCommunicationsDataHttp(_requestURL, patchBody) {
  return axios.patch(_requestURL, patchBody)
}

export function* getCommunicationDetailsDataFromServer() {
  // Select paginations info (from store) in body
  const stateCommunications = yield select(getStateCommunications)
  // Select paginations info (from store) in body
  const groupName =
    stateCommunications.selectedComponent &&
    stateCommunications.selectedComponent.value
  const partition = convert.convertPartitionName(groupName)
  const token =
    stateCommunications.selectedCommunication &&
    stateCommunications.selectedCommunication.token
  // let requestURL_old = `http://localhost:3004/communication/token-here`;

  const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/${token}` // RICORDAAAA ?partition=now;
  try {
    const response = yield call(getCommunicationsDataHttp, requestURL)
    if (!response) {
      throw new Error(NETWORK_ERROR)
    }
    if (response.data.opResult === true) {
      const dataResp = response.data.data
      yield put(loadCommunicationDetailsSuccessAct(dataResp))

      /* If a correlated is present, then load it also */
      const correlated_crash_id =
        dataResp && dataResp.data && dataResp.data.correlated_crash_id
      const hasCorrelated = !!correlated_crash_id
      if (hasCorrelated) {
        // Load correlated data from server
        yield put(loadCorrelatedCommunicationsAct(correlated_crash_id))
      }
    } else {
      yield put(loadCommunicationDetailsErrorAct(response.data.error))
    }
  } catch (err) {
    yield put(loadCommunicationDetailsErrorAct(err))
    return
  }
}

export function* getBaremesInfoFromServer() {
  const stateCommunications = yield select(getStateCommunications)
  const token =
    stateCommunications.selectedCommunication &&
    stateCommunications.selectedCommunication.token
  const groupName =
    stateCommunications.selectedComponent &&
    stateCommunications.selectedComponent.value
  const partition = convert.convertPartitionName(groupName)
  let requestURL = null
  if (groupName === 'REALTIME') {
    requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}cai/getBaremeInfo/${token}`
    try {
      const response = yield call(getCommunicationsDataHttp, requestURL)
      if (!response) {
        throw new Error(NETWORK_ERROR)
      }
      if (response.data.opResult === true) {
        yield put(loadBaremesInfoSuccessAct(response.data.data))
      } else {
        yield put(loadBaremesInfoErrorAct(response.data.error))
      }
    } catch (err) {
      yield put(loadBaremesInfoErrorAct(err))
      return
    }
  }
  return
}

export function* updateCommunicationNote(action) {
  const stateCommunications = yield select(getStateCommunications)
  const communicationDetails = yield select(getCommunicationDetails)
  const token =
    stateCommunications.selectedCommunication &&
    stateCommunications.selectedCommunication.token
  const updatedNote = communicationDetails.notesUnderUpdate
  const groupName =
    stateCommunications.selectedComponent &&
    stateCommunications.selectedComponent.value
  const partition = convert.convertPartitionName(groupName)
  let requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/${token}`

  try {
    const response = yield call(patchCommunicationsDataHttp, requestURL, {
      operatorNotes: updatedNote
    })
    if (!response) {
      throw new Error(NETWORK_ERROR)
    }
    if (response.data.opResult !== true) {
      yield put(openDefaultModalAct())
    }
  } catch (err) {
    yield put(openDefaultModalAct())
    return
  }
}

export function* loadDocumentsList() {
  const stateCommunications = yield select(getStateCommunications)
  let requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/documents`
  try {
    const response = yield call(getDocumentsDataHttp, requestURL)
    if (!response) {
      throw new Error(NETWORK_ERROR)
    }
    if (response.data.opResult === true) {
      yield put(loadDocumentsListSuccessAct(response.data.data))
    } else {
      yield put(loadDocumentsListErrorAct(response.data.error))
    }
  } catch (err) {
    yield put(loadDocumentsListErrorAct(err))
    return
  }
}

// saga watcher
export default function* getCommunicationsData() {
  yield takeLatest(
    'LOAD_COMMUNICATION_DETAILS',
    getCommunicationDetailsDataFromServer
  )
  yield takeLatest('LOAD_BAREMES_INFO', getBaremesInfoFromServer)
  yield takeLatest('SUBMIT_UPDATED_NOTE_VALUE', updateCommunicationNote)
  yield takeLatest('LOAD_DOCUMENTS_LIST', loadDocumentsList)
}
