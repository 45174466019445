const shouldRenderThisWidget = (name, blackListNames) =>
  !(name && blackListNames.some((element) => name.includes(element)))

const isWidgetInWhiteList = (name, whiteListNames) =>
  !!(name && whiteListNames.includes(name))

export default {
  shouldRenderThisWidget,
  isWidgetInWhiteList
}
