import React from 'react'

const RowLoading = (props) => (
  <div className='correlated-container is-loading'>
    <div className='spinner'>
      <div className='bounce1' />
      <div className='bounce2' />
      <div className='bounce3' />
    </div>
  </div>
)

export default RowLoading
