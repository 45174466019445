import React from 'react'
import { localGetTimeHourWithUtcOffset } from './../../../utils/custom_conversions'

export default [
  {
    Header: () => <i className='fas fa-link' />,
    width: 55,
    className: 'correlated-crash',
    headerClassName: 'correlated-crash',

    expander: true,
    Expander: ({ isExpanded, ...d }) => {
      const expandedClass = isExpanded ? 'is-expanded' : ''
      const correlated_crash_id =
        d && d.row && d.row._original && d.row._original.correlated_crash_id
      const crash_id_element = correlated_crash_id ? (
        <div>
          <span className={'linked-crash ' + expandedClass}>
            <i className='fas fa-angle-down'></i>
          </span>
        </div>
      ) : null
      return <div className='crash_id_container'> {crash_id_element}</div>
    }
  },

  // {
  //   Header: "Locked",
  //   id: "isSupportLocked",
  //   accessor: d => d.isSupportLocked && 'LOCKED'
  // },
  {
    Header: 'ID Crash',
    id: 'crash_id',
    accessor: 'crash_id',
    className: 'crash-id-col',
    headerClassName: 'crash-id-col'
  },
  {
    Header: 'token',
    accessor: 'token',
    className: 'token-col',
    headerClassName: 'token-col'
  },
  {
    Header: 'Data Accadimento',
    id: 'creation_date',
    accessor: (d) => {
      return localGetTimeHourWithUtcOffset(d.creation_date)
    },
    className: 'creation-date-col',
    headerClassName: 'creation-date-col'
  },
  {
    Header: 'Nome Assicurato',
    id: 'assic_nome',
    accessor: (d) => {
      const _assic_nome = d.assic_nome ? d.assic_nome : ''
      const _assic_cognome = d.assic_cognome ? d.assic_cognome : ''
      if (!_assic_nome && !_assic_cognome) {
        return '--'
      }
      return d.assic_nome + ' ' + d.assic_cognome
    },
    className: 'assic-nome-col',
    headerClassName: 'assic-nome-col'
  },
  {
    Header: 'Targa',
    accessor: 'veic_targa',
    className: 'veic-targa-col',
    headerClassName: 'veic-targa-col'
  },
  {
    Header: 'Contattato',
    id: 'clientContactedByOperator',
    Cell: (d) => {
      const operatorContacted = !!(
        d &&
        d.row &&
        d.row._original &&
        d.row._original.clientContactedByOperator
      )
      return operatorContacted ? (
        <div className='client-contacted-i-container'>
          <i className='far fa-check-circle' />
        </div>
      ) : null
    },
    className: 'client-contacted-col',
    headerClassName: 'client-contacted-col'
  }
]
