import React, { useEffect } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const DefaultModal = (props) => {
  useEffect(() => {
    const customBodyPresent = !!(
      props.modalData && props.modalData.customBodyPresent
    )
    if (
      customBodyPresent &&
      !React.isValidElement(props.modalData.customBodyCmp)
    ) {
      handleCloseModal()
    }
  }, [])

  const handleCloseModal = () => {
    props.closeDefaultModal()
  }

  const modalClassName =
    props.modalData && props.modalData.modalClassName
      ? props.modalData.modalClassName
      : ''
  const title =
    props.modalData && props.modalData.title ? props.modalData.title : 'Errore applicativo'
  const mainText =
    props.modalData && props.modalData.mainText
      ? props.modalData.mainText
      : "Si è verificato un problema durante l'esecuzione dell'operazione corrente. Prova a riprendere l'esecuzione dell'applicativo. Se il problema persiste, contatta l'assistenza."
  const customBodyPresent = !!(
    props.modalData && props.modalData.customBodyPresent
  )
  const customBodyCmp =
    (props.modalData &&
      React.isValidElement(props.modalData.customBodyCmp) &&
      props.modalData.customBodyCmp) ||
    mainText
  const cancelBtnPresent = !!(
    props.modalData && props.modalData.cancelBtnPresent
  )
  const cancelBtnLabel =
    props.modalData && props.modalData.cancelBtnLabel
      ? props.modalData.cancelBtnLabel
      : "Riaccedi all'applicativo"
  let closeAndCancelHandlerFunctionName =
    props.modalData &&
    props.modalData.closeAndCancelHandlerFunctionName &&
    props[props.modalData.closeAndCancelHandlerFunctionName]
  closeAndCancelHandlerFunctionName =
    closeAndCancelHandlerFunctionName || handleCloseModal
  const okBtnLabel =
    props.modalData && props.modalData.okBtnLabel
      ? props.modalData.okBtnLabel
      : "Riaccedi all'applicativo"
  const closeAndSaveHandlerFunctionName =
    (props.modalData &&
      props.modalData.closeAndSaveHandlerFunctionName) ||
    handleCloseModal

  return (
    <div>
      <Modal
        isOpen={props.showDefaultModal}
        className={props.className + ' ' + modalClassName}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{customBodyPresent ? customBodyCmp : mainText}</ModalBody>
        <ModalFooter>
          {cancelBtnPresent && (
            <Button
              color='default'
              className='lp-07'
              onClick={closeAndCancelHandlerFunctionName}
            >
              {cancelBtnLabel}
            </Button>
          )}
          <Button
            color='info'
            className='lp-07'
            onClick={closeAndSaveHandlerFunctionName}
          >
            {okBtnLabel}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default DefaultModal
