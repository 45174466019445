import React, { useEffect, useRef } from 'react'
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar'

import Header from './../../components/Navbars/Header.jsx'
// redux routing saga
import { connect } from 'react-redux'
import {
  loadUserInfoSuccessAct,
  loadUserInfoAct,
  loadAppConfigAct,
  openDefaultModalAct,
  closeDefaultModalAct
} from './_actions'
import { updateSelectedComponentAct } from '../communications/_actions'

import DefaultModalContainer from './DefaultModalContainer'
import CommunicationsPage from './../communications/CommunicationsPage'
import CommunicationDetailsPage from './../communication-details/CommunicationDetailsPage'
import CommunicationsDashboardPage from '../communications-dashboard/CommunicationsDashboardPage.jsx'

var ps

const Dashboard = (props) => {
  const mainPanel = useRef(null)

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current)
      document.body.classList.toggle('perfect-scrollbar-on')
    }
    // Load app config data
    props.loadAppConfig()
    // Load user info data
    props.loadUserInfo()

    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy()
        document.body.classList.toggle('perfect-scrollbar-on')
      }
    }
  }, [])

  return (
    <React.Fragment>
      <DefaultModalContainer />
      <div className='wrapper'>
        <div className='main-panel' id='main-panel' ref={mainPanel}>
          {props.header && props.header.show && <Header {...props} />}

          {props.showCommunications && <CommunicationsPage {...props} />}
          {props.showDetails && <CommunicationDetailsPage {...props} />}
          {props.showDashboard && <CommunicationsDashboardPage {...props} />}
        </div>
      </div>
    </React.Fragment>
  )
}

export const mapStateToProps = (state, ownProps) => ({
  ...state.main,
  communications: state.communications,
  stats: state.stats,
  auth: state.auth
})

export const mapDispatchToProps = (dispatch, ownProps) => ({
  loadAppConfig: () => dispatch(loadAppConfigAct()),
  loadUserInfo: () => dispatch(loadUserInfoAct()),
  getUserInfo: (userInfo) => dispatch(loadUserInfoSuccessAct(userInfo)),
  openDefaultModal: (modalData) => dispatch(openDefaultModalAct(modalData)),
  closeDefaultModal: (modalData) => dispatch(closeDefaultModalAct(modalData)),
  updateSelectedComponent: (componentCode) =>
    dispatch(updateSelectedComponentAct(componentCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
