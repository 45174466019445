import React from 'react'
import RealtimeClaimInteractionModalCmp from './ClaimInteraction/REALTIME/RealtimeClaimInteractionModalCmp'

export default function FactoryClaimInteractionModalCmp() {
  this.createModalInstance = function (type, props) {
    if (
      [
        'REALTIME',
        'REALTIME_DEMO',
        'FINITALIA',
        'LINK SMS',
        'NOW_AGE',
        'LINEAR'
      ].includes(type.value)
    ) {
      return {
        modalCmp: <RealtimeClaimInteractionModalCmp {...props} />
      }
    }
  }
}
