import { connect } from 'react-redux'
import ClaimInteractionModal from '../../components/Modals/ClaimInteractionModalCmp'
import {
  closeClaimInteractionModalAct,
  lockCommunicationAct,
  unlockCommunicationAct
} from './_actions'

const mapStateToProps = (state, ownProps) => ({
  ...state.communicationDetails,
  communications: state.communications,
  main: state.main
})

export const mapDispatchToProps = (dispatch, ownProps) => ({
  closeClaimInteractionModal: () => {
    dispatch(closeClaimInteractionModalAct())
    dispatch(unlockCommunicationAct())
  },
  lockCommunication: () => dispatch(lockCommunicationAct()),
  unlockCommunication: () => dispatch(unlockCommunicationAct())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimInteractionModal)
