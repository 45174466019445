import { connect } from 'react-redux'
import DefaultModal from '../../components/Modals/DefaultModal'
import { openDefaultModalAct, closeDefaultModalAct } from './_actions'

export const mapStateToProps = (state, ownProps) => ({
  ...state.main
})

export const mapDispatchToProps = (dispatch, ownProps) => ({
  openDefaultModal: () => dispatch(openDefaultModalAct()),
  closeDefaultModal: () => dispatch(closeDefaultModalAct())
})

export default connect(mapStateToProps, mapDispatchToProps)(DefaultModal)
