import {
  faThumbsUp,
  faFileUpload,
  faCheck
} from '@fortawesome/free-solid-svg-icons'

export default [
  {
    name: 'sent',
    label: 'Comunicazione Ricevuta',
    iconComponent: faCheck
  },
  {
    name: 'clicato',
    label: 'Link Cliccato',
    iconComponent: faThumbsUp
  },
  {
    name: 'document_downloaded',
    label: 'Almeno un file caricato',
    iconComponent: faFileUpload
  }
]
