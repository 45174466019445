import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import PreviewDocumentDetails from '../../containers/preview-details/PreviewDocumentDetails'
import ZipDownload from './../../components/zipDownload/zipDownload'
import './dropdown-documents.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faAddressCard,
  faFolderOpen,
  faCar,
  faCarCrash,
  faFileAlt
} from '@fortawesome/free-solid-svg-icons'

import _ from 'lodash'
const groupByArray = (xs, key) => {
  return xs.reduce(function (rv, x) {
    const v = key instanceof Function ? key(x) : x[key]
    const el = rv.find((r) => r && r.key === v)
    if (el) {
      el.values.push(x)
    } else {
      rv.push({ key: v, values: [x] })
    }
    return rv
  }, [])
}

const getFileCategory = (file_type, documents) => {
  if (!documents) {
    return
  }
  const foundEl = documents.find((doc) => doc.code === file_type)
  if (foundEl) {
    return { category: foundEl.category, label: foundEl.label }
  }
}

const getIconPerCategory = (categoryCode) => {
  switch (categoryCode) {
    case 'CAT_PEOPLE':
      return faAddressCard
    case 'CAT_OTHER':
      return faFolderOpen
    case 'CAT_CRASH':
      return faCarCrash
    case 'CAT_CAR':
      return faCar
    case 'CAT_CUSTOM':
      return faFileAlt
    default:
      return faAddressCard
  }
}

const getLabelPerCategory = (categoryCode) => {
  switch (categoryCode) {
    case 'CAT_PEOPLE':
      return 'Persona'
    case 'CAT_OTHER':
      return 'Varie'
    case 'CAT_CRASH':
      return 'Sinistro'
    case 'CAT_CAR':
      return 'Auto'
    case 'CAT_CUSTOM':
      return 'La tua lista'
    default:
      return 'Varie'
  }
}

const GridDocuments = (props) => {
  const {
    interactions,
    communicationDetails
  } = props

  const renderIban = () => {
    let iban = null
    if (interactions && interactions.length) {
      iban = interactions.find((int) => int.name === 'IBAN')
      if (iban) {
        return (
          <div className='text-iban'>
            <span>IBAN: {iban.value[0]}</span>
          </div>
        )
      } else {
        return null
      }
    }
  }

  const getOptions = () => {
    if (communicationDetails) {
      let interactionsMapped = interactions
        .map((el, index) => {
          const singleLabel =
            communicationDetails.documents &&
            communicationDetails.documents.find((val) => el.file_type === val.code)
          const lab = singleLabel ? singleLabel.label : ''
          const categoryObg =
            getFileCategory(el.file_type, communicationDetails.documents) || {}
          return {
            ...categoryObg,
            value: el.file_type,
            label: el.file_type + ' - ' + lab,
            ids: el.value
          }
        })
        .filter((int) => int.value !== 'IBAN' && typeof int.value !== 'undefined')

      interactionsMapped = groupByArray(interactionsMapped, 'category')

      return interactionsMapped || []
    }
  }

  const options = useMemo(() => {
    return getOptions()

  }, [communicationDetails])

  const iban = renderIban()

  return (
    <React.Fragment>
      <div className='grid-documents'>
        {iban}
        {options &&
          options.length > 0 &&
          options.map((soGrouped, index) => {
            return (
              <React.Fragment key={soGrouped.key}>
                <div className='category-docs-container'>
                  <p className='category-label'>
                    <FontAwesomeIcon
                      icon={getIconPerCategory(soGrouped.key)}
                      className='category-icon'
                    />
                    {getLabelPerCategory(soGrouped.key)}
                  </p>
                  <div className='category-docs-content'>
                    {soGrouped && soGrouped.values && soGrouped.values.map((so, index) => {
                      return (
                        <React.Fragment key={index}>
                          <div className='same-doc-content'>
                            {so.ids &&
                              so.ids.map((id) => (
                                <div className='single-doc-content' key={id}>
                                  <PreviewDocumentDetails
                                    key={id}
                                    interactions={interactions}
                                    id={id}
                                  />
                                </div>
                              ))}
                          </div>
                          <p className='doc-type-name'>{so.label}</p>
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
              </React.Fragment>
            )
          })}
      </div>
      <ZipDownload {...props} />
    </React.Fragment>
  )
}

const mapStateToProps = (state, ownProps) => ({
  previewDetails: state.previewDetails,
  showSpinner: state.showSpinner,
  communications: state.communications,
  communicationDetails: state.communicationDetails
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(GridDocuments)
