import React from 'react'
import { localGetTimeHourWithUtcOffset } from './../../utils/custom_conversions'

const CorrelatedCommunication = (props) => {
  const getAssicNome = (d) => {
    const _assic_nome = d.assic_nome ? d.assic_nome : ''
    const _assic_cognome = d.assic_cognome ? d.assic_cognome : ''
    if (!_assic_nome && !_assic_cognome) {
      return '--'
    }
    return d.assic_nome + ' ' + d.assic_cognome
  }

  const goToDetails = (_selectedCommunication) => {
    props.selectedCommunication(_selectedCommunication)
    props.communications_goToDetails &&
      props.communications_goToDetails(_selectedCommunication)
  }

  const { currentCorrelatedComm } = props
  return currentCorrelatedComm ? (
    <div className='correlated-container'>
      <div className='grid-title'>Crash collegato </div>
      <div className='correlated-body'>
        <div className='grid-container'>
          <div>
            <dt>Crash ID: </dt>
            <dd>{currentCorrelatedComm.crash_id}</dd>
          </div>
          <div>
            <dt>Token: </dt>
            <dd>{currentCorrelatedComm.token}</dd>
          </div>
          <div>
            <dt>Data accadimento: </dt>
            <dd>
              {localGetTimeHourWithUtcOffset(
                currentCorrelatedComm.creation_date
              )}
            </dd>
          </div>
          <div>
            <dt>Nome assicurato: </dt>
            <dd>{getAssicNome(currentCorrelatedComm)}</dd>
          </div>
          <div>
            <dt>Targa: </dt>
            <dd>{currentCorrelatedComm.veic_targa}</dd>
          </div>
          <div>
            <dt>Contattato: </dt>
            <dd>
              {currentCorrelatedComm.clientContactedByOperator ? 'sì' : 'no'}
            </dd>
          </div>
        </div>
        <div className='go-to-details'>
          <button
            className='btn btn-outline-primary btn-round correlated-details btn-sm'
            onClick={() => goToDetails(currentCorrelatedComm)}
          >
            Dettaglio
            <span className='btn-label btn-label-right'>
              <i className='nc-icon nc-minimal-right' />
            </span>
          </button>
        </div>
      </div>
    </div>
  ) : null
}

export default CorrelatedCommunication
