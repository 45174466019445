export const loadUserInfoAct = () => ({
  type: 'LOAD_USER_INFO'
})

export const loadUserInfoSuccessAct = (userInfo) => ({
  type: 'LOAD_USER_INFO_SUCCESS',
  payload: userInfo
})

export const loadUserInfoErrorAct = (errorInfo) => ({
  type: 'LOAD_USER_INFO_ERROR',
  payload: errorInfo
})

export const toggleUserInfoPopupAct = () => ({
  type: 'TOGGLE_USER_INFO_POPUP'
})

export const loadAppConfigAct = () => ({
  type: 'LOAD_APP_CONFIG'
})

export const loadAppConfigSuccessAct = (appConfig) => ({
  type: 'LOAD_APP_CONFIG_SUCCESS',
  payload: appConfig
})

export const loadAppConfigErrorAct = (errorInfo) => ({
  type: 'LOAD_APP_CONFIG_ERROR',
  payload: errorInfo
})

export const openDefaultModalAct = (modalData) => ({
  type: 'OPEN_DEFAULT_MODAL',
  payload: modalData
})

export const closeDefaultModalAct = () => ({
  type: 'CLOSE_DEFAULT_MODAL'
})
