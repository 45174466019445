import { getCommunicationsDataByComponent } from './utils-communications'
import { removeFailedDuplicatesData } from './../../utils/custom_conversions'

export const initialState = {
  list: [],
  selectedComponent: null,
  fullBackendData: [],
  availableCommunications: [],
  searchStr: '',
  filterId: '',
  advFilterUserModified: false,
  advancedFilters: [],
  pages: null,
  isLoading: false,
  loadingError: false,
  selectedCommunication: null,
  lastViewedCommunicationId: '',
  correlatedCommunication: null,
  isCorrelatedLoading: false,
  loadingCorrelatedError: false,
  tabViewMode: 'table',
  mapData: {
    viewport: {
      width: '100%',
      height: 513,
      zoom: 6,
      latitude: 44.5,
      longitude: 11.3
    }
  },
  currentHoveredCommIdInTable: '',
  currentHoveredCommIdInMap: '',
  isDownloading: false,
  downloadingError: false,
  isDownloadingDocs: false,
  downloadingDocsError: false
}

export const communicationsReducer = (state = initialState, action) => {
  let _componentEl, _numberOfPages, _selectedComponent
  let _avComponents = []
  let currentZoom = state.mapData.viewport.zoom
  // let _fullBackendData;
  switch (action.type) {
    case 'LOAD_COMMUNICATIONS_PAGINATIONS_UPDATED':
      return {
        ...state,
        pageSize: action.payload.pageSize,
        page: action.payload.page,
        sorted: action.payload.sorted,
        filtered: action.payload.filtered
      }
    case 'LOAD_COMMUNICATIONS_SEARCH_STRING_UPDATED':
      return {
        ...state,
        searchStr: action.payload,
        page: 0
      }
    case 'LOAD_COMMUNICATIONS_FILTERID':
      return {
        ...state,
        filterId: action.payload,
        page: 0
      }
    case 'ADD_COMMUNICATIONS_FILTER_ITEM':
      return {
        ...state,
        advancedFilters: state.advancedFilters
          ? [...state.advancedFilters, action.payload]
          : [action.payload],
        advFilterUserModified: true
      }
    case 'SET_COMMUNICATIONS_FILTER_ITEM':
      return {
        ...state,
        advancedFilters: [...action.payload],
        advFilterUserModified: true,
        page: 0
      }
    case 'REMOVE_COMMUNICATIONS_FILTER_ITEM':
      return {
        ...state,
        advancedFilters: state.advancedFilters.filter(
          (kpf) => kpf.field !== action.payload
        ),
        advFilterUserModified: true
      }
    case 'LOAD_COMMUNICATIONS':
      return {
        ...state,
        isLoading: true,
        loadingError: false
      }
    case 'LOAD_COMMUNICATIONS_SUCCESS':
      if (
        action.payload &&
        action.payload.serverData.data &&
        action.payload.serverData.data.length > 0
      ) {
        // select component with respect to selectedComponent
        let _currentSelectedComponent = state.selectedComponent
        // if (action.payload.forceSelectedComponent) {
        //     _currentSelectedComponent = { value: action.payload.forceSelectedComponent };
        // }

        _componentEl = getCommunicationsDataByComponent(
          action.payload.serverData.data,
          _currentSelectedComponent
        )

        _numberOfPages =
          _componentEl &&
          _componentEl.metadata &&
          _componentEl.metadata.totalPage
        _avComponents = action.payload.serverData.data.map((d) => {
          return {
            value: d.component,
            label: d.component
          }
        })
        _selectedComponent = {
          value: _componentEl.component,
          label: _componentEl.component
        }
      }
      /* Remove failed send trials for which there is a success send */
      let filteredData = (_componentEl && _componentEl.data) || []
      if (action.payload && !action.payload.takeAllSentAndFailed) {
        filteredData = removeFailedDuplicatesData(
          (_componentEl && _componentEl.data) || []
        )
      }

      return {
        ...state,
        fullBackendData: action.payload.data,
        list: filteredData,
        selectedComponent: _selectedComponent,
        availableComponents: _avComponents,
        isLoading: false,
        loadingError: false,
        pages: _numberOfPages
      }
    case 'LOAD_COMMUNICATIONS_ERROR':
      return {
        ...state,
        isLoading: false,
        loadingError: true,
        userInfoError: action.payload
      }
    case 'DOWNLOAD_COMMUNICATIONS':
      return {
        ...state,
        isDownloading: true,
        downloadingError: false
      }
    case 'DOWNLOAD_COMMUNICATIONS_SUCCESS':
      return {
        ...state,
        isDownloading: false,
        downloadingError: false
      }
    case 'DOWNLOAD_COMMUNICATIONS_ERROR':
      return {
        ...state,
        isDownloadingDocs: false,
        downloadingDocsError: true
      }
    case 'DOWNLOAD_DOCUMENTS':
      return {
        ...state,
        isDownloadingDocs: true,
        downloadingDocsError: false
      }
    case 'DOWNLOAD_DOCUMENTS_SUCCESS':
      return {
        ...state,
        isDownloadingDocs: false,
        downloadingDocsError: false
      }
    case 'DOWNLOAD_DOCUMENTS_ERROR':
      return {
        ...state,
        isDownloading: false,
        downloadingError: true
      }
    case 'UPDATE_SELECTED_COMPONENT':
      _selectedComponent = action.payload
      // _fullBackendData = state.fullBackendData;
      // if (_fullBackendData && _fullBackendData && _fullBackendData.length > 0) {
      //     // select component with rrespect to selectedComponent
      //     _componentEl = getCommunicationsDataByComponent(_fullBackendData, _selectedComponent);
      //     _numberOfPages = _componentEl && _componentEl.metadata && _componentEl.metadata.totalPage;
      // }
      return {
        ...state,
        // list: _componentEl && _componentEl.data,
        selectedComponent: _selectedComponent
        // pages: _numberOfPages
      }
    case 'SELECTED_COMMUNICATION':
      return {
        ...state,
        selectedCommunication: action.payload,
        lastViewedCommunicationId: action.payload._id
      }
    case 'CLEAN_SELECTED_COMMUNICATION':
      return {
        ...state,
        selectedCommunication: null
      }
    case 'LOAD_CORRELATED_COMMUNICATIONS':
      return {
        ...state,
        isCorrelatedLoading: true,
        loadingCorrelatedError: false
      }
    case 'LOAD_CORRELATED_COMMUNICATIONS_SUCCESS':
      if (
        action.payload &&
        action.payload.data &&
        action.payload.data.length > 0
      ) {
        // select component with rrespect to selectedComponent
        _componentEl = getCommunicationsDataByComponent(
          action.payload.data,
          state.selectedComponent
        )
      }
      return {
        ...state,
        correlatedCommunication:
          _componentEl &&
          _componentEl.data &&
          _componentEl.data.length &&
          _componentEl.data[0],
        isCorrelatedLoading: false,
        loadingCorrelatedError: false
      }
    case 'LOAD_CORRELATED_COMMUNICATIONS_ERROR':
      return {
        ...state,
        isCorrelatedLoading: false,
        loadingCorrelatedError: true,
        correlatedLoadingError: action.payload
      }
    case 'UPDATE_MAP_VIEWPORT':
      let newState = {
        ...state,
        mapData: {
          ...state.mapData,
          ...action.payload
        }
      }
      if (
        newState.mapData &&
        newState.mapData.viewport &&
        newState.mapData.viewport.zoom > 17
      ) {
        newState.mapData.viewport.zoom = 17
      }
      return newState
    case 'MAP_ZOOM_IN':
      currentZoom = state.mapData.viewport.zoom
      return {
        ...state,
        mapData: {
          ...state.mapData,
          viewport: {
            ...state.mapData.viewport,
            zoom: currentZoom >= 16 ? currentZoom : currentZoom + 1
          }
        }
      }
    case 'MAP_ZOOM_OUT':
      currentZoom = state.mapData.viewport.zoom
      return {
        ...state,
        mapData: {
          ...state.mapData,
          viewport: {
            ...state.mapData.viewport,
            zoom: currentZoom - 1
          }
        }
      }
    case 'SET_TAB_VIEW_MODE':
      return {
        ...state,
        tabViewMode: action.payload
      }
    case 'HOVERED_COMM_IN_TABLE':
      return {
        ...state,
        currentHoveredCommIdInTable: action.payload
      }
    case 'HOVERED_COMM_IN_MAP':
      return {
        ...state,
        currentHoveredCommIdInMap: action.payload
      }
    default:
      return state
  }
}
