import React from 'react'
import ClipLoader from 'react-spinners/ClipLoader'

const ContentButtonLoader = (props) => {
  const isLoading = props.isLoading === undefined ? false : props.isLoading
  const loadingText =
    props.loadingText === undefined ? 'In corso...' : props.loadingText
  return (
    <div className='div-content-button-loader'>
      <div className='div-loader-cmp'>
        {isLoading ? loadingText : props.defaultText}
      </div>
      {isLoading && <ClipLoader color='white' size='25px' />}
    </div>
  )
}

export default ContentButtonLoader
