import React, { useEffect } from 'react'

import './zoom-documents-modal.scss'

import { Modal, ModalHeader, ModalBody } from 'reactstrap'

export const prepareCanvas = (page) => {
  let scale = 4
  let viewport = page.getViewport({ scale })
  let modalComponent = document.getElementsByClassName('modal-body')[0]
  while (modalComponent && viewport.width > modalComponent.offsetWidth - 40) {
    scale = scale - 0.01
    viewport = null
    while (!viewport) {
      viewport = page.getViewport({ scale })
    }
  }
  let canvas = document.getElementById('canvasPageForModal')
  if (canvas) {
    canvas.width = viewport.width
    canvas.height = viewport.height
    let context = canvas.getContext('2d')
    let renderContext = {
      canvasContext: context,
      viewport: viewport
    }
    page.render(renderContext)
  }
}

const ZoomDocumentsModal = ({ modal, openModal, canvasForModal }) => {
  useEffect(() => {
    if (canvasForModal && modal) {
      setTimeout(() => {
        prepareCanvas(canvasForModal)
      }, 1000)
    }
  }, [canvasForModal, modal])

  return (
    <Modal
      isOpen={modal}
      toggle={() => openModal(false)}
      className='modal-preview'
    >
      <ModalHeader toggle={() => openModal(false)}>
        <div className='title pdf'>Anteprima del documento</div>
      </ModalHeader>
      <ModalBody>
        <canvas id='canvasPageForModal'></canvas>
      </ModalBody>
    </Modal>
  )
}

export default ZoomDocumentsModal
