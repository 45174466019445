import React, { useState, useEffect } from 'react';

const ErrorBoundary = (props) => {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const originalOnError = window.onerror;
    window.onerror = (errorMsg, url, lineNumber, column, errorObj) => {
      // You can log the error or perform other actions here.
      console.error(errorObj);

      // Set the state to trigger rendering of an error UI
      setHasError(true);

      // Call the original error handler if exists
      if (originalOnError) {
        originalOnError(errorMsg, url, lineNumber, column, errorObj);
      }
    };

    return () => {
      // Restore the original error handler when component unmounts
      window.onerror = originalOnError;
    };
  }, []);

  if (hasError) {
    return <div>Something went wrong!</div>; // You can customize this error UI.
  }

  return <>{props.children}</>;
}

export default ErrorBoundary;
