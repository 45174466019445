import React, { useState } from 'react'
import {
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  Collapse,
  Container
} from 'reactstrap'

import Select from 'react-select'

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [color, setColor] = useState('dark')

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const handleSelectedComponent = (selectedComponentOption) => {
    props.updateSelectedComponent(selectedComponentOption)
    props.header &&
      props.header.changedComponentHandler &&
      props.header.changedComponentHandler()
  }

  const handleLogoClick = () => {
    if (
      props.communications &&
      props.communications.selectedComponent &&
      props.communications.selectedComponent.value === 'LINEAR'
    ) {
      props.goToDashboard()
    } else {
      props.header &&
        props.header.logoClickHandler &&
        props.header.logoClickHandler()
    }
  }

  const userInfoToggle = () => {
    props.header &&
      props.header.toggleUserInfoPopupHandler &&
      props.header.toggleUserInfoPopupHandler()
  }

  return (
    <Navbar
      color='dark'
      expand='lg'
      className={
        'navbar-absolute fixed-top navbar-dark'
      }
    >
      <Container fluid>
        <div className='navbar-wrapper'>
          <div className='logo' onClick={handleLogoClick}>
            <div className='simple-text logo-mini'>
              <div className='logo-img'>
                <img
                  src={props.header && props.header.logo}
                  alt='react-logo'
                />
              </div>
            </div>
          </div>
        </div>
        <NavbarToggler onClick={toggle}>
          <span className='navbar-toggler-bar navbar-kebab' />
          <span className='navbar-toggler-bar navbar-kebab' />
          <span className='navbar-toggler-bar navbar-kebab' />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className='justify-content-end'>
          <Nav navbar>
            <NavItem>
              <Select
                className='react-select-custom'
                value={
                  props.communications.selectedComponent ||
                  props.stats.selectedComponent
                }
                onChange={handleSelectedComponent}
                options={
                  props.communications.availableComponents ||
                  props.stats.availableComponents
                }
              />
            </NavItem>
            <Dropdown nav toggle={userInfoToggle}>
              <DropdownToggle
                caret
                nav
                className={props.showPopupUserInfo ? 'activated' : ''}
              >
                <span className='header-icon-label'>
                  {props.userInfo && props.userInfo.username}
                </span>
                <i className='nc-icon nc-badge' />
              </DropdownToggle>
            </Dropdown>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  )
}

export default Header
