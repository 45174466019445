import { createStore, applyMiddleware, compose } from 'redux'
// import rootReducer from './../reducers.js';
import createRootReducer from './../reducers.js'
import createSagaMiddleware from 'redux-saga'
import layoutSaga from '../containers/Layout/saga'
import communicationsSaga from './../containers/communications/saga'
import communicationDetailsSaga from '../containers/communication-details/saga'
import previewDocumentDetailsSaga from '../containers/preview-details/saga'
import communicationLockSaga from '../containers/communication-details/saga-lock'
import clientContactedSaga from '../containers/communication-details/saga-client-contacted'
import statsSaga from './../containers/communications-dashboard/saga.js'

import { initialState as authInitialState } from './../containers/AuthLayout/_reducers'
import { initialState as communicationsInitialState } from './../containers/communications/_reducers'
import { initialState as communicationDetailsInitialState } from './../containers/communication-details/_reducers'
import { initialState as mainInitialState } from '../containers/Layout/_reducers'
import { initialState as previewInitialState } from '../containers/preview-details/_reducers'
import { statsInitialState } from '../containers/communications-dashboard/_reducers.js'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

const actionsToTriggerSaveSessionStorage = [
  'SELECTED_COMMUNICATION',
  'LOAD_COMMUNICATION_DETAILS',
  'LOAD_COMMUNICATION_DETAILS_SUCCESS',
  'LOAD_USER_INFO_SUCCESS',
  'LOAD_COMMUNICATIONS_SUCCESS',
  'CLEAN_SELECTED_COMMUNICATION_DATA',
  'LOAD_APP_CONFIG_SUCCESS',
  'UPDATE_SELECTED_COMPONENT',
  'CLEAN_SELECTED_COMMUNICATION',
  'LOCK_CLAIM',
  'UNLOCK_CLAIM',
  'LOCK_UNLOCK_COMMUNICATION_DETAILS_SUCCESS',
  'SET_TAB_VIEW_MODE',
  'MAP_ZOOM_IN',
  'MAP_ZOOM_OUT',
  'UPDATE_MAP_VIEWPORT',
  'SUBMIT_LOGIN_SUCCESS',
  'SHOW_LOADING_SPINNER',
  'SHOW_CANVAS',
  'LOAD_STATS_SUCCESS'
]

const actionsToTriggerSaveLocalStorage = [
  'SUBMIT_LOGIN_SUCCESS',
  'F5_SUBMIT_LOGIN_SUCCESS',
  'F5_REFRESH_TOKEN_SUCCESS',
  'LOGOUT'
]

const actionsToTriggerResetStorage = ['LOGOUT']

export const getMapDataToSave = (mapData) => {
  return {
    viewport: mapData.viewport && {
      zoom: mapData.viewport.zoom,
      latitude: mapData.viewport.latitude,
      longitude: mapData.viewport.longitude
    }
  }
}

export const removeStateLoading = (stateToSave) => {
  stateToSave.main.userInfoIsLoading = false
  stateToSave.main.appConfigIsLoading = false
  stateToSave.main.showPopupUserInfo = false
  stateToSave.main.showDefaultModal = false
  stateToSave.main.modalData = {}
  stateToSave.main.appConfigLoadingError = false
  stateToSave.main.userInfoLoadingError = false
  stateToSave.communications.isLoading = false
  stateToSave.communications.isCorrelatedLoading = false
  stateToSave.communicationDetails.isLoading = false
  stateToSave.communicationDetails.isLockUnlockLoading = false
  stateToSave.stats.isLoading = false
}

export const getLoObj_sessionStorage_reset = (_store) => {
  let stateToSave = {}

  stateToSave.main = mainInitialState
  stateToSave.communications = communicationsInitialState
  stateToSave.communicationDetails = communicationDetailsInitialState
  stateToSave.previewDetails = previewInitialState
  stateToSave.stats = statsInitialState

  /* Remove Loading */
  removeStateLoading(stateToSave)

  return stateToSave
}

export const getLoObj_sessionStorage = (_store) => {
  let stateToSave = {
    communications: {
      selectedComponent: _store.communications.selectedComponent,
      selectedCommunication: _store.communications.selectedCommunication,
      advancedFilters: _store.communications.advancedFilters,
      advFilterUserModified: _store.communications.advFilterUserModified,
      mapData: getMapDataToSave(_store.communications.mapData),
      tabViewMode: _store.communications.tabViewMode,
      pageSize: _store.communications.pageSize,
      page: _store.communications.page,
      sorted: _store.communications.sorted,
      filtered: _store.communications.filtered,
      lastViewedCommunicationId: _store.communications.lastViewedCommunicationId
    },
    stats: {
      selectedComponent: _store.stats.selectedComponent
    },
    communicationDetails: _store.communicationDetails,
    main: _store.main
  }

  stateToSave = Object.assign({}, stateToSave, {})
  stateToSave.main = Object.assign({}, stateToSave.main, {})
  stateToSave.communications = Object.assign({}, stateToSave.communications, {})
  stateToSave.previewDetails = Object.assign({}, stateToSave.previewDetails, {})

  /* Remove Loading */
  removeStateLoading(stateToSave)

  return stateToSave
}

export const getLoObj_localStorage = (_store) => {
  let stateToSave = {
    auth: _store.auth
  }
  stateToSave.auth = Object.assign({}, stateToSave.auth, {})
  stateToSave.auth.loginInput = Object.assign({}, {})
  /* Remove Loading */
  stateToSave.auth.isLoginLoading = false

  return stateToSave
}

/**
 * Add all the state in local storage
 * @param getState
 * @returns {function(*): function(*=)}
 */
export const sessionStorageMiddleware = ({ getState }) => {
  return (next) => (action) => {
    const result = next(action)
    // Session storage
    if (actionsToTriggerSaveSessionStorage.indexOf(action.type) >= 0) {
      const sessionStorageObj = getLoObj_sessionStorage(getState())
      // Select properties to store in SessionStorage
      sessionStorage.setItem(
        'applicationState',
        JSON.stringify(sessionStorageObj)
      )
    }
    // Local storage
    if (actionsToTriggerSaveLocalStorage.indexOf(action.type) >= 0) {
      const localStorageObj = getLoObj_localStorage(getState())
      // Select properties to store in localStorage
      localStorage.setItem('applicationState', JSON.stringify(localStorageObj))
    }
    // Reset Session storage and rehydrate
    if (actionsToTriggerResetStorage.indexOf(action.type) >= 0) {
      const resetSessionStorageObj = getLoObj_sessionStorage_reset(getState())
      // Select properties to store in SessionStorage
      sessionStorage.setItem(
        'applicationState',
        JSON.stringify(resetSessionStorageObj)
      )
      //reHydrateStore();
    }
    return result
  }
}

export const reHydrateStore = () => {
  if (sessionStorage.getItem('applicationState') !== null) {
    const appState = JSON.parse(sessionStorage.getItem('applicationState')) // re-hydrate the store
    appState.communications = Object.assign(
      {},
      communicationsInitialState,
      appState.communications
    )
    appState.communications.mapData = Object.assign(
      {},
      communicationsInitialState.mapData,
      appState.communications.mapData
    )
    appState.communications.mapData.viewport = Object.assign(
      {},
      communicationsInitialState.mapData.viewport,
      appState.communications.mapData.viewport
    )
    appState.communicationDetails = Object.assign(
      {},
      communicationDetailsInitialState,
      appState.communicationDetails
    )
    appState.main = Object.assign({}, mainInitialState, appState.main)
    appState.previewDetails = Object.assign(
      {},
      previewInitialState,
      appState.previewDetails
    )
    appState.stats = Object.assign({}, statsInitialState, appState.stats)

    if (localStorage.getItem('applicationState') !== null) {
      const authState = JSON.parse(localStorage.getItem('applicationState')) // re-hydrate the store
      appState.auth = Object.assign({}, authInitialState, authState.auth)
    }

    return appState
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(
  createRootReducer(),
  // rootReducer,
  // reHydrateStore(),
  composeEnhancers(applyMiddleware(sagaMiddleware, sessionStorageMiddleware))
)

// then run the saga
sagaMiddleware.run(layoutSaga)
sagaMiddleware.run(communicationsSaga)
sagaMiddleware.run(communicationDetailsSaga)
sagaMiddleware.run(previewDocumentDetailsSaga)
sagaMiddleware.run(communicationLockSaga)
sagaMiddleware.run(clientContactedSaga)
sagaMiddleware.run(statsSaga)
