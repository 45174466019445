import NOW_AGE_Excel_columns from './../../components/CommunicationsTable/excelColumn/NOW_AGE'
import DEFAULT_Excel_columns from './../../components/CommunicationsTable/excelColumn/DEFAULT'
import LINEAR_Excel_columns from './../../components/CommunicationsTable/excelColumn/LINEAR'

import * as XLSX from 'xlsx'

export const getCommunicationsDataByComponent = (
  _communicationsData,
  _selectedComponent
) => {
  // If no selected component is present, then return first element
  if (!_selectedComponent || !_selectedComponent.value) {
    return _communicationsData[0]
  } else {
    // Select the component
    return _communicationsData.find((d) => {
      return d.component === _selectedComponent.value
    })
  }
}

const getExcelColumnsHeader = (_selectedComponent) => {
  switch (_selectedComponent && _selectedComponent.value) {
    case 'NOW_AGE':
      return NOW_AGE_Excel_columns
    case 'LINEAR':
      return LINEAR_Excel_columns
    default:
      return DEFAULT_Excel_columns
  }
}

const getContactFieldsInLocalStorage = (_selectedComponent) => {
  switch (_selectedComponent && _selectedComponent.value) {
    case 'NOW_AGE':
      const nowInstanceContactFields = []
      const nowInstanceContactFieldsStr = localStorage.getItem(
        'nowInstanceContactFields'
      )
      if (nowInstanceContactFieldsStr) {
        nowInstanceContactFields = JSON.parse(nowInstanceContactFieldsStr)
      }
      return nowInstanceContactFields
    default:
      return []
  }
}

const riskyChars = ['=', '-', '@', '+']

const csvInjectionProtector = (str) => {
  if (!str) return ''

  const firstChar = str.charAt(0)
  const isInjected = riskyChars.includes(firstChar)

  if (!isInjected) return str

  const slicedStr = str.slice(1)

  if (firstChar === '+') {
    if (/^\d+$/.test(slicedStr)) {
      return str
    }
  }

  return csvInjectionProtector(slicedStr)
}

const purifyData = (_data) => {
  try {
    if (Array.isArray(_data)) {
      return _data && _data.map((dataItem) => purifyData(dataItem))
    } else {
      return csvInjectionProtector(_data)
    }
  } catch (err) {
    console.log(err)
  }
}

export const createListFile = (_communicationsData, _selectedComponent) => {
  if (!_communicationsData || !_selectedComponent) {
    return
  }

  let columnsHeader = getExcelColumnsHeader(_selectedComponent)
  if (!columnsHeader || !columnsHeader.length) {
    return
  }

  /* Add contact fields */
  const contactFields = getContactFieldsInLocalStorage(_selectedComponent)
  let contactFieldsColumnsHeader = []
  if (contactFields && contactFields.length) {
    contactFieldsColumnsHeader = contactFields.map((cf) => {
      return {
        Header: cf.label,
        accessor: (d) => d[cf.code]
      }
    })
  }
  columnsHeader = [...contactFieldsColumnsHeader, ...columnsHeader]

  const data = []
  data.push(columnsHeader.map((ch) => ch.Header))
  _communicationsData.forEach((cd) => {
    const currentRow = columnsHeader.map((ch) => ch.accessor && ch.accessor(cd))
    data.push(currentRow)
  })

  const dataPurified = purifyData(data)

  var worksheet = XLSX.utils.aoa_to_sheet(dataPurified)
  var new_workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Lista comunicazioni')

  XLSX.writeFile(new_workbook, 'ListaComunicazioni.xlsx')
}
