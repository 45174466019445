import {
  sendingStatusesObjects,
  userInteractionsStatusesObjects,
  viewedStatusItem,
  fromTemplateToName,
  getCommunicationStatuses,
  localGetTimeHourWithUtcOffset
} from '../../../utils/custom_conversions'
export default [
  {
    Header: 'Template',
    accessor: (d) => {
      return fromTemplateToName(d.type)
    }
  },
  {
    Header: 'Stato Invio',
    accessor: (original) => {
      const statusesOb = getCommunicationStatuses(
        original,
        sendingStatusesObjects
      )
      const labelsList = (
        statusesOb ? statusesOb.map((so) => so.label) : []
      ).join(', ')
      return labelsList
    }
  },
  {
    Header: 'Stato Interazioni',
    id: 'status',
    accessor: (original) => {
      const statusesOb = getCommunicationStatuses(
        original,
        userInteractionsStatusesObjects,
        true
      )
      const listStatuses = statusesOb ? statusesOb.map((so) => so.label) : ''
      return listStatuses
    }
  },
  {
    Header: 'Data Invio',
    accessor: (d) => {
      return localGetTimeHourWithUtcOffset(d.creation_date)
    }
  },
  {
    Header: 'Data Visualizzazione',
    id: 'viewd_date',
    accessor: (d) => {
      const statusesOb = getCommunicationStatuses(d, [viewedStatusItem])
      return statusesOb && statusesOb.length
        ? localGetTimeHourWithUtcOffset(statusesOb[0].lastTimestamp)
        : '--'
    }
  }
]
