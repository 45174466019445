/*eslint-disable*/
import React, { useEffect } from 'react'
// react plugin for creating notifications over the dashboard
import NotificationAlert from 'react-notification-alert'

const Notifications = (props) => {
  useEffect(() => {
    if (props.communicationDetails.showTooltip === true) {
      props.clearTooltip()
      notify('tc')
    }
  }, [props.communicationDetails.showTooltip])

  const onDismiss = () => {}

  const notify = (place) => {
    let options = {}
    options = {
      place: place,
      message: <strong>Aggiornamento</strong>,
      type: 'warning',
      icon: 'fa fa-arrow-up',
      autoDismiss: 3
    }
    if (notificationAlertRef.current) {
      notificationAlertRef.current.notificationAlert(options)
    }
  }

  const notificationAlertRef = React.createRef()

  return (
    <div
      className='notifications-content'
      onClick={() => {
        props.scrollTimeline()
      }}
    >
      <NotificationAlert ref={notificationAlertRef} />
    </div>
  )
}

export default Notifications
