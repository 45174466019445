import { combineReducers } from 'redux'
import { layoutReducer } from './containers/Layout/_reducers'
import { authReducer } from './containers/AuthLayout/_reducers'
import { communicationsReducer } from './containers/communications/_reducers'
import { communicationDetailsReducer } from './containers/communication-details/_reducers'
import { previewComponentDetailsReducer } from './containers/preview-details/_reducers'
import { statsReducer } from './containers/communications-dashboard/_reducers'

const createRootReducer = () => {
  const appReducer = combineReducers({
    communications: communicationsReducer,
    main: layoutReducer,
    auth: authReducer,
    communicationDetails: communicationDetailsReducer,
    previewDetails: previewComponentDetailsReducer,
    stats: statsReducer
  })

  const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
      state = undefined
    }
    return appReducer(state, action)
  }

  return rootReducer
}

export default createRootReducer
