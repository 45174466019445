const sentStatusFilterOptions = [
  {
    key: 'sent',
    value: 'sent',
    label: 'INVIATE',
    type: 'boolean',
    isStatusFilter: true,
    fieldValue: true,
    groupName: 'sentStatusFilterOptions',
    isExclusive: true
  },
  {
    key: 'sent_failed',
    value: 'sent_failed',
    label: 'INVII FALLITI',
    type: 'boolean',
    isStatusFilter: true,
    fieldValue: true,
    groupName: 'sentStatusFilterOptions',
    isExclusive: true
  }
]

const userInteractionStatusFilterOptions = [
  {
    key: 'page_viewed',
    value: 'page_viewed',
    label: 'VISITATE',
    type: 'boolean',
    isStatusFilter: true,
    fieldValue: true,
    groupName: 'userInteractionStatusFilterOptions',
    isExclusive: false
  },
  {
    key: 'closed',
    value: 'closed',
    label: 'CONCLUSE',
    type: 'boolean',
    isStatusFilter: true,
    fieldValue: true,
    groupName: 'userInteractionStatusFilterOptions',
    isExclusive: false
  }
]

const contactedKeyValueFilterOptions = [
  {
    key: 'clientContactedByOperator',
    value: 'clientContactedByOperator',
    label: 'CONTATTATO',
    isKeyValueFilter: true,
    fieldCondition: { clientContactedByOperator: true },
    groupName: 'contactedKeyValueFilterOptions',
    isExclusive: true
  },
  {
    key: 'clientNotContactedByOperator',
    value: 'clientNotContactedByOperator',
    label: 'NON CONTATTATO',
    isKeyValueFilter: true,
    fieldCondition: {
      $or: [
        { clientContactedByOperator: false },
        { clientContactedByOperator: { $exists: 0 } }
      ]
    },
    groupName: 'contactedKeyValueFilterOptions',
    isExclusive: true
  }
]

export default {
  sentStatusFilterOptions,
  userInteractionStatusFilterOptions,
  contactedKeyValueFilterOptions
}
