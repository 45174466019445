import React, { useEffect, useState } from 'react'
import LinearLegendItems from './LegendItems/LinearLegendItems'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Legend = (props) => {
  const [legendItems, setLegendItems] = useState([])

  const getLegendItems = (groupName) => {
    switch (groupName) {
      case 'LINEAR':
        return LinearLegendItems
      default:
        return []
    }
  }

  useEffect(() => {
    const items = getLegendItems(props.groupName)
    setLegendItems(items)
  }, [props.groupName])

  return (
    <React.Fragment>
      <ul className='legend-list'>
        {legendItems.map((item) => (
          <li key={item.name}>
            <div className='interation-icon'>
              <FontAwesomeIcon icon={item.iconComponent} />
            </div>
            <div>{item.label}</div>
          </li>
        ))}
      </ul>
    </React.Fragment>
  )
}

export default Legend
