//import 'react-app-polyfill/ie11';
//import 'react-app-polyfill/stable';
import React, { useEffect } from 'react'
import ReactDOM from "react-dom";
import AdminLayout from "./containers/Layout/Admin.jsx";

import "./assets/css/bootstrap/css/bootstrap.css";

import "./assets/scss/paper-dashboard.scss";
import "./assets/demo/demo.css";

import "./../node_modules/perfect-scrollbar/css/perfect-scrollbar.css";

import { Provider } from 'react-redux';
import createStore from './utils/storeConfig';
import applyAxiosInterceptor from './utils/http-interceptor-config.js';

const store = createStore;

applyAxiosInterceptor()

export const VtvSupport = (props) => {
    
    return (
        <React.Fragment>
            <Provider store={store}>
                <AdminLayout {...props} />
            </Provider>
        </React.Fragment>
    );
}

