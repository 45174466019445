import React from 'react'
import Loader from './../../../Loader/Loader'

import { PreviewModal } from 'vitruvius-preview-module'
import 'vitruvius-preview-module/src/scss/core.scss'

const DefaultClaimInteractionModalCmp = (props) => {
  const token = props.data && props.data.token
  const isSupportLocked =
    props.data && props.data.isSupportLocked === true
  const isLockUnlockLoading =
    props && props.isLockUnlockLoading === true

  const mainStateInfo = props.main
  const { appConfig } = mainStateInfo
  const _isLockMechanismActive = true

  const propsToPreview = {
    showModal: props.showClaimInteractionModal,
    closeModalHandler: props.closeClaimInteractionModal,
    unlockCommunication: props.unlockCommunication,
    lockCommunication: props.lockCommunication,
    data: {
      base_url: appConfig && appConfig.vitruvius_app_preview_url,
      token: token,
      isSupportLocked: isSupportLocked
    },
    isLockUnlockLoading: isLockUnlockLoading,
    loader: <Loader />,
    isLockMechanismActive: _isLockMechanismActive
  }

  return <PreviewModal {...propsToPreview} />
}

export default DefaultClaimInteractionModalCmp
