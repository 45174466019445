export const loadPreviewDetailsAct = (isPolling) => ({
  type: 'LOAD_PREVIEW',
  payload: isPolling
})

export const loadPreviewDetailsSuccessAct = (previewInfo) => ({
  type: 'LOAD_PREVIEW_SUCCESS',
  payload: previewInfo
})

export const loadPreviewDetailsErrorAct = (errorInfo) => ({
  type: 'LOAD_PREVIEW_ERROR',
  payload: errorInfo
})

export const showLoadingSpinnerAct = (boolValue) => ({
  type: 'SHOW_LOADING_SPINNER',
  payload: boolValue
})

export const showCanvasAct = (boolValue) => ({
  type: 'SHOW_CANVAS',
  payload: boolValue
})
