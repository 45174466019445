import { localGetTimeHourWithUtcOffset } from '../../../utils/custom_conversions'

export default [
  {
    Header: 'Data Accadimento',
    id: 'creation_date',
    accessor: (d) => {
      return localGetTimeHourWithUtcOffset(d.creation_date)
    }
  },
  {
    Header: 'Codice Cliente',
    id: 'customer_code',
    accessor: (d) => {
      return d.customer_code
    }
  }
]
