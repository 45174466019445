import React from 'react'
import filter from '../../utils/filter-widgets'

const ToggleMap = (props) => {
  const toggleClick = (isCurrentlyMapTabViewMode) => {
    if (isCurrentlyMapTabViewMode) {
      props.setTabViewMode('table')
    } else {
      props.setTabViewMode('map')
    }
  }

  let groupName = null
  if (
    props.communications.selectedComponent &&
    props.communications.selectedComponent.value
  ) {
    groupName = props.communications.selectedComponent.value
  }
  const communicationStore = props.communications
  const mapTabViewMode =
    communicationStore && communicationStore.tabViewMode === 'map'
  const mapTabViewModeClass = mapTabViewMode ? 'btn-filter-active' : ''
  const blackListToggleMap = ['NOW_AGE', 'LINEAR']
  const buttonMap = (
    <React.Fragment>
      <div
        className={'btn-filter map-filter ' + mapTabViewModeClass}
        onClick={() => toggleClick(mapTabViewMode)}
      >
        <i className='fas fa-map-marked-alt' />
      </div>
    </React.Fragment>
  )
  const renderWidget = filter.shouldRenderThisWidget(
    groupName,
    blackListToggleMap
  )
  return renderWidget ? buttonMap : null
}

export default ToggleMap
