import React from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import { ResponsiveContainer, YAxis, AreaChart, Area } from 'recharts'
import Icon from '../../components/ui/icon/Icon'
import { isValidProperty } from '../../containers/communications-dashboard/utils'

const CommunicationsChart = ({ data, dateLabel, lastUpdated }) => {
  const {
    totComsReceived,
    totLinkOpened,
    totAtLeastOneDoc,
    totComs,
    totDocSaved
  } = data.reduce(
    (acc, currItem) => {
      if (
        !isValidProperty('totalComs', currItem) ||
        !isValidProperty('numComsReceived', currItem) ||
        !isValidProperty('numLinkOpened', currItem) ||
        !isValidProperty('numAtLeastOneDoc', currItem) ||
        !isValidProperty('numDocSaved', currItem)
      ) {
        return acc
      }

      return {
        totComs: acc.totComs + currItem.totalComs,
        totComsReceived: acc.totComsReceived + currItem.numComsReceived,
        totLinkOpened: acc.totLinkOpened + currItem.numLinkOpened,
        totAtLeastOneDoc: acc.totAtLeastOneDoc + currItem.numAtLeastOneDoc,
        totDocSaved: acc.totDocSaved + currItem.numDocSaved
      }
    },
    {
      totComsReceived: 0,
      totLinkOpened: 0,
      totAtLeastOneDoc: 0,
      totComs: 0,
      totDocSaved: 0
    }
  )

  const yAxisDomain = [0, totComs]

  const dataPoints = [
    {
      time: '0',
      point: totComsReceived
    },
    {
      time: '1',
      point: totLinkOpened
    },
    {
      time: '3',
      point: totAtLeastOneDoc
    }
  ]

  const totComsReceivedPercentage = totComsReceived
    ? +((totComsReceived * 100) / totComs).toFixed(2)
    : 0
  const totLinkOpenedPercentage = totLinkOpened
    ? +((totLinkOpened * 100) / totComs).toFixed(2)
    : 0
  const totAtLeastOneDocPercentage = totAtLeastOneDoc
    ? +((totAtLeastOneDoc * 100) / totComs).toFixed(2)
    : 0

  return (
    <React.Fragment>
      <Card>
        <CardHeader className='header-with-search'>
          <CardTitle tag='h4'>Comunicazioni create: {totComs}</CardTitle>
          <span className='title-label'>{dateLabel}</span>
          {lastUpdated && (
            <span className='updated'>
              Ultimo aggiornamento: {lastUpdated}*
            </span>
          )}
        </CardHeader>
        <CardBody>
          <div className='chart__container'>
            <ResponsiveContainer height={250}>
              <AreaChart
                data={dataPoints}
                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id='colorValue1' x1='0' y1='0' x2='1' y2='0'>
                    <stop offset='0%' stopColor='#6BD098' stopOpacity='1' />
                    <stop offset='33%' stopColor='#63CEA9' stopOpacity='1' />
                    <stop offset='66%' stopColor='#58CCBF' stopOpacity='1' />
                    <stop offset='99%' stopColor='#51CBCE' stopOpacity='1' />
                  </linearGradient>
                </defs>
                <YAxis domain={yAxisDomain} hide />
                <Area
                  type='monotone'
                  dataKey='point'
                  stackId='1'
                  strokeWidth={0}
                  fill='url(#colorValue1)'
                />
              </AreaChart>
            </ResponsiveContainer>
            <div className='chart-info__grid'>
              <div className='chart-info__grid-item'>
                <div className='chart-info__grid-item-heading'>
                  <Icon name='send' />
                  <div className='chart-info__grid-item-heading-title'>
                    <h5>Comunicazioni ricevute</h5>
                    <div className='chart-info__grid-item-heading-stats'>
                      <span>{totComsReceived}</span>
                      <span>{totComsReceivedPercentage}%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='chart-info__grid-item'>
                <div className='chart-info__grid-item-heading'>
                  <Icon name='hand-pointer' />
                  <div className='chart-info__grid-item-heading-title'>
                    <h5>Comunicazioni con link aperto almeno una volta</h5>
                    <div className='chart-info__grid-item-heading-stats'>
                      <span>{totLinkOpened}</span>
                      <span>{totLinkOpenedPercentage}%</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='chart-info__grid-item'>
                <div className='chart-info__grid-item-heading'>
                  <Icon name='file-upload' />
                  <div className='chart-info__grid-item-heading-title'>
                    <h5>
                      Comunicazioni con almeno un documento caricato e salvato
                    </h5>
                    <div className='chart-info__grid-item-heading-stats'>
                      <span>{totAtLeastOneDoc}</span>
                      <span>{totAtLeastOneDocPercentage}%</span>
                    </div>
                    <div className='chart-info__grid-item-heading-stats-docs'>
                      <span>N° totale documenti caricati e salvati: </span>
                      <span>{totDocSaved}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
      {lastUpdated && (
        <p className='disclaimer'>
          *I dati delle comunicazioni degli ultimi 5 giorni sono soggetti a
          cambiamento in attesa che scadano
        </p>
      )}
    </React.Fragment>
  )
}

export default CommunicationsChart
