import axios from 'axios'

export const requestHandler = (request) => {
  if (localStorage.getItem('applicationState') !== null) {
    const appState = JSON.parse(localStorage.getItem('applicationState')) // re-hydrate the store
    const authInfoToken =
      appState.auth && appState.auth.authInfo && appState.auth.authInfo.token
    if (authInfoToken) {
      request.headers['Authorization'] = `Bearer ${authInfoToken}`
    }
    request.headers['Pragma'] = 'no-cache'
  }

  return request
}

const applyAxiosInterceptor = () => {
  axios.interceptors.request.use((request) => requestHandler(request))
}

export default applyAxiosInterceptor
