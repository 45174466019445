import NowAgeFilters from './NOW_AGE/nowAgeFilters'
import DefaultAgeFilters from './DEFAULT_AGE/defaultAgeFilters'
import RealtimeFilters from './REALTIME/realtimeFilters'
import LinearFilters from './LINEAR/linearFilters'

const getFilterGroupArrayElements = (groupName, filterGroupKeyName) => {
  switch (groupName) {
    case 'NOW_AGE':
      return (
        NowAgeFilters[filterGroupKeyName] ||
        DefaultAgeFilters[filterGroupKeyName] ||
        []
      )
    case 'REALTIME':
      return (
        RealtimeFilters[filterGroupKeyName] ||
        DefaultAgeFilters[filterGroupKeyName] ||
        []
      )
    case 'LINEAR':
      return LinearFilters[filterGroupKeyName] || []
    default:
      return DefaultAgeFilters[filterGroupKeyName]
  }
}

export const getGroupedAdvFilters = (groupName = '') => {
  return [
    {
      label: 'Stato Invii',
      options: getFilterGroupArrayElements(groupName, 'sentStatusFilterOptions')
    },
    {
      label: 'Stato Interazioni',
      options: getFilterGroupArrayElements(
        groupName,
        'userInteractionStatusFilterOptions'
      )
    },
    {
      label: 'Stato',
      options: getFilterGroupArrayElements(groupName, 'statusFilterOptions')
    },
    {
      label: 'Conferme',
      options: getFilterGroupArrayElements(groupName, 'confirmedFilterOptions')
    },
    {
      label: 'Scadenza',
      options: getFilterGroupArrayElements(
        groupName,
        'expiredKeyValueFilterOptions'
      )
    },
    {
      label: 'Contattato',
      options: getFilterGroupArrayElements(
        groupName,
        'contactedKeyValueFilterOptions'
      )
    },
    {
      label: 'Altri',
      options: getFilterGroupArrayElements(
        groupName,
        'otherKeyValueFilterOptions'
      )
    }
  ]
}
