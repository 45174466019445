import React from 'react';

function LinkSmsDetailsListCmp(props) {
  const getCompanyNameByCompanyCode = (companyCode) => {
    switch (companyCode) {
      case '1':
        return 'Unipol';
      case '5':
        return 'Linear';
      default:
        return null;
    }
  };

  const renderDetailsInfo = (data) => (
    <React.Fragment>
      <dl>
        <dt>Nome assicurato: </dt>
        <dd>{data.owner_ns || '--'}</dd>
        <dt>Compagnia assicurativa: </dt>
        <dd>{getCompanyNameByCompanyCode(data.company_code) || '--'}</dd>
        <dt>Numero di telefono: </dt>
        <dd>{data.phone_number || '--'}</dd>
        <dt>Data incidente: </dt>
        <dd>{data.occurrence_date ? data.occurrence_date : '--'}</dd>
        <dt>Veicolo assicurato: </dt>
        <dd>{data.plate_ns || '--'}</dd>

        <dt>Propietaria controparte: </dt>
        <dd>{data.owner_ctp || '--'}</dd>
        <dt>Targa controparte: </dt>
        <dd>{data.plate_ctp || '--'}</dd>
        <dt>Compagnia controparte: </dt>
        <dd>{data.company_ctp || '--'}</dd>
      </dl>
    </React.Fragment>
  );

  const DetailsInfo =
    props.communicationDetails && props.communicationDetails.data
      ? renderDetailsInfo(props.communicationDetails.data)
      : null;

  return <div className='incident-info-container'>{DetailsInfo}</div>;
}

export default LinkSmsDetailsListCmp;
