import React from 'react'
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap'
import moment from 'moment'
import {
  createListFile,
  isValidProperty,
  monthNames
} from '../../containers/communications-dashboard/utils'
import Icon from '../ui/icon/Icon'
import Button from '../ui/button/Button'

const CommunicationsStatsHistory = ({ data }) => {
  let currentYear

  const groupedData = data.reduce((acc, currItem) => {
    const dt = moment(currItem.timestamp)
    const month = dt.month()
    currentYear = dt.year()

    if (!acc[month]) acc[month] = []

    if (!isValidProperty('totalComs', currItem)) {
      currItem.totalComs = 'N/A'
    }

    if (!isValidProperty('numComsReceived', currItem)) {
      currItem.numComsReceived = 'N/A'
    }

    if (!isValidProperty('numLinkOpened', currItem)) {
      currItem.numLinkOpened = 'N/A'
    }

    if (!isValidProperty('numAtLeastOneDoc', currItem)) {
      currItem.numAtLeastOneDoc = 'N/A'
    }

    if (!isValidProperty('numDocSaved', currItem)) {
      currItem.numDocSaved = 'N/A'
    }

    acc[month] = [...acc[month], currItem]

    return acc
  }, {})

  const handleDownload = (month) => {
    if (month) {
      return createListFile(
        groupedData[month],
        `${monthNames[month]}-${currentYear}_storico`
      )
    }

    return createListFile(data, `${currentYear}_storico`)
  }

  return (
    <Card>
      <CardHeader className='header-with-search'>
        <CardTitle tag='h4'>Storico estrazioni</CardTitle>
      </CardHeader>
      <CardBody>
        {Object.keys(groupedData).length > 0 ? (
          <div className='history-table'>
            <div className='history-table__header'>
              <div className='col'>Mese</div>
              <div className='col'>Anno</div>
              <div className='col-fixed' />
            </div>
            {Object.keys(groupedData).map((k, idx) => (
              <div className='history-table__row' key={idx}>
                <div className='col'>{monthNames[k]}</div>
                <div className='col'>{currentYear}</div>
                <div className='col-fixed'>
                  <Button
                    className='btn-flex'
                    variant='dark'
                    onClick={() => handleDownload(k)}
                  >
                    <Icon name='download' />
                  </Button>
                </div>
              </div>
            ))}
            <div className='history-table__row' key='-1'>
              <div className='col'>Tot. anno</div>
              <div className='col'>{currentYear}</div>
              <div className='col-fixed'>
                <Button variant='dark' onClick={() => handleDownload()}>
                  <Icon name='download' />
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <p>Nessuna estrazione disponibile</p>
        )}
      </CardBody>
    </Card>
  )
}

export default CommunicationsStatsHistory
