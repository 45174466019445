export const initialState = {
  data: null,
  baremes: null,
  isLoading: false,
  loadingError: false,
  showClaimData: true,
  takeClaimControl: false,
  showClaimInteractionModal: false,
  showTooltip: false,
  notesUnderUpdate: '',
  documents: null
}

export const communicationDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_COMMUNICATION_DETAILS_PAGINATIONS_UPDATED':
      return {
        ...state,
        sorted: action.payload.sorted,
        filtered: action.payload.filtered
      }
    case 'LOAD_COMMUNICATION_DETAILS':
      return {
        ...state,
        isLoading: !action.payload,
        loadingError: false
      }
    case 'LOAD_COMMUNICATION_DETAILS_SUCCESS':
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        loadingError: false
      }
    case 'LOAD_COMMUNICATION_DETAILS_ERROR':
      return {
        ...state,
        isLoading: false,
        loadingError: true,
        userInfoError: action.payload
      }
    case 'LOAD_BAREMES_INFO':
      return {
        ...state,
        isLoading: !action.payload,
        loadingError: false
      }
    case 'LOAD_BAREMES_INFO_SUCCESS':
      return {
        ...state,
        baremes: action.payload,
        isLoading: false,
        loadingError: false
      }
    case 'LOAD_BAREMES_INFO_ERROR':
      return {
        ...state,
        isLoading: false,
        loadingError: true,
        userInfoError: action.payload
      }
    case 'SHOW_CLAIM_DATA':
      return {
        ...state,
        showClaimData: true,
        takeClaimControl: false
      }
    case 'TAKE_CLAIM_CONTROL':
      return {
        ...state,
        showClaimData: false,
        takeClaimControl: true
      }
    case 'CLEAN_SELECTED_COMMUNICATION_DATA':
      return {
        ...state,
        data: null
      }
    // MODALS
    case 'OPEN_CLAIM_INTERACTION_MODAL':
      return {
        ...state,
        showClaimInteractionModal: true
      }
    case 'CLOSE_CLAIM_INTERACTION_MODAL':
      return {
        ...state,
        showClaimInteractionModal: false
      }
    case 'TRIGGER_TOOLTIP':
      return {
        ...state,
        showTooltip: true
      }
    case 'CLEAR_TOOLTIP':
      return {
        ...state,
        showTooltip: false
      }
    case 'LOCK_CLAIM':
    case 'UNLOCK_CLAIM':
      return {
        ...state,
        isLockUnlockLoading: true
      }
    case 'LOCK_UNLOCK_COMMUNICATION_DETAILS_SUCCESS':
      return {
        ...state,
        isLockUnlockLoading: false,
        lockUnlockError: false
      }
    case 'LOCK_UNLOCK_COMMUNICATION_DETAILS_ERROR':
      return {
        ...state,
        isLockUnlockLoading: false,
        lockUnlockError: true
      }
    case 'CLIENT_CONTACTED':
      return {
        ...state,
        data: Object.assign({}, state.data, { clientContactedByOperator: true })
      }
    case 'CLIENT_NOT_CONTACTED':
      return {
        ...state,
        data: Object.assign({}, state.data, {
          clientContactedByOperator: false
        })
      }
    case 'TOGGLE_CLIENT_CONTACTED_ERROR':
      return {
        ...state,
        data: Object.assign({}, state.data, {
          clientContactedByOperator: !state.data.clientContactedByOperator
        })
      }
    case 'UPDATE_NOTE_VALUE':
      return {
        ...state,
        notesUnderUpdate: action.payload
      }
    case 'SUBMIT_UPDATED_NOTE_VALUE': {
      return {
        ...state,
        data: Object.assign({}, state.data, {
          operatorNotes: state.notesUnderUpdate
        })
      }
    }
    case 'LOAD_DOCUMENTS_LIST': {
      return {
        ...state,
        loadingError: false
      }
    }
    case 'LOAD_DOCUMENTS_LIST_SUCCESS': {
      return {
        ...state,
        documents: action.payload,
        loadingError: false
      }
    }
    case 'LOAD_DOCUMENTS_LIST_ERROR': {
      return {
        ...state,
        loadingError: true
      }
    }
    default:
      return state
  }
}
