import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { connect } from 'react-redux'
import { PDFDocument } from 'pdf-lib'
import Loader from './../../components/Loader/Loader'
import ZoomDocumentsModal from './../../components/Modals/ZoomDocuments/ZoomDocumentsModal'
import convert from './../../utils/convert-partition-name'

import * as pdfjsLib from './../../../node_modules/pdfjs-dist'
if (pdfjsLib && pdfjsLib.GlobalWorkerOptions) {
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`
}

const DocumentDetails = (props) => {
  const [cmpState, setCmpState] = useState({
    activePdf: null,
    bufferPdf: null,
    errorCommunication: null,
    isRenderingPdf: false,
    isFirstLanding: true,
    loadingEnd: true,
    indexPdf: 0,
    currentPage: 0,
    numPages: 0,
    totalPages: 0,
    sum: 0,
    currentValue: 0,
    modal: false,
    canvasForModal: null,
    pdf: null,
    showSpinner: false,
    showCanvas: true
  })

  const prepareCanvas = (page, bufferPdf) => {
    setCmpState(prevState => ({
      ...prevState,
      showCanvas: true,
      showSpinner: false
    }))
    let scale = 0.4
    let viewport = page.getViewport({ scale })
    const pdfPreview = document.getElementsByClassName('pdf-preview')[0]
    // eslint-disable-next-line no-unmodified-loop-condition
    while (pdfPreview && viewport.width > pdfPreview.offsetWidth - 15) {
      scale = scale - 0.01
      viewport = null
      while (!viewport) {
        viewport = page.getViewport({ scale })
      }
    }
    const canvasId = 'canvas_' + props.id
    const canvas = document.getElementById(canvasId)
    if (canvas) {
      canvas.width = viewport.width
      canvas.height = viewport.height
      const context = canvas.getContext('2d')
      const renderContext = {
        canvasContext: context,
        viewport: viewport
      }
      page.render(renderContext).promise.then(
        () => {
          setCmpState(prevState => ({
            ...prevState,
            canvasForModal: page,
            isRenderingPdf: false,
            bufferPdf,
            loadingEnd: true,
            errorCommunication: null
          }))
        },
        (reason) => {
          setCmpState(prevState => ({
            ...prevState,
            canvasForModal: null,
            isRenderingPdf: false,
            bufferPdf,
            loadingEnd: true,
            errorCommunication: reason
          }))
        }
      )
    }
  }

  const errorSignal = (err) => {
    setCmpState(prevState => ({
      ...prevState,
      showCanvas: true,
      showSpinner: false,
      errorCommunication: err
    }))
  }

  const convertImageInPdf = async (img) => {
    const { data, contentType } = img

    const pdfDoc = await PDFDocument.create()

    let image

    if (contentType === 'image/jpeg') {
      image = await pdfDoc.embedJpg(data)
    } else if (contentType === 'image/png') {
      image = await pdfDoc.embedPng(data)
    } else {
      throw new Error('Unsupported image format')
    }

    const { width, height } = image
    pdfDoc.addPage([width, height]).drawImage(image, {
      x: 0,
      y: 0,
      width: width,
      height: height
    })

    const pdfBytes = await pdfDoc.save()
    return new Blob([pdfBytes], { type: 'application/pdf' })
  }

  const pFileReader = (blob) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = function found() {
        resolve(fileReader.result)
      }
      fileReader.readAsArrayBuffer(blob)
    })
  }

  const counterAllPages = (pdf) => {
    const promises = []
    Object.keys(pdf).forEach((i) => {
      promises.push(
        pdf[i].contentType === 'image/jpeg' ||
          pdf[i].contentType === 'image/png'
          ? new Promise((resolve, reject) => {
            resolve(1)
          })
          : pdfjsLib.getDocument({ data: pdf[i].data }).promise.then((res) => {
            return res.numPages
          })
      )
    })
    return Promise.all(promises)
      .then((res) => {
        return res
      })
      .catch((err) => {
        errorSignal(err)
        return err
      })
  }

  const renderFirstPage = (pdf, totalPages) => {
    pdfjsLib.getDocument({ data: pdf[0].data }).promise.then(
      (res) => {
        res.getPage(1).then(
          (page) => {
            prepareCanvas(page, pdf)
            setCmpState(prevState => ({
              ...prevState,
              activePdf: res,
              indexPdf: 0,
              currentPage: 1,
              numPages: res.numPages,
              sum: 1,
              totalPages
            }))
          },
          (err) => {
            errorSignal(err)
          }
        )
      },
      (err) => {
        errorSignal(err)
      }
    )
  }

  useEffect(() => {

    // prevProps, prevState
    if (!pdf.length) {
      return
    }
    const { isFirstLanding, currentValue, pdf } = cmpState

    const newPdf = { ...pdf }
    counterAllPages(pdf)
      .then((resTot) => {
        let totalPages = 0
        if (Array.isArray(resTot) && resTot.length > 0) {
          totalPages = resTot.reduce((a, b) => a + b, 0)
          resTot.forEach((r, index) => {
            newPdf[index] = {
              ...newPdf[index],
              pages: r
            }
          })
        }
        if (newPdf[0].contentType === 'application/pdf' && isFirstLanding) {
          renderFirstPage(newPdf, totalPages)
        }
        for (let i = 0; i < Object.keys(newPdf).length; i++) {
          if (
            newPdf[i].contentType === 'image/jpeg' ||
            newPdf[i].contentType === 'image/png'
          ) {
            convertImageInPdf(newPdf[i]).then((blob) => {
              pFileReader(blob).then((arrayBuffer) => {
                const convertedPdf = {
                  data: arrayBuffer,
                  contentType: 'application/pdf',
                  pages: 1,
                  wasImage: true,
                  id: newPdf[i].id
                }
                newPdf[i] = convertedPdf
                if (parseInt(i) === 0 && isFirstLanding) {
                  renderFirstPage(newPdf, totalPages)
                } else if (!isFirstLanding) {
                  if (i === currentValue) {
                    return pdfjsLib
                      .getDocument({ data: newPdf[i].data })
                      .promise.then((res) => {
                        res
                          .getPage(state.currentPage)
                          .then((page) => {
                            prepareCanvas(page, state.bufferPdf)
                          })
                          .catch((err) => {
                            errorSignal(err)
                          })
                      })
                      .catch((err) => {
                        errorSignal(err)
                      })
                  } else {
                    setCmpState(prevState => ({
                      ...prevState, bufferPdf: newPdf
                    }))
                  }
                }
              })
            })
          } else {
            setCmpState(prevState => ({
              ...prevState, bufferPdf: newPdf
            }))
          }
        }
      })
      .catch((err) => {
        errorSignal(err)
      })






  }, [pdf && pdf.length && pdf[0].id])

  const nextPage = (sum) => {
    const {
      currentPage,
      indexPdf,
      activePdf,
      bufferPdf,
      numPages,
      isRenderingPdf,
      currentValue
    } = cmpState
    if (!isRenderingPdf) {
      if (currentPage < numPages) {
        setCmpState(prevState => ({
          ...prevState,
          isRenderingPdf: true
        }))
        activePdf.getPage(currentPage + 1).then(
          (page) => {
            prepareCanvas(page, bufferPdf)
            setCmpState(prevState => ({
              ...prevState,
              currentPage: currentPage + 1, sum
            }))
          },
          (err) => {
            errorSignal(err)
          }
        )
      } else if (currentPage === numPages && bufferPdf[indexPdf + 1]) {
        setCmpState(prevState => ({
          ...prevState,
          isRenderingPdf: true, currentValue: currentValue + 1
        }))
        const loadingTask = pdfjsLib.getDocument({
          data: bufferPdf[indexPdf + 1].data
        })
        loadingTask.promise.then((res) => {
          res.getPage(1).then((page) => {
            prepareCanvas(page, bufferPdf)
            setCmpState(prevState => ({
              ...prevState,

              activePdf: res,
              indexPdf: indexPdf + 1,
              currentPage: 1,
              numPages: res.numPages,
              sum
            }))
          })
        })
      }
    }
  }

  const prevPage = (sum) => {
    const {
      currentPage,
      indexPdf,
      activePdf,
      bufferPdf,
      isRenderingPdf,
      currentValue
    } = cmpState
    if (!isRenderingPdf) {
      if (currentPage > 1) {
        setCmpState(prevState => ({
          ...prevState,
          isRenderingPdf: true
        }))
        activePdf.getPage(currentPage - 1).then(
          (page) => {
            prepareCanvas(page, bufferPdf)
            setCmpState(prevState => ({
              ...prevState,
              currentPage: currentPage - 1, sum
            }))
          },
          (err) => {
            errorSignal(err)
          }
        )
      } else if (currentPage === 1 && bufferPdf[indexPdf - 1]) {
        setCmpState(prevState => ({
          ...prevState,
          isRenderingPdf: true, currentValue: currentValue - 1
        }))
        pdfjsLib
          .getDocument({ data: bufferPdf[indexPdf - 1].data })
          .promise.then(
            (res) => {
              res.getPage(bufferPdf[indexPdf - 1].pages).then(
                (page) => {
                  prepareCanvas(page, bufferPdf)
                  setCmpState(prevState => ({
                    ...prevState,

                    activePdf: res,
                    indexPdf: indexPdf - 1,
                    currentPage: res.numPages,
                    numPages: res.numPages,
                    sum
                  }))
                },
                (err) => {
                  errorSignal(err)
                }
              )
            },
            (err) => {
              errorSignal(err)
            }
          )
      }
    }
  }

  const openModal = (bool) => {
    setCmpState(prevState => ({
      ...prevState,
      modal: bool
    }))
  }

  const loadPreviewDetails = (id) => {
    const stateCommunications = props.communications
    const token =
      stateCommunications.selectedCommunication &&
      stateCommunications.selectedCommunication.token
    const groupName =
      stateCommunications.selectedComponent &&
      stateCommunications.selectedComponent.value
    const partition = convert.convertPartitionName(groupName)
    const aggregateData = []
    const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/${token}/${id}`
    try {
      return axios
        .get(requestURL, {
          responseType: 'arraybuffer'
        })
        .then((response) => {
          aggregateData.push({
            data: response.data,
            id: id,
            contentType: response.headers['content-type']
          })
          setCmpState(prevState => ({
            ...prevState,
            pdf: aggregateData,
            showCanvas: true,
            showSpinner: false,
            errorCommunication: null
          }))
          // }
        })
        .catch((err) => {
          setCmpState(prevState => ({
            ...prevState,
            showSpinner: false,
            showCanvas: false,
            errorCommunication: err
          }))
        })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    setCmpState(prevState => ({
      ...prevState,
      showCanvas: false
    })) // nessuna canvas fino al select
    setCmpState(prevState => ({
      ...prevState,
      showSpinner: true
    }))
    loadPreviewDetails(props.id)
  }, [])



  const {
    totalPages,
    sum,
    errorCommunication,
    indexPdf,
    isRenderingPdf,
    bufferPdf,
    modal,
    canvasForModal
  } = cmpState
  const { waitRotating, communications } = props

  let bearerToken = null
  if (localStorage.getItem('applicationState') !== null) {
    const appState = JSON.parse(localStorage.getItem('applicationState')) // re-hydrate the store
    const authInfoToken =
      appState.auth && appState.auth.authInfo && appState.auth.authInfo.token
    if (authInfoToken) {
      bearerToken = authInfoToken
    }
  }

  const token =
    communications &&
    communications.selectedCommunication &&
    communications.selectedCommunication.token
  const groupName =
    communications.selectedComponent && communications.selectedComponent.value
  const partition = convert.convertPartitionName(groupName)

  const id = bufferPdf && bufferPdf[indexPdf].id
  const canvasId = 'canvas_' + props.id
  // let canRotate = bufferPdf && bufferPdf[indexPdf] && bufferPdf[indexPdf].wasImage ? true : false;
  const noDocs = (
    <div className='text-no-docs'>
      Seleziona una tipologia di documento per visualizzare i file caricati.
    </div>
  )

  const lastLineInstruments = (
    <div className='outline-content'>
      <div className='zoom-icon'>
        <i
          className='fa fa-search'
          onClick={() => {
            !waitRotating && openModal(true)
          }}
        />
      </div>
      <div className='download-icon'>
        <a
          href={`${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/${partition}communication/${token}/${id}?token=${bearerToken}&download=true`}
          target='_blank'
          rel='noreferrer'
        >
          <i className='fa fa-download' />
        </a>
      </div>
      <div className='rotate-icon'>
        <i className='fa fa-rotate-right' onClick={() => {}} />
      </div>
    </div>
  )

  const textError = (
    <div>
      <div className='text-no-docs'>Errore</div>
      <div className='text-no-docs'>
        {errorCommunication ? errorCommunication.message : ''}
      </div>
    </div>
  )

  const renderSpace = (
    <div className='inline-content first'>
      {lastLineInstruments}
      <div className='arrow left'>
        {totalPages !== 1 && sum !== 1 && (
          <i
            className='fa fa-angle-left'
            onClick={() => !isRenderingPdf && prevPage(sum - 1)}
          />
        )}
      </div>
      <div className='pdf-preview'>
        <div className='page'>
          <canvas
            id={canvasId}
            onClick={() => {
              !waitRotating && openModal(true)
            }}
          />
        </div>
      </div>
      <div className='arrow right'>
        {totalPages !== 1 && sum !== totalPages && (
          <i
            className='fa fa-angle-right'
            onClick={() => !isRenderingPdf && nextPage(sum + 1)}
          />
        )}
        <div className='pager-list'>{sum + '/' + totalPages}</div>
      </div>
    </div>
  )

  return (
    <div>
      <ZoomDocumentsModal
        modal={modal}
        canvasForModal={canvasForModal}
        openModal={openModal}
      />
      <div className='preview-container'>
        {cmpState.showSpinner && <Loader />}
        {cmpState.showCanvas && renderSpace}
        {!cmpState.showCanvas && !cmpState.showSpinner && noDocs}
        {errorCommunication && textError}
      </div>
    </div>
  )
}


const mapStateToProps = (state, ownProps) => ({
  communications: state.communications,
  communicationDetails: state.communicationDetails
})

const mapDispatchToProps = (dispatch, ownProps) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetails)
