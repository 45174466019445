import React from 'react';
import PreviewDocumentDetails from '../../../containers/preview-details/PreviewDocumentDetails';
import GridDocuments from '../../DropdownDocuments/GridDocuments';

function LinearDetailsListCmp(props) {
  let interactions = null;
  const details = props.communicationDetails;
  const isDocumentsGridView = !!props.documentsGridView;

  if (
    details &&
    details.data &&
    details.data.interactions &&
    details.data.interactions.length > 0
  ) {
    interactions = details.data.interactions;
  }

  const currentCommunication =
    props.communicationDetails && props.communicationDetails.data;

  if (details.isLoading || !currentCommunication) {
    return null;
  }

  return (
    <div className='documents-container incident-info-container'>
      {interactions ? (
        isDocumentsGridView ? (
          <GridDocuments interactions={interactions} />
        ) : (
          <PreviewDocumentDetails interactions={interactions} />
        )
      ) : (
        'Nessuna interazione presente per questa comunicazione'
      )}
    </div>
  );
}

export default LinearDetailsListCmp;
