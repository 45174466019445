import React, { useEffect } from 'react'
import Markdown from 'markdown-to-jsx'
import _ from 'lodash'

const RealtimeDetailsListCmp = (props) => {
  const findInteractionInc = (interactions) => {
    let inc = '--'
    if (interactions && interactions.length) {
      interactions.forEach((element) => {
        if (element.name && element.name === 'modify_position_widget') {
          inc = element.value[2]
        }
      })
    }
    return inc
  }

  const findCAIphoto = (interactions) => {
    let cai = 'NO'
    if (interactions && interactions.length) {
      interactions.forEach((element) => {
        if (element.name && element.name === 'cai_doc') {
          cai = 'SI'
        } else if (element.widget_id && element.widget_id === '25-0-0-1') {
          cai = 'SI'
        }
      })
    }
    return cai
  }

  const countAccindentPhoto = (interactions) => {
    let photos = 0
    if (interactions && interactions.length) {
      interactions.forEach((element) => {
        if (element.name && element.name === 'other_ph') {
          photos = element.value.length
        } else if (element.widget_id && element.widget_id === '15-0-0-1') {
          photos = element.value.length
        }
      })
    }
    return photos
  }

  const getListPhoto = (interactions) => {
    const listPhotos = []
    let listJoined = ''
    const arrayGrid = [
      'TOP-LEFT',
      'TOP-CENTER',
      'TOP-RIGHT',
      'CENTER-LEFT',
      'CENTER-CENTER',
      'CENTER-RIGHT',
      'BOTTOM-LEFT',
      'BOTTOM-CENTER',
      'BOTTOM-RIGHT'
    ]
    if (interactions && interactions.length) {
      interactions.forEach((element) => {
        if (element.file_type && arrayGrid.includes(element.file_type)) {
          listPhotos.push(element.file_type)
        }
      })
      listJoined = listPhotos.join(', ')
    }
    return listJoined
  }

  const showAnswers = (questions) => {
    const answer = []
    if (questions.length === 0) {
      return 'nessuna risposta'
    }
    questions.forEach((quest, ind) => {
      answer.push(
        <div key={ind}>
          {quest.label + ' '}
          <b>{quest.testo}</b>
        </div>
      )
    })
    return answer
  }

  const renderMarkdown = (bareme_dichiarato) => {
    if (bareme_dichiarato === '****' || !bareme_dichiarato) {
      return '--'
    }
    return <Markdown>{bareme_dichiarato}</Markdown>
  }

  const handlecorrelatedClick = (e, correlatedToken) => {
    e.preventDefault()
    const currentLocation = window.location.origin + window.location.pathname
    window.open(`${currentLocation}?commTk=${correlatedToken}`, '_blank')
  }

  const renderIncidentInfo = (data, correlatedCommunication, correlatedLoading) => {
    const correlatedCommToken =
      correlatedCommunication && correlatedCommunication.token
    return (
      <React.Fragment>
        <dl>
          <dt>Name: </dt>
          <dd>
            {data.assic_nome || data.assic_cognome
              ? `${data.assic_nome} ${data.assic_cognome}`
              : '--'}
          </dd>
          <dt>Contatto: </dt>
          <dd>{data.assic_tel_o_email || '--'}</dd>
          <dt>Codice Fiscale: </dt>
          <dd>{data.customer_code || '--'}</dd>
          <dt>Targa: </dt>
          <dd>{data.veic_targa || '--'}</dd>
          <dt>ID Incidente: </dt>
          <dd>{data.crash_id || '--'}</dd>
          {data && data.correlated_crash_id && (
            <React.Fragment>
              <dt>ID Incidente correlato: </dt>
              {correlatedLoading ? (
                <dd className='correlated-link'>
                  {data.correlated_crash_id || '--'}
                </dd>
              ) : (
                <a
                  href='!#'
                  className='correlated-link after animated-link'
                  onClick={(e) =>
                    handlecorrelatedClick(
                      e,
                      correlatedCommToken,
                      data.crash_id
                    )
                  }
                >
                  {data.correlated_crash_id || '--'}
                </a>
              )}
            </React.Fragment>
          )}
          <dt>Data incidente: </dt>
          <dd>{data.inc_data ? data.inc_data : '--'}</dd>
          <dt>Luogo rilevato: </dt>
          <dd>{data.inc_luogo || '--'}</dd>
          <dt>Luogo dichiarato: </dt>
          <dd>{findInteractionInc(data.interactions)}</dd>
          <dt>Responsabilità predetta: </dt>
          <dd>{data.bareme_desc || '--'}</dd>
          <dt>Responsabilità dichiarata: </dt>
          <dd className='markdown-label'>
            {props.communicationDetails.baremes
              ? renderMarkdown(
                props.communicationDetails.baremes.bareme_description
              )
              : '--'}
          </dd>
          <dt className='question-answer'>Risposte questionario: </dt>
          <dd className='question-answer'>
            {props.communicationDetails.baremes
              ? showAnswers(
                props.communicationDetails.baremes.bareme_questions
              )
              : 'nessuna risposta'}
          </dd>
          <dt>Numero foto incidente: </dt>
          <dd>
            {countAccindentPhoto(data.interactions) ||
              'nessuna foto caricata'}
          </dd>
          <dt>Foto punti d'urto: </dt>
          <dd>
            {getListPhoto(data.interactions) || 'nessuna foto caricata'}
          </dd>
          <dt>CAI: </dt>
          <dd>{findCAIphoto(data.interactions)}</dd>
        </dl>
      </React.Fragment>
    )
  }

  useEffect(() => {
    props.loadBaremesInfo()
  }, [])

  useEffect(() => {
    const prevbaremes = props.communicationDetails.baremes
    const baremes = props.communicationDetails.baremes
    if (prevbaremes && baremes) {
      const dArr = _.difference(
        baremes.bareme_description,
        prevbaremes.bareme_description,
        _.isEqual
      )
      const dArr2 = _.differenceWith(
        baremes.bareme_questions,
        prevbaremes.bareme_questions,
        _.isEqual
      )
      if ((dArr && dArr.length) || (dArr2 && dArr2.length)) {
        renderIncidentInfo(
          props.communicationDetails.data,
          props.communications.correlatedCommunication
        )
      }
    }
  }, [])
  const IncidentInfo =
    props.communicationDetails && props.communicationDetails.data
      ? renderIncidentInfo(
        props.communicationDetails.data,
        props.communications.correlatedCommunication,
        props.communications.isCorrelatedLoading
      )
      : null;

  return <div className='incident-info-container'>{IncidentInfo}</div>;
};

export default RealtimeDetailsListCmp;


