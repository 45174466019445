import React, { useState, useEffect, useRef } from 'react'
import ModalFrame from './modalFrame'

const PreviewModal = (props) => {
  const {
    data,
    isLockUnlockLoading,
    isLockMechanismActive,
    unlockCommunication,
    lockCommunication
  } = props

  const [iframeLoadingMinTime, setIframeLoadingMinTime] = useState(false)
  const timeOutMinTimeLoading = useRef()

  const activateIframeLoadingMinTime = () => {
    setIframeLoadingMinTime(true)
    timeOutMinTimeLoading.current = setTimeout(() => {
      setIframeLoadingMinTime(false)
    }, 2000)
  }

  useEffect(() => {
    return () => clearTimeout(timeOutMinTimeLoading.current)
  }, [])

  const refreshPreview = () => {
    activateIframeLoadingMinTime()
    const iframeEl = document.getElementById('preview-app')
    if (iframeEl) {
      const srcToRefresh = iframeEl.src
      iframeEl.src = srcToRefresh
    }
  }

  const token = data && data.token
  const baseUrl = data && data.base_url
  const isSupportLocked = data && data.isSupportLocked === true
  const isSupportLockedClass = isSupportLocked ? 'support-locked' : ''
  const previewUrlInfoPresent = !!baseUrl
  const urlReady = !!baseUrl && !!token

  const iframeLoadingMinTimeClass =
    iframeLoadingMinTime || isLockUnlockLoading ? 'loading-frame-minimum' : ''
  const iframeUrl = baseUrl + '/' + token + '?support_op=true'

  return (
    <div>
      <ModalFrame
        {...props}
        isSupportLocked={isSupportLocked}
        isSupportLockedClass={isSupportLockedClass}
        activateIframeLoadingMinTime={activateIframeLoadingMinTime}
        refreshPreview={refreshPreview}
      >
        {isLockMechanismActive && (
          <div
            className={
              'alert alert-warning alert-dismissible show alert-lock ' +
              isSupportLockedClass
            }
            role='alert'
          >
            <div>
              <span>
                <b>Attenzione - </b>Comunicazione IN LOCK per l'utente. Clicca
                il bottone FATTO per rilasciare il controllo all'utente.
              </span>
            </div>
            <button
              className='btn-round btn btn-success'
              onClick={unlockCommunication}
            >
              FATTO
            </button>
          </div>
        )}

        <div className='phone-preview'>
          <div className='hermes-app-preview'>
            <div className='preview-mask'>
              <div className={'loader-container ' + iframeLoadingMinTimeClass}>
                <div>{props.loader}</div>
              </div>

              {isLockMechanismActive &&
                !isSupportLocked &&
                !isLockUnlockLoading &&
                previewUrlInfoPresent && (
                  <div
                    className='panel-to-lock'
                    onClick={() => {
                      activateIframeLoadingMinTime()
                      refreshPreview()
                      lockCommunication()
                    }}
                  >
                    <p>Clicca per prendere il controllo dell'applicativo</p>
                    <i className='fa fa-unlock-alt' />
                  </div>
                )}

              {urlReady && (!isLockMechanismActive || isSupportLocked) ? (
                <iframe
                  title='claimInteractionIFrame'
                  id='preview-app'
                  src={iframeUrl}
                  className='globalRoot'
                />
              ) : (
                <iframe
                  title='claimInteractionIFrame'
                  id='preview-app'
                  src={null}
                  className='globalRoot'
                />
              )}

              {!previewUrlInfoPresent && (
                <div className='panel-to-lock has-danger'>
                  <p>Errore nel caricamento dell'url.</p>
                  <p>Preview non disponibile.</p>
                  <i className='fa fa-unlink' />
                </div>
              )}
            </div>
          </div>
        </div>
      </ModalFrame>
    </div>
  )
}

export { PreviewModal }
