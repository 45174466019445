import React, { useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faMobileAlt,
  faTabletAlt,
  faDesktop,
  faSync,
  faTimes
} from '@fortawesome/free-solid-svg-icons'

const getModalPositionClass = (_modalPosition) => {
  switch (_modalPosition) {
    case 'LEFT':
      return ' left '
    case 'TOP':
      return ' top '
    case 'RIGHT':
      return ' right '
    case 'EMBEDDED':
      return ' embedded '
    default:
      return ' right '
  }
}

const isEmbedded = (_modalPosition) => {
  return _modalPosition === 'EMBEDDED'
}

const ModalFrame = (props) => {
  const [phoneView, setPhoneView] = useState(true)
  const [tabletView, setTabletView] = useState(false)
  const [desktopView, setDesktopView] = useState(false)
  const [fullScreenView, setFullScreenView] = useState(false)

  const phoneModeClicked = () => {
    setPhoneView(true)
    setTabletView(false)
    setDesktopView(false)
    setFullScreenView(false)
  }

  const tabletModeClicked = () => {
    setPhoneView(false)
    setTabletView(true)
    setDesktopView(false)
    setFullScreenView(false)
  }

  const desktopModeClicked = () => {
    setPhoneView(false)
    setTabletView(false)
    setDesktopView(true)
    setFullScreenView(false)
  }

  const fullScreenModeClicked = () => {
    setPhoneView(false)
    setTabletView(false)
    setDesktopView(false)
    setFullScreenView(true)
  }

  useEffect(() => {
    props.activateIframeLoadingMinTime()
    switch (props.interactionDefaultMode) {
      case 'PHONE':
        phoneModeClicked()
        return
      case 'TABLET':
        tabletModeClicked()
        return
      case 'DESKTOP':
        desktopModeClicked()
        return
      case 'FULL_SCREEN':
        fullScreenModeClicked()
        return
      default:
        phoneModeClicked()
    }
  }, [])

  const phoneViewClass = phoneView ? 'phone-view' : ''
  const tabletViewClass = tabletView ? 'tablet-view' : ''
  const desktopViewClass = desktopView ? 'desktop-view' : ''
  const fullScreenViewClass = fullScreenView ? 'full-screen-view' : ''
  const phoneActiveViewClass = phoneView ? 'btn-selected' : ''
  const tabletActiveViewClass = tabletView ? 'btn-selected' : ''
  const fullScreenActiveViewClass = fullScreenView ? 'btn-selected' : ''

  const modalPositionClass = getModalPositionClass(props.modalPosition)

  return isEmbedded(props.modalPosition) ? (
    <div
      className={
        'preview-interaction-container ' +
        modalPositionClass +
        phoneViewClass +
        ' ' +
        desktopViewClass +
        ' ' +
        tabletViewClass +
        ' ' +
        fullScreenViewClass
      }
    >
      {props.children}
    </div>
  ) : (
    <Modal
      isOpen={props.showModal}
      toggle={props.closeModalHandler}
      modalClassName={
        'preview-interaction-container ' +
        modalPositionClass +
        phoneViewClass +
        ' ' +
        desktopViewClass +
        ' ' +
        tabletViewClass +
        ' ' +
        fullScreenViewClass
      }
    >
      <ModalBody>
        <div className='close-modal-btn'>
          <button
            aria-label='Close'
            className='close'
            data-dismiss='modal'
            type='button'
            onClick={props.closeModalHandler}
          >
            <FontAwesomeIcon icon={faTimes} className='icon-remove' />
          </button>
        </div>
        <div className='modal-content'>
          <div className='modal-commands'>
            <div className={'general-commands ' + props.isSupportLockedClass}>
              <button
                className={
                  'btn-round btn-icon btn btn-outline-info ' +
                  phoneActiveViewClass
                }
                onClick={phoneModeClicked}
              >
                <FontAwesomeIcon icon={faMobileAlt} />
              </button>
              <button
                className={
                  'btn-round btn-icon btn btn-outline-info ' +
                  tabletActiveViewClass
                }
                onClick={tabletModeClicked}
              >
                <FontAwesomeIcon icon={faTabletAlt} className='fa-rotate-90' />
                <i className='fa fa-tablet-alt fa-rotate-90' />
              </button>
              <button
                className={
                  'btn-round btn-icon btn btn-outline-info ' +
                  fullScreenActiveViewClass
                }
                onClick={fullScreenModeClicked}
              >
                <FontAwesomeIcon icon={faDesktop} />
              </button>
              <button
                className='btn-round btn-icon btn btn-outline-info no-active-hilighted'
                onClick={props.refreshPreview}
              >
                <FontAwesomeIcon icon={faSync} />
              </button>
            </div>
          </div>
          {props.children}
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalFrame
