export const initialState = {
  userInfo: null,
  userInfoIsLoading: false,
  showPopupUserInfo: false,
  userInfoLoadingError: false,
  appConfig: null,
  appConfigIsLoaded: false,
  appConfigIsLoading: false,
  appConfigLoadingError: false,
  showDefaultModal: false,
  modalData: null
}

export const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    // USER INFO
    case 'LOAD_USER_INFO':
      return {
        ...state,
        userInfoIsLoading: true,
        userInfoLoadingError: false
      }
    case 'LOAD_USER_INFO_SUCCESS':
      return {
        ...state,
        userInfo: action.payload,
        userInfoIsLoading: false,
        userInfoLoadingError: false
      }
    case 'LOAD_USER_INFO_ERROR':
      return {
        ...state,
        userInfoIsLoading: false,
        userInfoLoadingError: true,
        userInfoError: action.payload
      }
    case 'TOGGLE_USER_INFO_POPUP':
      return {
        ...state,
        showPopupUserInfo: !state.showPopupUserInfo
      }
    // APP CONFIG
    case 'LOAD_APP_CONFIG':
      return {
        ...state,
        appConfigIsLoading: true,
        appConfigLoadingError: false
      }
    case 'LOAD_APP_CONFIG_SUCCESS':
      return {
        ...state,
        appConfig: action.payload,
        appConfigIsLoaded: true,
        appConfigIsLoading: false,
        appConfigLoadingError: false
      }
    case 'LOAD_APP_CONFIG_ERROR':
      return {
        ...state,
        appConfigIsLoading: false,
        appConfigLoadingError: true,
        appConfigError: action.payload
      }
    // MODALS
    case 'OPEN_DEFAULT_MODAL':
      return {
        ...state,
        showDefaultModal: true,
        modalData: action.payload
      }
    case 'CLOSE_DEFAULT_MODAL':
      return {
        ...state,
        showDefaultModal: false
      }
    default:
      return state
  }
}
