/**
 * Gets the repositories of the user from Github
 */
import axios from 'axios'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  loadUserInfoSuccessAct,
  loadUserInfoErrorAct,
  loadAppConfigSuccessAct,
  loadAppConfigErrorAct
} from './_actions'
// import request from 'utils/request';
// import { makeSelectUsername } from 'containers/HomePage/selectors';
const NETWORK_ERROR = "Errore nell'esecuzione della chiamata"

export const getStateCommunications = (state) => state.communications

/** function that returns an axios call */
function getUserDataHttp(requestURL) {
  return axios.get(requestURL)
}

export function* getUserDataFromServer() {
  const stateCommunications = yield select(getStateCommunications)
  // let requestURL_old = `http://localhost:3004/userInfoError`;
  // let requestURL_old = `http://localhost:3004/userInfo`;
  let requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/userInfo`

  try {
    const response = yield call(getUserDataHttp, requestURL)
    if (!response) {
      throw new Error(NETWORK_ERROR)
    }
    if (response.data.opResult === true) {
      yield put(loadUserInfoSuccessAct(response.data.data))
    } else {
      yield put(loadUserInfoErrorAct(response.data.error))
    }
  } catch (err) {
    yield put(loadUserInfoErrorAct(err))
    return
  }
}

export function* getAppConfigFromServer() {
  const stateCommunications = yield select(getStateCommunications)
  const requestURL = `${window.REACT_APP_API_ENDPOINT_SUPPORT}/api/configuration`

  try {
    const response = yield call(getUserDataHttp, requestURL)
    if (!response) {
      throw new Error(NETWORK_ERROR)
    }
    if (response.data.opResult === true) {
      yield put(loadAppConfigSuccessAct(response.data.data))
    } else {
      yield put(loadAppConfigErrorAct(response.data.error))
    }
  } catch (err) {
    yield put(loadAppConfigErrorAct(err))
    return
  }
}

// saga watcher
export default function* getUserData() {
  yield takeLatest('LOAD_USER_INFO', getUserDataFromServer)
  yield takeLatest('LOAD_APP_CONFIG', getAppConfigFromServer)
}
